import { cacheExchange } from '@urql/exchange-graphcache';
import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver } from '@urql/exchange-graphcache';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  UUID: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** Brand input type to create and update a brand */
export type AddBrandInput = {
  /** Brand color in RGB hex */
  color: Scalars['String'];
  /** Image file to be uploaded as Logo */
  logo: Scalars['Upload'];
  /** Name of the brand */
  name: Scalars['String'];
};

/** Genre input type to create and update genres */
export type AddGenreInput = {
  /** Genre color in RGB hex */
  color: Scalars['String'];
  /** Genre logo image */
  logo: Scalars['Upload'];
  /** Genre name */
  name: Scalars['String'];
};

export type AddShapeCollectionImageSceneByShapeIdInput = {
  hdriFile?: InputMaybe<Scalars['Upload']>;
  jsonFile: Scalars['Upload'];
  lutFile?: InputMaybe<Scalars['Upload']>;
  prerenderedImageFile?: InputMaybe<Scalars['Upload']>;
  sceneId: Scalars['UUID'];
  shapeId: Scalars['UUID'];
  uvFile?: InputMaybe<Scalars['Upload']>;
};

/** Add Image scene input */
export type AddShapeCollectionImageSceneInput = {
  /** Scene HDRI file */
  hdriFile?: InputMaybe<Scalars['Upload']>;
  jsonFile: Scalars['Upload'];
  /** Scene LUT file */
  lutFile?: InputMaybe<Scalars['Upload']>;
  /** Prerendered Scene Image */
  prerenderedImageFile?: InputMaybe<Scalars['Upload']>;
  sceneId: Scalars['UUID'];
  shapeCollectionId: Scalars['UUID'];
  /** Image Scene UV file */
  uvFile?: InputMaybe<Scalars['Upload']>;
};

export type AddShapeCollectionUvRemapSceneByShapeIdInput = {
  hdriFile?: InputMaybe<Scalars['Upload']>;
  jsonFile: Scalars['Upload'];
  lutFile?: InputMaybe<Scalars['Upload']>;
  sceneId: Scalars['UUID'];
  shapeId: Scalars['UUID'];
  videoUvFile?: InputMaybe<Scalars['Upload']>;
};

/** Add UV Remap scene input */
export type AddShapeCollectionUvRemapSceneInput = {
  /** Scene HDRI file */
  hdriFile?: InputMaybe<Scalars['Upload']>;
  jsonFile: Scalars['Upload'];
  /** Scene LUT file */
  lutFile?: InputMaybe<Scalars['Upload']>;
  sceneId: Scalars['UUID'];
  shapeCollectionId: Scalars['UUID'];
  /** Video Scene UV file */
  videoUvFile?: InputMaybe<Scalars['Upload']>;
};

export type AddShapeCollectionVideoSceneByShapeIdInput = {
  hdriFile?: InputMaybe<Scalars['Upload']>;
  jsonFile: Scalars['Upload'];
  lutFile?: InputMaybe<Scalars['Upload']>;
  prerenderedVideoFile?: InputMaybe<Scalars['Upload']>;
  sceneId: Scalars['UUID'];
  shapeId: Scalars['UUID'];
  videoUvFile?: InputMaybe<Scalars['Upload']>;
};

/** Add Video scene input */
export type AddShapeCollectionVideoSceneInput = {
  /** Scene HDRI file */
  hdriFile?: InputMaybe<Scalars['Upload']>;
  jsonFile: Scalars['Upload'];
  /** Scene LUT file */
  lutFile?: InputMaybe<Scalars['Upload']>;
  /** Prerendered Scene Video */
  prerenderedVideoFile?: InputMaybe<Scalars['Upload']>;
  sceneId: Scalars['UUID'];
  shapeCollectionId: Scalars['UUID'];
  /** Video Scene UV file */
  videoUvFile?: InputMaybe<Scalars['Upload']>;
};

/** Input type to Create a Tenant */
export type AddTenantInput = {
  /** Tenant IdP domain. Must be unique when not null. */
  domain?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['Upload']>;
  /** Tenant name. Can not be null or empty. */
  name: Scalars['String'];
  noMatchesImage: Scalars['Upload'];
  /** Tenant authorization journey name on Azure B2C */
  userJourney?: InputMaybe<Scalars['String']>;
};

export type AddUserProvisioningTableFailed = UserProvisioningTableResponse & {
  __typename?: 'AddUserProvisioningTableFailed';
  csvDownloadUrl?: Maybe<Scalars['String']>;
  succeeded: Scalars['Boolean'];
  validationResult: ValidationResult;
};

/** User Provisioning Table Input */
export type AddUserProvisioningTableInput = {
  /** A csv file that contains user provisioning information regarding budget and productGroups */
  file: Scalars['Upload'];
  /** Name of the uploaded user provisioning table */
  name: Scalars['String'];
};

export type AddUserProvisioningTableSucceeded = UserProvisioningTableResponse & {
  __typename?: 'AddUserProvisioningTableSucceeded';
  data: UserProvisioningTableModel;
  succeeded: Scalars['Boolean'];
};

/** Additional Image input */
export type AdditionalImageInput = {
  additionalImageTypeId: Scalars['UUID'];
  image: Scalars['Upload'];
  productId: Scalars['UUID'];
};

export type AdditionalImageModel = {
  __typename?: 'AdditionalImageModel';
  /** Additional Image Type */
  additionalImageType: AdditionalImageTypeModel;
  /** Image Type Id */
  additionalImageTypeId: Scalars['UUID'];
  /** File Name */
  fileName: Scalars['String'];
  /** File Type */
  fileType: Scalars['String'];
  /** Height of the Additional Image */
  height: Scalars['Int'];
  /** Image Id */
  id: Scalars['UUID'];
  imageUrl: Scalars['String'];
  /** Product Id */
  productId: Scalars['UUID'];
  /** Tenant Id */
  tenantId: Scalars['UUID'];
  /** Width of the Additional Image */
  width: Scalars['Int'];
};

export type AdditionalImageModelInput = {
  /** Image Type Id */
  additionalImageTypeId: Scalars['UUID'];
  /** File Name */
  fileName: Scalars['String'];
  /** File Type */
  fileType: Scalars['String'];
  /** Height of the Additional Image */
  height: Scalars['Int'];
  /** Image Id */
  id: Scalars['UUID'];
  /** Product Id */
  productId: Scalars['UUID'];
  /** Tenant Id */
  tenantId: Scalars['UUID'];
  /** Width of the Additional Image */
  width: Scalars['Int'];
};

/** Additional Image Type input */
export type AdditionalImageTypeInput = {
  /** Type Description */
  description: Scalars['String'];
  /** Image Type Name/Label */
  name: Scalars['String'];
  /** List of tenants ids with access */
  tenants: Array<Scalars['UUID']>;
};

export type AdditionalImageTypeModel = {
  __typename?: 'AdditionalImageTypeModel';
  /** Image Type Description */
  description: Scalars['String'];
  /** Image Type Id */
  id: Scalars['UUID'];
  /** Image Type Name */
  name: Scalars['String'];
  /** List of tenents assigned to this type */
  tenants: Array<TenantModel>;
};

export type AdvancedFilterSelectionInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type AdvancedFiltersSettings = {
  __typename?: 'AdvancedFiltersSettings';
  metadataFiltersEnabled: Scalars['Boolean'];
  productGroupFiltersEnabled: Scalars['Boolean'];
  shapeCollectionFiltersEnabled: Scalars['Boolean'];
};

export type AdvancedFiltersSettingsInput = {
  metadataFiltersEnabled: Scalars['Boolean'];
  productGroupFiltersEnabled: Scalars['Boolean'];
  shapeCollectionFiltersEnabled: Scalars['Boolean'];
};

export type AdvancedProductFilterOptions = {
  __typename?: 'AdvancedProductFilterOptions';
  metadataFilterOptions: MetadataKeyFilterConnection;
  productGroupFilterOptions: ProductGroupFilterConnection;
  shapeCollectionFilterOptions: ShapeCollectionFilterConnection;
};


export type AdvancedProductFilterOptionsMetadataFilterOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type AdvancedProductFilterOptionsProductGroupFilterOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type AdvancedProductFilterOptionsShapeCollectionFilterOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  textPhrase?: InputMaybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type ArtworkInput = {
  /** Grip Id of selected artwork */
  id: Scalars['UUID'];
  /** ShapeCollection reference id */
  shapeCollectionId: Scalars['UUID'];
};

/** Object/Type that contains artwork mapping information for a shapeCollection */
export type ArtworkMapping = {
  __typename?: 'ArtworkMapping';
  inputs: Array<ArtworkMappingInputObject>;
  mappingUrl: Scalars['String'];
};

/** Object/Type that contains artwork mapping information for a shapeCollection */
export type ArtworkMappingInput = {
  inputs: Array<ArtworkMappingInputObjectInput>;
  mappingUrl: Scalars['String'];
};

export type ArtworkMappingInputObject = {
  __typename?: 'ArtworkMappingInputObject';
  id: Scalars['String'];
  inputType: ArtworkMappingInputType;
  name: Scalars['String'];
  options: Array<ArtworkMappingOption>;
  required: Scalars['Boolean'];
};

export type ArtworkMappingInputObjectInput = {
  id: Scalars['String'];
  inputType: ArtworkMappingInputType;
  name: Scalars['String'];
  options: Array<ArtworkMappingOptionInput>;
  required: Scalars['Boolean'];
};

export enum ArtworkMappingInputType {
  Hidden = 'HIDDEN',
  Image = 'IMAGE',
  Pdf = 'PDF',
  SelectColor = 'SELECT_COLOR',
  SelectGraphic = 'SELECT_GRAPHIC',
  SelectText = 'SELECT_TEXT'
}

export type ArtworkMappingOption = {
  __typename?: 'ArtworkMappingOption';
  name: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  value: Scalars['String'];
};

export type ArtworkMappingOptionInput = {
  name: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  value: Scalars['String'];
};

/** Object/Type that represents an Artwork */
export type ArtworkModel = {
  __typename?: 'ArtworkModel';
  /**
   * Ultra-high or high resolution artwork URL
   *
   * This field will contain ultra-high resolution artwork URL if exists, otherwise high resolution artwork URL
   */
  fullQuality: Scalars['String'];
  /**
   * Internal ArtworkId
   *
   * The Id must correspond with an Artwork Id on Grip
   */
  id: Scalars['UUID'];
  /**
   * Internal FileSystem Id for the actual artwork
   *
   * The NasId is mainly used by render farm
   */
  nasId?: Maybe<Scalars['String']>;
  /** Low resolution artwork URL */
  preview: Scalars['String'];
  /** Reference to related ShapeCollection */
  shapeCollectionId: Scalars['UUID'];
  /** 160x160 Thumbnail Url */
  thumbnail: Scalars['String'];
};

/** Object/Type that represents an Artwork */
export type ArtworkModelInput = {
  /**
   * Ultra-high or high resolution artwork URL
   *
   * This field will contain ultra-high resolution artwork URL if exists, otherwise high resolution artwork URL
   */
  fullQuality: Scalars['String'];
  /**
   * Internal ArtworkId
   *
   * The Id must correspond with an Artwork Id on Grip
   */
  id: Scalars['UUID'];
  /**
   * Internal FileSystem Id for the actual artwork
   *
   * The NasId is mainly used by render farm
   */
  nasId?: InputMaybe<Scalars['String']>;
  /** Low resolution artwork URL */
  preview: Scalars['String'];
  /** Reference to related ShapeCollection */
  shapeCollectionId: Scalars['UUID'];
  /** 160x160 Thumbnail Url */
  thumbnail: Scalars['String'];
};

export type ArtworkSetCompatibilityInput = {
  artworkSetTypes?: InputMaybe<Array<ArtworkType>>;
};

export type ArtworkSetCompatibilityOptions = {
  __typename?: 'ArtworkSetCompatibilityOptions';
  artworkTypes: Array<ArtworkType>;
};

export type ArtworkSetFilter = {
  __typename?: 'ArtworkSetFilter';
  artworkSetTypes?: Maybe<Array<ArtworkType>>;
};

/** ArtworkSet input type to create an artworkSet */
export type ArtworkSetInput = {
  /** Artwork Id's referencing Grip shapes */
  artworks: Array<ArtworkInput>;
  locale: Array<Scalars['String']>;
  /** Name of the ArtworkSet. Can not be null or empty. */
  name: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
  type: ArtworkType;
  /** Information indicating by whom/what the shape was validated */
  validatedBy: ValidatorInput;
};

export type ArtworkSetListFilterInput = {
  /** Text to search for (Name, Id, Type, Source) */
  searchTerm?: InputMaybe<Scalars['String']>;
  /** ShapeCollectionId to filter by */
  shapeCollectionId?: InputMaybe<Scalars['UUID']>;
};

export enum ArtworkSetListFilterSortBy {
  Id = 'ID',
  Name = 'NAME',
  Source = 'SOURCE',
  Type = 'TYPE'
}

export type ArtworkSetListFilterSortInput = {
  /** Contains which column to use in sorting */
  sortBy: ArtworkSetListFilterSortBy;
  /** Contains which order to use in sorting */
  sortDirection: SortDirection;
};

export type ArtworkSetListModel = {
  __typename?: 'ArtworkSetListModel';
  artworkSets: Array<ArtworkSetModel>;
  filteredTotal: Scalars['Int'];
};


export type ArtworkSetListModelArtworkSetsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

/** Object/Type that represents an ArtworkSet */
export type ArtworkSetModel = {
  __typename?: 'ArtworkSetModel';
  /** Artworks. */
  artworks: Array<ArtworkModel>;
  /** Unique Internal ArtworkSet Id */
  id: Scalars['UUID'];
  locale: Array<Scalars['String']>;
  /** Name of the ArtworkSet. Can not be null or empty. */
  name: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  /** ArtworkSet semantic label. DEFAULT | CLEAN | OPTIMIZED */
  type: ArtworkType;
  /** Information indicating by whom/what the shape was validated */
  validatedBy: Validator;
};

/** Object/Type that represents an ArtworkSet */
export type ArtworkSetModelInput = {
  /** Artworks. */
  artworks: Array<ArtworkModelInput>;
  /** Unique Internal ArtworkSet Id */
  id: Scalars['UUID'];
  locale: Array<Scalars['String']>;
  /** Name of the ArtworkSet. Can not be null or empty. */
  name: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
  /** ArtworkSet semantic label. DEFAULT | CLEAN | OPTIMIZED */
  type: ArtworkType;
  /** Information indicating by whom/what the shape was validated */
  validatedBy: ValidatorInput;
};

export enum ArtworkType {
  Clean = 'CLEAN',
  CleanAngled = 'CLEAN_ANGLED',
  Default = 'DEFAULT',
  DefaultAngled = 'DEFAULT_ANGLED',
  Optimized = 'OPTIMIZED',
  OptimizedAngled = 'OPTIMIZED_ANGLED'
}

export type AssignProductGroupsInput = {
  productGroups: Array<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type AssignUserRolesInput = {
  userId: Scalars['UUID'];
  userRoles: Array<UserRoleEnum>;
};

/** Input type to discover the User Journey */
export type AuthenticationInput = {
  /** User email registered in a tenant IdP. */
  email: Scalars['String'];
};

/** Measurement type for an object */
export enum BaseMeasurement {
  /** Always in ml */
  NetContent = 'NET_CONTENT',
  /** Quantity */
  NumberOfItems = 'NUMBER_OF_ITEMS',
  /** Always in cm */
  ShoeSize = 'SHOE_SIZE',
  /** Always in grams */
  Weight = 'WEIGHT'
}

export type BaseMeasurementFilter = {
  __typename?: 'BaseMeasurementFilter';
  max?: Maybe<Scalars['Int']>;
  measurement: BaseMeasurement;
  min?: Maybe<Scalars['Int']>;
};

export type BaseMeasurementFilterInput = {
  max?: InputMaybe<Scalars['Int']>;
  measurement: BaseMeasurement;
  min?: InputMaybe<Scalars['Int']>;
};

/** Basket input item for bundles */
export type BasketBundleInput = {
  /** Content kit id */
  contentKitId: Scalars['UUID'];
  /** Order Item id, only when the combination is a completed order */
  orderItemId?: InputMaybe<Scalars['UUID']>;
  /** Main productId */
  productId: Scalars['UUID'];
  /** Saved Item id, only when the combination is saved */
  savedItemId?: InputMaybe<Scalars['UUID']>;
  /** Template id */
  templateId: Scalars['UUID'];
};

export type BasketGroupModel = {
  __typename?: 'BasketGroupModel';
  /** Creation date */
  createdAt: Scalars['DateTime'];
  /** Group Id for items, single items also have group Id */
  groupId: Scalars['UUID'];
  /** List of items */
  items: Array<BasketItemModel>;
};

export type BasketItemModel = {
  __typename?: 'BasketItemModel';
  configuration: ConfigurationModel;
  /** Content kit */
  contentKit: ContentKitModel;
  /** Credit cost to order this item */
  creditCost: Scalars['Int'];
  /** Hash key, unique for each combination */
  hashKey: Scalars['String'];
  /** Saved item Id */
  id: Scalars['UUID'];
  isPremiumOrderable: Scalars['Boolean'];
  /** ProductsModel for Main Product */
  mainProduct: ProductModel;
  /** Template output variant */
  outputVariant: Scalars['String'];
  /** Product selections, Key is the product input name from Template.Metadata */
  productSelections: Array<KeyValuePairOfStringAndProductSelectionModel>;
  /** List of saved products */
  products: Array<SimplifiedProductModel>;
  /** Status defining if the item is in the basket or already ordered */
  status: BasketItemStatusFlags;
  /** Template */
  template: TemplateModel;
  templateVersion: Scalars['Int'];
  /** Thumbnail url */
  thumbnailUrl: Scalars['String'];
  /** User inputs */
  userInputs: Array<KeyValuePairOfStringAndString>;
};

export enum BasketItemRemovalTypeInput {
  Group = 'GROUP',
  Item = 'ITEM'
}

export type BasketItemStatusFlags = {
  __typename?: 'BasketItemStatusFlags';
  /** Basket item that is duplicated in the same basket */
  isInBasket: Scalars['Boolean'];
  /** Basket item that is ordered already */
  isOrdered: Scalars['Boolean'];
};

/** User basket */
export type BasketModel = {
  __typename?: 'BasketModel';
  groups: Array<BasketGroupModel>;
};

/** Basket Item input */
export type BasketSingleItemInput = {
  /** Content kit id */
  contentKitId: Scalars['UUID'];
  /**
   * Output-Variant to be used in rendering the output, if null the first output should be used on the template.
   * Only applicable when the order comes from the editor
   */
  outputVariant: Scalars['String'];
  /** Preview image */
  preview: Scalars['Upload'];
  /**
   * Product selection.
   * Where the key is the product input name, and the value is the productId and its selections
   * Only applicable when the order comes from the editor
   */
  productSelections: Array<KeyValuePairOfStringAndProductSelectionInput>;
  /** Template id */
  templateId: Scalars['UUID'];
  /** Thumbnail image */
  thumbnail: Scalars['Upload'];
  /**
   * User inputs to environment and other variables.
   * Only applicable when the order comes from the editor.
   */
  userInputs?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

/** Object/Type that represents a brand */
export type BrandModel = {
  __typename?: 'BrandModel';
  /** Brand color */
  color: Scalars['String'];
  /** Internal unique Brand Id */
  id: Scalars['UUID'];
  logoUrl: Scalars['String'];
  /** Name of brand */
  name: Scalars['String'];
};

/** Object/Type that represents a brand */
export type BrandModelInput = {
  /** Brand color */
  color: Scalars['String'];
  /** Internal unique Brand Id */
  id: Scalars['UUID'];
  /** Name of brand */
  name: Scalars['String'];
};

export type BudgetFiltersInput = {
  /** Budget owner filter */
  budgetOwner?: InputMaybe<Scalars['UUID']>;
  /** Budget type filter */
  budgetTypeFilter: BudgetTypeFilterFlagsInput;
  /** Keyword to search by name */
  keyword?: InputMaybe<Scalars['String']>;
};

export type BudgetListModel = {
  __typename?: 'BudgetListModel';
  /** List of budgets */
  budgets: Array<BudgetModel>;
  /** Total number of budgets */
  totalNumber: Scalars['Int'];
};


export type BudgetListModelBudgetsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type BudgetModel = {
  __typename?: 'BudgetModel';
  /** Users assigned to read the budget. Doesn't show Admins, PMs and Budget Owner with access to it */
  assignedUsers: Array<InfoModel>;
  /** Pay-Per-Use | Subscription */
  budgetType: BudgetType;
  createdAt: Scalars['DateTime'];
  /** Credit value in Euros */
  creditValue: Scalars['Decimal'];
  /** Budget current credit balance */
  credits: Scalars['Int'];
  /** Expiration date */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** Unique budget Id */
  id: Scalars['UUID'];
  /**
   * If true budget can not be used
   *
   * Expired subscriptions can not be used to order.
   * Expired subscriptions will not be reset.
   */
  isExpired: Scalars['Boolean'];
  isInUse: Scalars['Boolean'];
  /**
   * If set true budget can not be used
   *
   * Suspended budgets can not be used to order.
   * Suspended subscriptions will not be reset.
   */
  isSuspended: Scalars['Boolean'];
  /** Budget name */
  name: Scalars['String'];
  /** Budget owner */
  owner: InfoModel;
  /** PrePaid | PostPaid */
  paymentType: BudgetPaymentType;
  premiumOrdering: PremiumOrderingModel;
  /** Sales force ids */
  salesForceReferences: Array<SalesForceReferenceModel>;
  /** Monthly credit reset amount */
  subscriptionCreditAmount: Scalars['Int'];
  /** Subscription Start date */
  subscriptionStart?: Maybe<Scalars['DateTime']>;
};

export type BudgetOrderSuggestion = OrderSuggestionModel & {
  __typename?: 'BudgetOrderSuggestion';
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: OrderSearchEntityType;
};

export enum BudgetPaymentType {
  PostPaid = 'POST_PAID',
  PrePaid = 'PRE_PAID'
}

export enum BudgetSortType {
  ByNameAsc = 'BY_NAME_ASC',
  ByNameDesc = 'BY_NAME_DESC',
  LatestFirst = 'LATEST_FIRST'
}

export enum BudgetType {
  PayPerUse = 'PAY_PER_USE',
  Subscription = 'SUBSCRIPTION'
}

export type BudgetTypeFilterFlagsInput = {
  isPayPerUse?: InputMaybe<Scalars['Boolean']>;
  isSubscription?: InputMaybe<Scalars['Boolean']>;
};

export type CompatibleProductSearchResult = {
  __typename?: 'CompatibleProductSearchResult';
  products: Array<ProductModel>;
  totalNumber: Scalars['Int'];
};


export type CompatibleProductSearchResultProductsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type CompleteShapeCollectionSceneModel = {
  __typename?: 'CompleteShapeCollectionSceneModel';
  /** Hdri File URL */
  hdriFileUrl?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** Json file content */
  json: Scalars['String'];
  /** Json File URL */
  jsonFileUrl: Scalars['String'];
  /** Lut File URL */
  lutFileUrl?: Maybe<Scalars['String']>;
  /** Prerendered Image File URL */
  prerenderedImageFileUrl?: Maybe<Scalars['String']>;
  prerenderedVideoFileUrl?: Maybe<Scalars['String']>;
  /** Scene */
  scene: SceneInfoModel;
  shapeCollectionId: Scalars['UUID'];
  shapeViewerData?: Maybe<ShapeViewerDataModel>;
  /** Uv File URL */
  uvFileUrl?: Maybe<Scalars['String']>;
  videoUvFileUrl?: Maybe<Scalars['String']>;
};

export type ConfigurationModel = {
  __typename?: 'ConfigurationModel';
  /** Template output variant */
  outputVariant: Scalars['String'];
  /** Product selections, Key is the product input name from Template.Metadata */
  productSelections: Array<KeyValuePairOfStringAndProductSelectionModel>;
  /** User inputs */
  userInputs: Array<KeyValuePairOfStringAndString>;
};

/** Content Kit input type to create or update a Content Kit */
export type ContentKitInput = {
  /** Content kit Genre */
  genreId: Scalars['UUID'];
  /** Content kit metadata */
  metadata: Array<KeyValuePairOfStringAndStringInput>;
  /** Content kit name */
  name: Scalars['String'];
  /** Template Ids associated with the content kit */
  templateIds: Array<Scalars['UUID']>;
};

export enum ContentKitListSortBy {
  Genre = 'GENRE',
  Id = 'ID',
  Name = 'NAME'
}

/** Object/Type that represents a ContentKit */
export type ContentKitModel = {
  __typename?: 'ContentKitModel';
  /** Content kit genre */
  genre: GenreModel;
  /** Unique Internal ContentKit Id */
  id: Scalars['UUID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** Key/Value object to store content kit specific metadata */
  metadata: Array<KeyValuePairOfStringAndString>;
  /** Name of the ContentKit. Can not be null or empty. */
  name: Scalars['String'];
  /** Associated project */
  project?: Maybe<ProjectModel>;
  /** Related ContentKits */
  related: Array<ContentKitModel>;
  templates: Array<TemplateModel>;
};

export type ContentKitOutputCombinationGroupModel = OutputCombinationGroupModel & {
  __typename?: 'ContentKitOutputCombinationGroupModel';
  /** Full Content Kit details */
  contentKit: ContentKitModel;
  /** Content-Kit genre */
  genre: GenreModel;
  id: Scalars['String'];
  mainEntityId: Scalars['UUID'];
  name: Scalars['String'];
  outputCombinations: OutputCombinationListModel;
};

export type ContentKitSearchFilters = {
  __typename?: 'ContentKitSearchFilters';
  /** Selected content-kit filters */
  contentKits?: Maybe<FindByIdFilter>;
  /** Selected genre filters */
  genres?: Maybe<FindByIdFilter>;
};

export type ContentKitSearchFiltersInput = {
  /** Selected content-kit filters */
  contentKits?: InputMaybe<FindByIdFilterInput>;
  /** Selected genre filters */
  genres?: InputMaybe<FindByIdFilterInput>;
};

export type ContentKitSuggestion = SuggestionModel & {
  __typename?: 'ContentKitSuggestion';
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: SearchEntityType;
};

export type ContentKitsFiltersInput = {
  /** Text to search for (ContentKitId, ContentKitName and Genre) */
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type ContentKitsModel = {
  __typename?: 'ContentKitsModel';
  contentKits: Array<ContentKitModel>;
  filteredTotal: Scalars['Int'];
};


export type ContentKitsModelContentKitsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type ContentKitsSortInput = {
  /** Contains which column to use in sorting */
  sortBy: ContentKitListSortBy;
  /** Contains which order to use in sorting */
  sortDirection: SortDirection;
};

export type CopyTemplateInput = {
  /** If null then name will be copied from RootTemplate with prefix "Copy of " */
  newTemplateName?: InputMaybe<Scalars['String']>;
  /** Id of template from which all data will be copied */
  sourceTemplateId: Scalars['UUID'];
};

export type CreateQaUserInput = {
  email: Scalars['String'];
  givenName: Scalars['String'];
  password: Scalars['String'];
  surname: Scalars['String'];
};

export type CurrentUserModel = {
  __typename?: 'CurrentUserModel';
  /** Email address */
  email: Scalars['String'];
  /** First Name */
  firstName?: Maybe<Scalars['String']>;
  /** UserId in the AD B2C */
  id: Scalars['UUID'];
  /** Last Name */
  lastName?: Maybe<Scalars['String']>;
  /** Display name, configured in the original IdP */
  name?: Maybe<Scalars['String']>;
  /** User role for the tenant */
  roles: Array<UserRoleEnum>;
  /** Tenant selected by the user to perform actions */
  selectedTenantId: Scalars['UUID'];
  /** Grip TenantId that the user belongs */
  tenantId: Scalars['UUID'];
};

export type DeleteOrderInput = {
  orderId: Scalars['UUID'];
};

export type DeleteOrderItemInput = {
  orderId: Scalars['UUID'];
  orderItemId: Scalars['UUID'];
};

export type DimensionFilter = {
  __typename?: 'DimensionFilter';
  depth?: Maybe<RangeFilter>;
  height?: Maybe<RangeFilter>;
  width?: Maybe<RangeFilter>;
};

export type DimensionFilterInput = {
  depth?: InputMaybe<RangeFilterInput>;
  height?: InputMaybe<RangeFilterInput>;
  width?: InputMaybe<RangeFilterInput>;
};

/** 3D dimensions of a shape in mm */
export type Dimensions = {
  __typename?: 'Dimensions';
  /** Depth of a 3D object */
  depth: Scalars['Int'];
  /** Height of a 3D object */
  height: Scalars['Int'];
  /** Width of a 3D object */
  width: Scalars['Int'];
};

/** 3D dimensions of a shape in mm */
export type DimensionsInput = {
  /** Depth of a 3D object */
  depth: Scalars['Int'];
  /** Height of a 3D object */
  height: Scalars['Int'];
  /** Width of a 3D object */
  width: Scalars['Int'];
};

export type DownloadModel = {
  __typename?: 'DownloadModel';
  downloadUrl: Scalars['String'];
};

/** DraftProduct input type to create and update a product */
export type DraftProductInput = {
  /** Array of associated artworkSet ids */
  artworkSets?: InputMaybe<Array<Scalars['UUID']>>;
  /** Associated brand id */
  brandId?: InputMaybe<Scalars['UUID']>;
  /** Key/Value object to store client specific metadata */
  metadata?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  /** Name of the product. */
  name?: InputMaybe<Scalars['String']>;
  /** Globally unique identifier of a product */
  productGlobalId?: InputMaybe<ProductGlobalIdInput>;
  /** Associated project id */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Associated shapeCollection id */
  shapeCollectionId?: InputMaybe<Scalars['UUID']>;
  /**
   * Product variant
   *
   * Flavor in case of a drink, "Cherry"
   * Scent in case of a perfume, such as "Fresh"
   */
  variant?: InputMaybe<Scalars['String']>;
};

export type DraftProductModel = {
  __typename?: 'DraftProductModel';
  artworkSets?: Maybe<Array<ArtworkSetModel>>;
  /** Associated brand of the product. */
  brand?: Maybe<BrandModel>;
  /** Globally unique identifier of a product (trade item) */
  globalId?: Maybe<ProductGlobalId>;
  id: Scalars['UUID'];
  /** Key/Value object to store client specific metadata */
  metadata?: Maybe<Array<KeyValuePairOfStringAndString>>;
  /** Name of the product. */
  name?: Maybe<Scalars['String']>;
  /** Associated project of the product. */
  project?: Maybe<ProjectModel>;
  /** Associated shapeCollection */
  shapeCollection?: Maybe<ShapeCollectionModel>;
  /**
   * Product variant
   *
   * Flavor in case of a drink, "Cherry"
   * Scent in case of a perfume, such as "Fresh"
   */
  variant?: Maybe<Scalars['String']>;
};

/** Class to return details related to the editor, based on the combination of products, template and user inputs */
export type EditorCombinationDetailsModel = {
  __typename?: 'EditorCombinationDetailsModel';
  /** Hash key for the combination and user inputs */
  hashKey: Scalars['String'];
  /** Returns a order history based on matching hash keys */
  orderHistory: Array<OutputCombinationOrderInfo>;
};

export type EditorDetailsInput = {
  /** Content kit id */
  contentKitId: Scalars['UUID'];
  /** Output-Variant to be used in rendering the output, if null the first output should be used on the template */
  outputVariant: Scalars['String'];
  /**
   * Product selection.
   * Where the key is the product input name, and the value is the productId and its selections
   */
  productSelections: Array<KeyValuePairOfStringAndProductSelectionInput>;
  /** Template id */
  templateId: Scalars['UUID'];
  /** User inputs to environment and other variables. */
  userInputs: Array<KeyValuePairOfStringAndStringInput>;
};

export type EditorDetailsModel = {
  __typename?: 'EditorDetailsModel';
  /** Content kit id */
  contentKitId: Scalars['UUID'];
  /** Output-Variant to be used in rendering the output, if null the first output should be used on the template */
  outputVariant: Scalars['String'];
  /**
   * Product selection.
   * Where the key is the product input name, and the value is the productId and its selections
   */
  productSelections: Array<KeyValuePairOfStringAndProductSelectionModel>;
  /** Template id */
  templateId: Scalars['UUID'];
  /** User inputs to environment and other variables. */
  userInputs: Array<KeyValuePairOfStringAndString>;
};

/** Inputs to fetch the editor initial values */
export type EditorInitialValuesInput = {
  /** Content Kit Id */
  contentKitId: Scalars['UUID'];
  /** HashKey */
  hashKey: Scalars['String'];
  /** List of Product Ids */
  productIds: Array<Scalars['UUID']>;
  /** Template Id */
  templateId: Scalars['UUID'];
};

export type EditorInitialValuesModel = {
  __typename?: 'EditorInitialValuesModel';
  configuration: ConfigurationModel;
  contentKit: ContentKitModel;
  products: Array<ProductModel>;
  template: TemplateModel;
};

/** Editor saved item input */
export type EditorInput = {
  /** Content kit id */
  contentKitId: Scalars['UUID'];
  /** Output-Variant to be used in rendering the output, if null the first output should be used on the template */
  outputVariant: Scalars['String'];
  /** Preview image */
  preview: Scalars['Upload'];
  /**
   * Product selection.
   * Where the key is the product input name, and the value is the productId and its selections
   */
  productSelections: Array<KeyValuePairOfStringAndProductSelectionInput>;
  /** Template id */
  templateId: Scalars['UUID'];
  /** User inputs to environment and other variables. */
  userInputs: Array<KeyValuePairOfStringAndStringInput>;
};

export enum EntityType {
  Scene = 'SCENE',
  Shape = 'SHAPE',
  Video = 'VIDEO'
}

/** Export orders */
export type ExportAsInput = {
  downloadableCombinations: Array<Scalars['UUID']>;
  exportAs?: InputMaybe<OutputFormat>;
  fileName?: InputMaybe<Scalars['String']>;
  maxHeight?: InputMaybe<Scalars['Int']>;
  maxWidth?: InputMaybe<Scalars['Int']>;
};

export type ExportAsModel = {
  __typename?: 'ExportAsModel';
  downloadUrl: Scalars['String'];
};

export type FileInfo = {
  __typename?: 'FileInfo';
  contentType: Scalars['String'];
  extension: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  originalFileName: Scalars['String'];
  type: OutputCombinationFileType;
  width?: Maybe<Scalars['Int']>;
};

export type FilterOptionItem = {
  /** Id of the item */
  id: Scalars['UUID'];
  /** Name of the filter option item, this is only for display purposes */
  name: Scalars['String'];
  product?: Maybe<ProductModel>;
  /** Thumbnail/Preview of the filter option item */
  thumbnailUrl: Scalars['String'];
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  /** Filter options */
  options: Array<FilterOptionItem>;
  /** Total number of filter options */
  total: Scalars['Long'];
};

/**
 * Order status
 * In case one or more order items were deleted those items will not be taken into account when
 * deciding the final state of the order and they are being ignored
 */
export enum FilterOrderStatus {
  /** Completed - All order items are either in Completed or Failed state */
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  /**
   * Pending - The order is in progress and sent to corresponding services to be processed.
   * OrderItems under this order at least have one of the following Pending, Processing
   */
  Pending = 'PENDING'
}

export type FiltersModel = {
  __typename?: 'FiltersModel';
  brands: Array<FilterOptionItem>;
  contentKits: Array<FilterOptionItem>;
  genres: Array<FilterOptionItem>;
  products: Array<FilterOptionItem>;
  searchContext: SearchContext;
};


export type FiltersModelBrandsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type FiltersModelContentKitsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type FiltersModelGenresArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type FiltersModelProductsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type FinancialReportFiltersInput = {
  /** Budget type filter */
  budgetTypeFilter?: InputMaybe<BudgetTypeFilterFlagsInput>;
  /** Tenants filter */
  tenants?: InputMaybe<Array<Scalars['UUID']>>;
};

export type FinancialReportModel = {
  __typename?: 'FinancialReportModel';
  /** Monthly/Weekly report rows */
  rows: Array<ReportRowModel>;
  /** Total number of budgets */
  totalNumber: Scalars['Int'];
};


export type FinancialReportModelRowsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export enum FinancialReportSortType {
  ByBudgetName = 'BY_BUDGET_NAME',
  ByTenant = 'BY_TENANT',
  LatestFirst = 'LATEST_FIRST'
}

export type FindByIdFilter = {
  __typename?: 'FindByIdFilter';
  /** Selected ids */
  idList: Array<Scalars['UUID']>;
  /** Determines how the selected id filter will be applied */
  requirement: FindByIdRequirement;
};

export type FindByIdFilterInput = {
  /** Selected ids */
  idList: Array<Scalars['UUID']>;
  /** Determines how the selected id filter will be applied */
  requirement: FindByIdRequirement;
};

export enum FindByIdRequirement {
  /** At least one of provided id's must match */
  AnyOf = 'ANY_OF',
  /** Only provided id's allowed */
  Exact = 'EXACT',
  /** None of the provided id's can match */
  NoneOf = 'NONE_OF'
}

/** Fix OrderItem input */
export type FixOrderItemInput = {
  /** Order Item id to be fixed */
  orderItemId: Scalars['UUID'];
  /** OrderItem result */
  result: Scalars['Upload'];
};

export type GenericFilterOptionItem = FilterOptionItem & {
  __typename?: 'GenericFilterOptionItem';
  /** Id of the item */
  id: Scalars['UUID'];
  /** Name of the filter option item, this is only for display purposes */
  name: Scalars['String'];
  product?: Maybe<ProductModel>;
  /** Thumbnail/Preview of the filter option item */
  thumbnailUrl: Scalars['String'];
};

/** Object/type that represents a Genre */
export type GenreModel = {
  __typename?: 'GenreModel';
  /** Genre color */
  color: Scalars['String'];
  /** Genre Id */
  id: Scalars['UUID'];
  logoUrl: Scalars['String'];
  /** Genre name */
  name: Scalars['String'];
};

/** Simple model, to represent other models when only basic descriptions are needed */
export type InfoModel = {
  __typename?: 'InfoModel';
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type InternalUserModel = {
  __typename?: 'InternalUserModel';
  /** User email */
  email: Scalars['String'];
  /** User Id */
  id: Scalars['UUID'];
  /** If a user logged in to the system before this flag will return true */
  loggedInBefore: Scalars['Boolean'];
  /** ManagedTenants that the user has access to. */
  managedTenants: Array<InfoModel>;
  /** User name */
  name: Scalars['String'];
  /** User role for its own tenant */
  roles: Array<UserRoleEnum>;
  /** Tenants that the user has access to VIEW */
  tenants: Array<InfoModel>;
};

export type InviteUserInput = {
  email: Scalars['String'];
  givenName: Scalars['String'];
  surname: Scalars['String'];
};

export type KeyValuePairOfStringAndObject = {
  __typename?: 'KeyValuePairOfStringAndObject';
  key: Scalars['String'];
};

export type KeyValuePairOfStringAndProductSelectionInput = {
  key: Scalars['String'];
  value: ProductSelectionInput;
};

export type KeyValuePairOfStringAndProductSelectionModel = {
  __typename?: 'KeyValuePairOfStringAndProductSelectionModel';
  key: Scalars['String'];
  value: ProductSelectionModel;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairOfStringAndString__ = {
  __typename?: 'KeyValuePairOfStringAndString__';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type MetadataKeyFilterConnection = {
  __typename?: 'MetadataKeyFilterConnection';
  edges: Array<MetadataKeyFilterOptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long'];
};

export type MetadataKeyFilterOption = {
  __typename?: 'MetadataKeyFilterOption';
  /** ID of the filter item */
  id: Scalars['String'];
  /** Name of the filter option item, this is only for display purposes */
  name: Scalars['String'];
  values: MetadataValueFilterConnection;
};


export type MetadataKeyFilterOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  selectedAdvancedFilters?: InputMaybe<SelectedAdvancedFiltersInput>;
  textPhrase?: InputMaybe<Scalars['String']>;
};

export type MetadataKeyFilterOptionEdge = {
  __typename?: 'MetadataKeyFilterOptionEdge';
  cursor: Scalars['String'];
  node: MetadataKeyFilterOption;
};

export type MetadataValueFilterConnection = {
  __typename?: 'MetadataValueFilterConnection';
  edges: Array<MetadataValueFilterOptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long'];
};

export type MetadataValueFilterOption = {
  __typename?: 'MetadataValueFilterOption';
  /** ID of the filter item */
  id: Scalars['String'];
  /** Name of the filter option item, this is only for display purposes */
  name: Scalars['String'];
  /** Product hit count for this filter */
  productCount: Scalars['Long'];
};

export type MetadataValueFilterOptionEdge = {
  __typename?: 'MetadataValueFilterOptionEdge';
  cursor: Scalars['String'];
  node: MetadataValueFilterOption;
};

/** User journey for authentication */
export type MsalConfigurationModel = {
  __typename?: 'MsalConfigurationModel';
  /** Active Directory B2C clientId */
  clientId: Scalars['String'];
  /** Active Directory B2C domain */
  domain: Scalars['String'];
  /** Active Directory B2C instance */
  instance: Scalars['String'];
  /** Name of the journey in the AD B2C */
  userJourney: Scalars['String'];
  /** Link to authorize the user and retrieve the token */
  userJourneyUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Create a new Additional Image
   *
   *
   * **Returns:**
   * Newly created additional image
   */
  addAdditionalImage: AdditionalImageModel;
  /**
   * Creates a new additional image type.
   *
   *
   * **Returns:**
   * Newly created additional image type
   */
  addAdditionalImageType: AdditionalImageTypeModel;
  /**
   * Creates a new artworkSet.
   *
   *
   * **Returns:**
   * Newly created artworkSet
   */
  addArtworkSet: ArtworkSetModel;
  /**
   * Adds a list of artworks to an existing artworkSet
   *
   *
   * **Returns:**
   * ArtworkSet
   */
  addArtworks: Scalars['Boolean'];
  /**
   * Creates a new brand.
   *
   *
   * **Returns:**
   * Newly created brand
   */
  addBrand: BrandModel;
  /**
   * Add a bundle of items to the user basket
   *
   *
   * **Returns:**
   * Updated Basket with the new entries
   */
  addBundleToBasket: BasketModel;
  /**
   * Creates a new Content Kit
   *
   *
   * **Returns:**
   * Newly created Content Kit
   */
  addContentKit: ContentKitModel;
  /** Adds credits to given budget */
  addCredits: Scalars['Boolean'];
  /**
   * Creates a new product as Draft
   *
   *
   * **Returns:**
   * Newly created product
   */
  addDraftProduct: DraftProductModel;
  /**
   * Creates a new genre.
   *
   *
   * **Returns:**
   * Newly created genre
   */
  addGenre: GenreModel;
  /**
   * Add a single item to the user basket
   *
   *
   * **Returns:**
   * Updated Basket with the new entries
   */
  addItemToBasket: BasketModel;
  addPayPerUseBudget: BudgetModel;
  /**
   * Creates a new product.
   *
   *
   * **Returns:**
   * Newly created product
   */
  addProduct: ProductModel;
  /**
   * Creates a new Product Group.
   *
   *
   * **Returns:**
   * Newly created product group
   */
  addProductGroup: ProductGroupModel;
  /**
   * Creates a new project.
   *
   *
   * **Returns:**
   * Newly created project
   */
  addProject: ProjectModel;
  addQaUser: Scalars['Boolean'];
  /** Adds a sales force id to given budget */
  addSalesForceId: Scalars['Boolean'];
  /**
   * Creates a new scene.
   *
   *
   * **Returns:**
   * Newly created scene type
   */
  addScene: SceneModel;
  /**
   * Creates a new shapeCollection. Newly created shapeCollection will be associated with the tenant that the logged in user belongs to
   *
   *
   * **Returns:**
   * Newly created shapeCollection
   */
  addShapeCollection: ShapeCollectionModel;
  /**
   * Add a new Image scene to a shape collection
   *
   *
   * **Returns:**
   * Newly created scene
   */
  addShapeCollectionImageScene: ShapeCollectionImageSceneModel;
  addShapeCollectionImageSceneByShapeId: ShapeCollectionImageSceneModel;
  /**
   * Add a new VideoUVRemap scene to a shape collection
   *
   *
   * **Returns:**
   * Newly created scene
   */
  addShapeCollectionUVRemapScene: ShapeCollectionUvRemapSceneModel;
  addShapeCollectionUVRemapSceneByShapeId: ShapeCollectionUvRemapSceneModel;
  /**
   * Add a new Video scene to a shape collection
   *
   *
   * **Returns:**
   * Newly created scene
   */
  addShapeCollectionVideoScene: ShapeCollectionVideoSceneModel;
  addShapeCollectionVideoSceneByShapeId: ShapeCollectionVideoSceneModel;
  addSubscriptionBudget: BudgetModel;
  /** Add template */
  addTemplate: TemplateModel;
  /**
   * Create a new Tenant
   *
   *
   * **Returns:**
   * Newly created Tenant
   */
  addTenant: TenantModel;
  /**
   * Creates a new user provisioning table by uploading a csv file
   *
   *
   * **Returns:**
   * Newly created Tenant
   */
  addUserProvisioningTable: UserProvisioningTableResponse;
  archiveTemplate: TemplateModel;
  /** Assign budgets for a given user */
  assignBudgetsForUser: Scalars['Boolean'];
  assignProductGroups: UserWithBudgetsModel;
  assignUserRole: Scalars['Boolean'];
  /** Assign users that will have access to a given budget */
  assignUsersForBudget: Scalars['Boolean'];
  batchDownload: DownloadModel;
  /** Changes budget name for given budgetId */
  changeBudgetName: Scalars['Boolean'];
  /** Changes owner by given userId */
  changeBudgetOwner: Scalars['Boolean'];
  /** Change the tenant view for this user */
  changeTenantView: CurrentUserModel;
  copyTemplate: TemplateModel;
  /**
   * Delete the brand
   *
   *
   * **Returns:**
   * True if the operation succeeds
   */
  deleteBrand: Scalars['Boolean'];
  /**
   * Deletes budget by given Id
   *
   * Already used budgets can not be deleted
   */
  deleteBudget: Scalars['Boolean'];
  /**
   * Delete Content Kit
   *
   *
   * **Returns:**
   * True if succeeded
   */
  deleteContentKit: Scalars['Boolean'];
  /**
   * Delete genre
   *
   *
   * **Returns:**
   * True if succeed
   */
  deleteGenre: Scalars['Boolean'];
  deleteOrder: OrderModel;
  deleteOrderItem: OrderItemModel;
  exportAs: ExportAsModel;
  /** Extends a subscription */
  extendSubscription: Scalars['Boolean'];
  fixOrderItem: Scalars['Boolean'];
  inviteUser: Scalars['Boolean'];
  /**
   * Removes the draft product from system for given product id
   *
   *
   * **Returns:**
   * True if product was successfully removed
   */
  makeDraftProductLive: Scalars['Boolean'];
  newUserNotification: Scalars['Boolean'];
  notifyOfFailedViewer: Scalars['Boolean'];
  /**
   * Creates an order using shipped basket items and cleared them from the basket. Must have read access to the budget
   *
   *
   * **Returns:**
   * Array of orders model
   */
  order: OrderModel;
  /**
   * Creates an premium order using items from basket and cleans the basket.
   * Basket must have prmium ordering enabled.
   * Must have read access to the budget.
   *
   *
   * **Returns:**
   * Array of orders model
   */
  orderPremium: OrderModel;
  publishTemplate: TemplateModel;
  reTriggerOrderItem: Scalars['Boolean'];
  /**
   * Refire the preview request
   *
   *
   * **Returns:**
   * Project model
   */
  refirePreviewRequest: Scalars['Boolean'];
  /** Retrieves artwork information from Grip and uses it to refresh shape data on MLP */
  refreshArtwork: ArtworkModel;
  /** Retrieves shape information from Grip and uses it to refresh shape data on MLP */
  refreshShape: ShapeModel;
  /**
   * Removes the additional image from system for given id
   *
   *
   * **Returns:**
   * True if additional image was successfully removed
   */
  removeAdditionalImage: Scalars['Boolean'];
  /**
   * Removes the additional image type from system for given id
   *
   *
   * **Returns:**
   * True if additional image type was successfully removed
   */
  removeAdditionalImageType: Scalars['Boolean'];
  /**
   * Removes the artworkSet from system for given artworkSetId
   *
   *
   * **Returns:**
   * True if artworkSet was successfully removed
   */
  removeArtworkSet: Scalars['Boolean'];
  /**
   * Removes a list of artworks from an existing artworkSet
   *
   *
   * **Returns:**
   * ArtworkSet
   */
  removeArtworks: Scalars['Boolean'];
  /**
   * Removes the draft product from system for given product id
   *
   *
   * **Returns:**
   * True if product was successfully removed
   */
  removeDraftProduct: Scalars['Boolean'];
  /** Remove the saved editor combination */
  removeEditorCombination: Scalars['Boolean'];
  /** Remove an item or a group of items from the basket */
  removeItemFromBasket: BasketModel;
  /**
   * Removes the Preview from system for given PreviewId
   *
   *
   * **Returns:**
   * True if Previews was successfully removed
   */
  removePreview: Scalars['Boolean'];
  /**
   * Removes the product from system for given product id
   *
   *
   * **Returns:**
   * True if product was successfully removed
   */
  removeProduct: Scalars['Boolean'];
  /** Remove Product Group. */
  removeProductGroup: Scalars['Boolean'];
  /**
   * Removes the project from system for given project id
   *
   *
   * **Returns:**
   * True if project was successfully removed
   */
  removeProject: Scalars['Boolean'];
  /**
   * Removes the Scene from system for given id
   *
   *
   * **Returns:**
   * True if Scene type was successfully removed
   */
  removeScene: Scalars['Boolean'];
  /**
   * Removes the shapeCollection from system for given shapeCollectionId
   *
   *
   * **Returns:**
   * True if shapeCollection was successfully removed
   */
  removeShapeCollection: Scalars['Boolean'];
  /** Remove scene */
  removeShapeCollectionScene: Scalars['Boolean'];
  /** Remove template */
  removeTemplate: Scalars['Boolean'];
  /**
   * Remove the Tenant
   *
   *
   * **Returns:**
   * True if the Tenant was successfully removed
   */
  removeTenant: Scalars['Boolean'];
  /**
   * Removes given user provisioning table
   *
   *
   * **Returns:**
   * True if table was successfully removed
   */
  removeUserProvisioningTable: Scalars['Boolean'];
  /** Sends a notification to PM(s) requesting access to products */
  requestAccess: Scalars['Boolean'];
  /**
   * Request all previews for a given template
   *
   *
   * **Returns:**
   * Amount of previews requested
   */
  requestPreviews: Scalars['String'];
  /** Request all previews for a given product */
  requestPreviewsByProductId: Scalars['String'];
  /** Reset user to Default User role */
  resetUser: Scalars['Boolean'];
  /** Resets welcome message shown flag for given tenantId and userId combination */
  resetWelcomeMessageShown: Scalars['Boolean'];
  /**
   * Resumes a suspended budget by given Id
   *
   * Expired subscription type of budgets can not be resumed
   */
  resumeBudget: Scalars['Boolean'];
  /** Revoke all permissions for the user */
  revokeUserPermissions: Scalars['Boolean'];
  /** Save the current editor settings */
  saveEditorCombination: Scalars['Boolean'];
  /**
   * Sets default product for a tenant
   *
   *
   * **Returns:**
   * Updated Tenant
   * @deprecated There is no more use for Default Product
   */
  setDefaultProduct: Scalars['Boolean'];
  /** Set users as INDG_PM for the given tenant */
  setTenantProjectManagers: Scalars['Boolean'];
  /** Set the user with the INDG_Admin role */
  setUserAsAdmin: Scalars['Boolean'];
  /** Set the user as INDG_DEFAULT_USER for the given tenants */
  setUserAsDefaultUser: Scalars['Boolean'];
  /** Set the user as INDG_PM for the given tenants */
  setUserAsProjectManager: Scalars['Boolean'];
  /** Stashes given editor details input for later use */
  stashEditorDetailsInput: Scalars['String'];
  /** Suspend a budget by given Id */
  suspendBudget: Scalars['Boolean'];
  /** Toggles sales force id status for given Id */
  toggleSalesForceId: Scalars['Boolean'];
  /**
   * Updates the additional image type for given id
   *
   *
   * **Returns:**
   * Updated additional image type
   */
  updateAdditionalImageType: AdditionalImageTypeModel;
  updateAdvancedFiltersSettings: AdvancedFiltersSettings;
  /**
   * Updates the artworkSet for given artworkSet Id
   *
   *
   * **Returns:**
   * Updated artworkSet
   */
  updateArtworkSet: ArtworkSetModel;
  /**
   * Update the brand
   *
   *
   * **Returns:**
   * Updated brand
   */
  updateBrand: BrandModel;
  updateBudgetPremiumOrdering: Scalars['Boolean'];
  /**
   * Update Content Kit
   *
   *
   * **Returns:**
   * Updated Content Kit
   */
  updateContentKit: ContentKitModel;
  /**
   * Re-Triggers Product default preview generation
   *
   *
   * **Returns:**
   * True if previews were successfully re-triggered
   */
  updateDefaultPreviews: Scalars['Boolean'];
  /**
   * Updates the draft product for given product id
   *
   *
   * **Returns:**
   * Updated product
   */
  updateDraftProduct: DraftProductModel;
  /**
   * Update genre
   *
   *
   * **Returns:**
   * Updated genre
   */
  updateGenre: GenreModel;
  /**
   * Updates the product for given product id
   *
   *
   * **Returns:**
   * Updated product
   */
  updateProduct: ProductModel;
  /**
   * Update a Product Group.
   *
   *
   * **Returns:**
   * Newly created product group
   */
  updateProductGroup: ProductGroupModel;
  /**
   * Updates the project for given project id
   *
   *
   * **Returns:**
   * Updated project
   */
  updateProject: ProjectModel;
  /**
   * Updates the scene for given id
   *
   *
   * **Returns:**
   * Updated scene type
   */
  updateScene: SceneModel;
  /**
   * Updates the shapeCollection for given shapeCollectionId
   *
   *
   * **Returns:**
   * Updated shapeCollection
   */
  updateShapeCollection: ShapeCollectionModel;
  /**
   * Update Image scene
   *
   *
   * **Returns:**
   * Edited Image scene
   */
  updateShapeCollectionImageScene: ShapeCollectionImageSceneModel;
  /**
   * Update UVRempa scene
   *
   *
   * **Returns:**
   * Edited UVRemap scene
   */
  updateShapeCollectionUVRemapScene: ShapeCollectionUvRemapSceneModel;
  /**
   * Update Video scene
   *
   *
   * **Returns:**
   * Edited Video scene
   */
  updateShapeCollectionVideoScene: ShapeCollectionVideoSceneModel;
  /** Update template */
  updateTemplate: TemplateModel;
  /**
   * Update the Tenant
   *
   *
   * **Returns:**
   * Updated Tenant
   */
  updateTenant: TenantModel;
  /**
   * Updates the UserProvisioningTable for a given id
   *
   *
   * **Returns:**
   * Newly created Tenant
   */
  updateUserProvisioningTable: UserProvisioningTableModel;
  /** Extracts the zip file and stores it on blob in relation with the shape for given Nas Id */
  uploadShapeImageSequence: Scalars['Boolean'];
  /**
   * Validates draft product
   *
   *
   * **Returns:**
   * True if product was successfully removed
   */
  validateDraftProduct: ValidationResultModel;
  /** Marks user as welcome message shown */
  welcomeMessageShown: Scalars['Boolean'];
};


export type MutationAddAdditionalImageArgs = {
  input: AdditionalImageInput;
};


export type MutationAddAdditionalImageTypeArgs = {
  input: AdditionalImageTypeInput;
};


export type MutationAddArtworkSetArgs = {
  input: ArtworkSetInput;
};


export type MutationAddArtworksArgs = {
  artworkSetId: Scalars['UUID'];
  artworks: Array<ArtworkInput>;
};


export type MutationAddBrandArgs = {
  input: AddBrandInput;
};


export type MutationAddBundleToBasketArgs = {
  items: Array<BasketBundleInput>;
};


export type MutationAddContentKitArgs = {
  input: ContentKitInput;
};


export type MutationAddCreditsArgs = {
  budgetId: Scalars['UUID'];
  credits: Scalars['Int'];
  description: Scalars['String'];
};


export type MutationAddDraftProductArgs = {
  input: DraftProductInput;
};


export type MutationAddGenreArgs = {
  input: AddGenreInput;
};


export type MutationAddItemToBasketArgs = {
  item: BasketSingleItemInput;
};


export type MutationAddPayPerUseBudgetArgs = {
  input: PayPerUseBudgetInput;
};


export type MutationAddProductArgs = {
  input: ProductInput;
};


export type MutationAddProductGroupArgs = {
  input: ProductGroupInput;
};


export type MutationAddProjectArgs = {
  input: ProjectInput;
};


export type MutationAddQaUserArgs = {
  inviteUserInput: CreateQaUserInput;
};


export type MutationAddSalesForceIdArgs = {
  budgetId: Scalars['UUID'];
  input: SalesForceInput;
};


export type MutationAddSceneArgs = {
  input: SceneInput;
};


export type MutationAddShapeCollectionArgs = {
  input: ShapeCollectionInput;
};


export type MutationAddShapeCollectionImageSceneArgs = {
  input: AddShapeCollectionImageSceneInput;
};


export type MutationAddShapeCollectionImageSceneByShapeIdArgs = {
  input: AddShapeCollectionImageSceneByShapeIdInput;
};


export type MutationAddShapeCollectionUvRemapSceneArgs = {
  input: AddShapeCollectionUvRemapSceneInput;
};


export type MutationAddShapeCollectionUvRemapSceneByShapeIdArgs = {
  input: AddShapeCollectionUvRemapSceneByShapeIdInput;
};


export type MutationAddShapeCollectionVideoSceneArgs = {
  input: AddShapeCollectionVideoSceneInput;
};


export type MutationAddShapeCollectionVideoSceneByShapeIdArgs = {
  input: AddShapeCollectionVideoSceneByShapeIdInput;
};


export type MutationAddSubscriptionBudgetArgs = {
  input: SubscriptionBudgetInput;
};


export type MutationAddTemplateArgs = {
  templateInput: TemplateInput;
};


export type MutationAddTenantArgs = {
  input: AddTenantInput;
};


export type MutationAddUserProvisioningTableArgs = {
  input: AddUserProvisioningTableInput;
};


export type MutationArchiveTemplateArgs = {
  templateId: Scalars['UUID'];
};


export type MutationAssignBudgetsForUserArgs = {
  budgetIds: Array<Scalars['UUID']>;
  userId: Scalars['UUID'];
};


export type MutationAssignProductGroupsArgs = {
  input: AssignProductGroupsInput;
};


export type MutationAssignUserRoleArgs = {
  assignUserRolesInput: AssignUserRolesInput;
};


export type MutationAssignUsersForBudgetArgs = {
  budgetId: Scalars['UUID'];
  userIds: Array<Scalars['UUID']>;
};


export type MutationBatchDownloadArgs = {
  downloadableCombinations: Array<Scalars['UUID']>;
};


export type MutationChangeBudgetNameArgs = {
  budgetId: Scalars['UUID'];
  name: Scalars['String'];
};


export type MutationChangeBudgetOwnerArgs = {
  budgetId: Scalars['UUID'];
  owner: Scalars['UUID'];
};


export type MutationChangeTenantViewArgs = {
  tenantId: Scalars['UUID'];
};


export type MutationCopyTemplateArgs = {
  input: CopyTemplateInput;
};


export type MutationDeleteBrandArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteBudgetArgs = {
  budgetId: Scalars['UUID'];
};


export type MutationDeleteContentKitArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteGenreArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};


export type MutationDeleteOrderItemArgs = {
  input: DeleteOrderItemInput;
};


export type MutationExportAsArgs = {
  exportAsInput: ExportAsInput;
};


export type MutationExtendSubscriptionArgs = {
  budgetId: Scalars['UUID'];
  duration: Scalars['Int'];
};


export type MutationFixOrderItemArgs = {
  fixOrderItemInput: FixOrderItemInput;
};


export type MutationInviteUserArgs = {
  inviteUserInput: InviteUserInput;
};


export type MutationMakeDraftProductLiveArgs = {
  id: Scalars['UUID'];
};


export type MutationNewUserNotificationArgs = {
  userId: Scalars['UUID'];
};


export type MutationNotifyOfFailedViewerArgs = {
  input: NotifyOfFailedViewerInput;
};


export type MutationOrderArgs = {
  budgetId: Scalars['UUID'];
  groupItemsIds: Array<Scalars['UUID']>;
};


export type MutationOrderPremiumArgs = {
  budgetId: Scalars['UUID'];
  groupItemsIds: Array<Scalars['UUID']>;
};


export type MutationPublishTemplateArgs = {
  input: PublishTemplateInput;
};


export type MutationReTriggerOrderItemArgs = {
  orderItemId: Scalars['UUID'];
};


export type MutationRefirePreviewRequestArgs = {
  previewIds: Array<Scalars['UUID']>;
};


export type MutationRefreshArtworkArgs = {
  id: Scalars['UUID'];
};


export type MutationRefreshShapeArgs = {
  id: Scalars['UUID'];
  type: ShapeType;
};


export type MutationRemoveAdditionalImageArgs = {
  additionalImageId: Scalars['UUID'];
};


export type MutationRemoveAdditionalImageTypeArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveArtworkSetArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveArtworksArgs = {
  artworkSetId: Scalars['UUID'];
  artworks: Array<Scalars['UUID']>;
};


export type MutationRemoveDraftProductArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveEditorCombinationArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveItemFromBasketArgs = {
  id: Scalars['UUID'];
  removalType: BasketItemRemovalTypeInput;
};


export type MutationRemovePreviewArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveProductGroupArgs = {
  productGroupId: Scalars['UUID'];
};


export type MutationRemoveProjectArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveSceneArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveShapeCollectionArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveShapeCollectionSceneArgs = {
  shapeCollectionSceneId: Scalars['UUID'];
};


export type MutationRemoveTemplateArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveTenantArgs = {
  id: Scalars['UUID'];
};


export type MutationRemoveUserProvisioningTableArgs = {
  id: Scalars['UUID'];
};


export type MutationRequestPreviewsArgs = {
  templateId: Scalars['UUID'];
};


export type MutationRequestPreviewsByProductIdArgs = {
  productId: Scalars['UUID'];
};


export type MutationResetUserArgs = {
  userId: Scalars['UUID'];
};


export type MutationResetWelcomeMessageShownArgs = {
  tenantId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


export type MutationResumeBudgetArgs = {
  budgetId: Scalars['UUID'];
};


export type MutationRevokeUserPermissionsArgs = {
  userId: Scalars['UUID'];
};


export type MutationSaveEditorCombinationArgs = {
  input: EditorInput;
};


export type MutationSetDefaultProductArgs = {
  defaultProductId: Scalars['UUID'];
};


export type MutationSetTenantProjectManagersArgs = {
  tenantId: Scalars['UUID'];
  userIds: Array<Scalars['UUID']>;
};


export type MutationSetUserAsAdminArgs = {
  userId: Scalars['UUID'];
};


export type MutationSetUserAsDefaultUserArgs = {
  tenantIds: Array<Scalars['UUID']>;
  userId: Scalars['UUID'];
};


export type MutationSetUserAsProjectManagerArgs = {
  tenantIds: Array<Scalars['UUID']>;
  userId: Scalars['UUID'];
};


export type MutationStashEditorDetailsInputArgs = {
  input: EditorDetailsInput;
};


export type MutationSuspendBudgetArgs = {
  budgetId: Scalars['UUID'];
};


export type MutationToggleSalesForceIdArgs = {
  id: Scalars['UUID'];
};


export type MutationUpdateAdditionalImageTypeArgs = {
  id: Scalars['UUID'];
  input: AdditionalImageTypeInput;
};


export type MutationUpdateAdvancedFiltersSettingsArgs = {
  input: AdvancedFiltersSettingsInput;
};


export type MutationUpdateArtworkSetArgs = {
  id: Scalars['UUID'];
  input: ArtworkSetInput;
};


export type MutationUpdateBrandArgs = {
  id: Scalars['UUID'];
  input: UpdateBrandInput;
};


export type MutationUpdateBudgetPremiumOrderingArgs = {
  input: UpdateBudgetPremiumOrderingInput;
};


export type MutationUpdateContentKitArgs = {
  id: Scalars['UUID'];
  input: ContentKitInput;
};


export type MutationUpdateDefaultPreviewsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationUpdateDraftProductArgs = {
  id: Scalars['UUID'];
  input: DraftProductInput;
};


export type MutationUpdateGenreArgs = {
  id: Scalars['UUID'];
  input: UpdateGenreInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['UUID'];
  input: ProductInput;
};


export type MutationUpdateProductGroupArgs = {
  input: ProductGroupInput;
  productGroupId: Scalars['UUID'];
};


export type MutationUpdateProjectArgs = {
  id: Scalars['UUID'];
  input: ProjectInput;
};


export type MutationUpdateSceneArgs = {
  id: Scalars['UUID'];
  input: SceneInput;
};


export type MutationUpdateShapeCollectionArgs = {
  id: Scalars['UUID'];
  input: ShapeCollectionInput;
};


export type MutationUpdateShapeCollectionImageSceneArgs = {
  input: UpdateShapeCollectionImageSceneInput;
};


export type MutationUpdateShapeCollectionUvRemapSceneArgs = {
  input: UpdateShapeCollectionUvRemapSceneInput;
};


export type MutationUpdateShapeCollectionVideoSceneArgs = {
  input: UpdateShapeCollectionVideoSceneInput;
};


export type MutationUpdateTemplateArgs = {
  id: Scalars['UUID'];
  templateInput: TemplateInput;
};


export type MutationUpdateTenantArgs = {
  id: Scalars['UUID'];
  input: UpdateTenantInput;
};


export type MutationUpdateUserProvisioningTableArgs = {
  input: UpdateUserProvisioningTableInput;
};


export type MutationUploadShapeImageSequenceArgs = {
  imageSequence: Scalars['Upload'];
  shapeId: Scalars['UUID'];
};


export type MutationValidateDraftProductArgs = {
  input: DraftProductInput;
};

export type MyOrderCreatedAtRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type MyOrderListModel = {
  __typename?: 'MyOrderListModel';
  /** Total number of MyOrders templates */
  filteredTotal: Scalars['Int'];
  /** List of MyOrders */
  myOrders: Array<OrderModel>;
};


export type MyOrderListModelMyOrdersArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum MyOrderSortBy {
  BudgetName = 'BUDGET_NAME',
  CreditsUsed = 'CREDITS_USED',
  OrderedDate = 'ORDERED_DATE',
  TotalItems = 'TOTAL_ITEMS',
  UserEmail = 'USER_EMAIL'
}

export type MyOrdersFiltersInput = {
  createdAtRange?: InputMaybe<MyOrderCreatedAtRangeInput>;
  currentUserOrdersOnly: Scalars['Boolean'];
  /** Text to search for (OrderID, BudgetName, ProductName) */
  searchTerm?: InputMaybe<Scalars['String']>;
  /** Status of the order to search (Pending and Completed) */
  status?: InputMaybe<FilterOrderStatus>;
  /** Globally unique identifier of an entity */
  suggestionId?: InputMaybe<Scalars['UUID']>;
  /** Type of an entity */
  suggestionType?: InputMaybe<OrderSearchEntityType>;
};

export type MyOrdersSortInput = {
  /** Contains which column to use in sorting */
  sortBy: MyOrderSortBy;
  /** Contains which order to use in sorting */
  sortDirection: SortDirection;
};

export type NotifyOfFailedViewerInput = {
  linkToViewer: Scalars['String'];
  productIds: Array<Scalars['UUID']>;
  reason: Scalars['String'];
  templateId: Scalars['UUID'];
};

export enum OperatorHelper {
  And = 'AND',
  Or = 'OR'
}

/** Model to update optional files */
export type OptionalFileInput = {
  /** File to be updated */
  file?: InputMaybe<Scalars['Upload']>;
};

export type OrderItemModel = {
  __typename?: 'OrderItemModel';
  basketHashKey: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contentKitId: Scalars['UUID'];
  cost: Scalars['Int'];
  downloadUrl?: Maybe<Scalars['String']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failureReason?: Maybe<Scalars['String']>;
  fileInfo?: Maybe<FileInfo>;
  generatorRunId: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  mainProductId: Scalars['UUID'];
  outputVariant: Scalars['String'];
  premiumOrdering?: Maybe<PremiumOrderingModel>;
  preview: PreviewModel;
  processingStartedAt?: Maybe<Scalars['DateTime']>;
  productSelections: Array<KeyValuePairOfStringAndProductSelectionModel>;
  products: Array<SimplifiedProductModel>;
  retryCount: Scalars['Int'];
  runId: Scalars['String'];
  status: OrderItemStatus;
  template: TemplateModel;
  templateVersion: Scalars['Int'];
  thumbnailUrl: Scalars['String'];
  userInputs: Array<KeyValuePairOfStringAndString>;
  width: Scalars['Int'];
};

/** Order item status */
export enum OrderItemStatus {
  /** Completed - Order item was processed successfully and is now downloadable */
  Completed = 'COMPLETED',
  /** Failed - Failed order item, setting an order item to "Failed" requires manual interaction. */
  Failed = 'FAILED',
  /**
   * Pending - Initial state of a newly created order item. Only order items in the pending state are
   * being processed and are ready to be sent to the Generator
   */
  Pending = 'PENDING',
  /** Processing - The order item was sent to the Generator. */
  Processing = 'PROCESSING',
  /** Order item could not be processed and now is waiting for human interaction. */
  ToFix = 'TO_FIX',
  /** Order items that require human interaction through JMS to be completed. */
  UrlOutputOnJms = 'URL_OUTPUT_ON_JMS'
}

export type OrderModel = {
  __typename?: 'OrderModel';
  budget: BudgetModel;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  isProcessed: Scalars['Boolean'];
  orderCompleted?: Maybe<Scalars['DateTime']>;
  orderItems: Array<OrderItemModel>;
  orderPartial?: Maybe<Scalars['DateTime']>;
  orderRendering?: Maybe<Scalars['DateTime']>;
  orderedBy: Scalars['String'];
  status: OrderStatus;
  totalCost: Scalars['Int'];
};

export type OrderQuickSearchInput = {
  searchOwnerEmail: Scalars['Boolean'];
  text: Scalars['String'];
};

export enum OrderSearchEntityType {
  Budget = 'BUDGET',
  Order = 'ORDER',
  Product = 'PRODUCT',
  User = 'USER'
}

/**
 * Order status
 * In case one or more order items were deleted those items will not be taken into account when
 * deciding the final state of the order and they are being ignored
 */
export enum OrderStatus {
  /** Completed - All order items are either in Completed or Failed state */
  Completed = 'COMPLETED',
  /**
   * Partially Completed Order
   * None of the order items is in Pending or Processing state but has at least one either
   * OnJMS (FixIt!) or UrlOutputOnJms
   */
  Partial = 'PARTIAL',
  /**
   * Pending - The order is in progress and sent to corresponding services to be processed.
   * OrderItems under this order at least have one of the following Pending, Processing
   */
  Pending = 'PENDING',
  /**
   * ToFix - Pending order that failed enough times to start processing
   * Order could not be processed and now is waiting for human interaction. This state will
   */
  ToFix = 'TO_FIX'
}

export type OrderSuggestion = OrderSuggestionModel & {
  __typename?: 'OrderSuggestion';
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: OrderSearchEntityType;
};

export type OrderSuggestionModel = {
  /** Globally unique identifier of an entity */
  id: Scalars['UUID'];
  /** Name of the entity */
  name: Scalars['String'];
  /** Type of an entity */
  type: OrderSearchEntityType;
};

export type OutputCombinationConnection = {
  __typename?: 'OutputCombinationConnection';
  edges: Array<OutputCombinationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long'];
};

export type OutputCombinationEdge = {
  __typename?: 'OutputCombinationEdge';
  cursor: Scalars['String'];
  node: OutputCombinationModel;
};

export enum OutputCombinationFileType {
  Image = 'IMAGE',
  Unknown = 'UNKNOWN',
  Video = 'VIDEO'
}

export type OutputCombinationGroupListModel = {
  __typename?: 'OutputCombinationGroupListModel';
  /** SearchId named as Id for FE use */
  id: Scalars['String'];
  /** Collection of output-combination groups */
  items: Array<OutputCombinationGroupModel>;
  /**
   * Unique identifier for the search context. Can also be used as a unique hash as shortened version of the search context.
   *
   * Shortened version of the search context does not include pagination information. In re-use scenarios please pass pagination information to the query separately.
   */
  searchId: Scalars['String'];
  /**
   * Total number of output-combination groups
   *
   * Returns zero unless Items are requested
   */
  total: Scalars['Long'];
};


export type OutputCombinationGroupListModelItemsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type OutputCombinationGroupModel = {
  /** Unique group identifier */
  id: Scalars['String'];
  /** Based on the layout option, this will be either a product or content kit id */
  mainEntityId: Scalars['UUID'];
  /** Based on the layout option, this will be either a product or content kit name */
  name: Scalars['String'];
  /** Default previews for the group in other words previews within a row on IS */
  outputCombinations: OutputCombinationListModel;
};

/** Template grouping info */
export type OutputCombinationGroupingInfoModel = {
  __typename?: 'OutputCombinationGroupingInfoModel';
  /** Completed order count - number of output-combination previews that are ordered */
  completedCount: Scalars['Long'];
  /** Content-Kit id used for grouping. Content-Kit id will be null on product layout */
  contentKitId?: Maybe<Scalars['UUID']>;
  /** In progress order count - number of output-combination previews that are ordered but not yet completed */
  inProgressCount: Scalars['Long'];
  /** Product id used for grouping. */
  productId: Scalars['UUID'];
  /** Template id used for grouping. */
  templateId: Scalars['UUID'];
  /** Total count - number of ordered output-combinations */
  totalCount: Scalars['Long'];
};

export type OutputCombinationListModel = {
  __typename?: 'OutputCombinationListModel';
  /** Collection of output-combinations */
  items: Array<OutputCombinationModel>;
  /**
   * Total number of output-combinations
   *
   * Returns zero unless Items are requested
   */
  total: Scalars['Long'];
};


export type OutputCombinationListModelItemsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type OutputCombinationModel = {
  __typename?: 'OutputCombinationModel';
  /** Generator configuration for the output combination */
  configuration: ConfigurationModel;
  /** Content Kit */
  contentKit: ContentKitModel;
  downloadUrl?: Maybe<Scalars['String']>;
  /** Template grouping info, intended use is for displaying grouping info in the UI */
  groupingInfo: OutputCombinationGroupingInfoModel;
  /** Hash key common between basket items and ordered items */
  hashKey?: Maybe<Scalars['String']>;
  /** Generated Id for this output combination */
  id: Scalars['String'];
  /** True if the output combination is ordered and has a full quality image */
  isDownloadable: Scalars['Boolean'];
  isSaved: Scalars['Boolean'];
  /** Main product used to create the combination */
  mainProductId: Scalars['UUID'];
  /** Order information for ordered output-combination */
  orderInfo?: Maybe<OutputCombinationOrderInfo>;
  /** Products */
  products: Array<ProductModel>;
  /** OutputCombination Status */
  status: OutputCombinationStatus;
  /** Template */
  template: TemplateModel;
  /** Preview */
  thumbnail: PreviewModel;
};

export type OutputCombinationOrderInfo = {
  __typename?: 'OutputCombinationOrderInfo';
  createdBy: Scalars['UUID'];
  downloadUrl?: Maybe<Scalars['String']>;
  fileInfo?: Maybe<FileInfo>;
  orderId: Scalars['UUID'];
  orderItemId: Scalars['UUID'];
  orderedAt: Scalars['DateTime'];
  preview: PreviewModel;
  status: OrderItemStatus;
  templateVersion: Scalars['Int'];
};

export enum OutputCombinationStatus {
  Downloadable = 'DOWNLOADABLE',
  InProgress = 'IN_PROGRESS',
  Preview = 'PREVIEW',
  Saved = 'SAVED'
}

/** OutputFile Format */
export enum OutputFormat {
  Jpg = 'JPG',
  Png = 'PNG',
  Tiff = 'TIFF'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PayPerUseBudgetInput = {
  /** Single credit value equivalent in Euros */
  creditValue: Scalars['Decimal'];
  /** Initial credit value for PrePaid budget */
  credits?: Scalars['Int'];
  /** Budget name */
  name: Scalars['String'];
  /** Budget owner UserId */
  ownerId: Scalars['UUID'];
  /** PrePaid | PostPaid */
  paymentType: BudgetPaymentType;
  /** Optional SalesForce Reference */
  salesForceReference?: InputMaybe<SalesForceInput>;
};

export type PremiumOrderingModel = {
  __typename?: 'PremiumOrderingModel';
  enabled: Scalars['Boolean'];
  multiplier: Scalars['Int'];
};

export type PreviewModel = {
  __typename?: 'PreviewModel';
  /** Processing failure reason */
  failureReason?: Maybe<Scalars['String']>;
  /** Height */
  height: Scalars['Int'];
  /** PreviewId */
  id: Scalars['UUID'];
  mainProductId: Scalars['UUID'];
  outputFullType: TemplateOutputType;
  /** Product selections, Key is the product input name from Template.Metadata */
  productSelections: Array<KeyValuePairOfStringAndProductSelectionModel>;
  /** List of products used in the preview */
  products: Array<InfoModel>;
  /** Generator Id for the processing */
  runId: Scalars['Float'];
  /** Status of the preview */
  status: PreviewStatus;
  template: TemplateModel;
  /** Url for the completed preview */
  url?: Maybe<Scalars['String']>;
  /** Width */
  width: Scalars['Int'];
};

export type PreviewStatsModel = {
  __typename?: 'PreviewStatsModel';
  completed: Scalars['Long'];
  failed: Scalars['Long'];
  processing: Scalars['Long'];
  total: Scalars['Long'];
};

export enum PreviewStatus {
  /** Preview completed */
  Completed = 'COMPLETED',
  /** Preview failed to process, check FailureReason */
  Failed = 'FAILED',
  /** Preview processing */
  Processing = 'PROCESSING'
}

/** Represents a model for managing preview lists. */
export type PreviewsListModel = {
  __typename?: 'PreviewsListModel';
  /**
   * Gets a total count of previews based on the specified filters.
   *
   *
   * **Returns:**
   * The total count of filtered previews.
   */
  filteredTotal: Scalars['Long'];
  /**
   * Asynchronously gets a list of previews based on the specified filters, page, and page size.
   *
   *
   * **Returns:**
   * A list of PreviewModel.
   */
  previews: Array<PreviewModel>;
};


/** Represents a model for managing preview lists. */
export type PreviewsListModelPreviewsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type ProductCompatibilityInput = {
  additionalImageTypeIds?: InputMaybe<Array<Scalars['UUID']>>;
  artworkSetFilter?: InputMaybe<ArtworkSetCompatibilityInput>;
  brandIds?: InputMaybe<Array<Scalars['UUID']>>;
  keyword?: InputMaybe<Scalars['String']>;
  shapeCollectionFilter?: InputMaybe<ShapeCollectionCompatibilityInput>;
  skuList?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductCompatibilityOptions = {
  __typename?: 'ProductCompatibilityOptions';
  additionalImageTypes: Array<InfoModel>;
  artworkSetCompatibilityOptions: ArtworkSetCompatibilityOptions;
  brands: Array<InfoModel>;
  shapeCollectionCompatibilityOptions: ShapeCollectionCompatibilityOptions;
};

export type ProductCompatibilityResult = {
  __typename?: 'ProductCompatibilityResult';
  failureReason?: Maybe<Scalars['String']>;
  isCompatible: Scalars['Boolean'];
  productId: Scalars['UUID'];
};

export type ProductFilter = {
  __typename?: 'ProductFilter';
  additionalImageTypeIds?: Maybe<Array<Scalars['UUID']>>;
  artworkSetFilter?: Maybe<ArtworkSetFilter>;
  brandIds?: Maybe<Array<Scalars['UUID']>>;
  shapeCollectionFilter?: Maybe<ShapeCollectionFilter>;
  skuList?: Maybe<Array<Scalars['String']>>;
};

export type ProductFilterConnection = {
  __typename?: 'ProductFilterConnection';
  edges: Array<ProductFilterOptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long'];
};

export type ProductFilterOption = {
  __typename?: 'ProductFilterOption';
  /** ID of the filter item */
  id: Scalars['String'];
  /** Name of the filter option item, this is only for display purposes */
  name: Scalars['String'];
  product: ProductModel;
  /** Product hit count for this filter */
  productCount: Scalars['Long'];
};

export type ProductFilterOptionEdge = {
  __typename?: 'ProductFilterOptionEdge';
  cursor: Scalars['String'];
  node: ProductFilterOption;
};

export type ProductFilterOptionItem = FilterOptionItem & {
  __typename?: 'ProductFilterOptionItem';
  globalId: ProductGlobalId;
  /** Id of the item */
  id: Scalars['UUID'];
  /** Name of the filter option item, this is only for display purposes */
  name: Scalars['String'];
  product?: Maybe<ProductModel>;
  /** Thumbnail/Preview of the filter option item */
  thumbnailUrl: Scalars['String'];
};

/** Object/Type that represents a product global id */
export type ProductGlobalId = {
  __typename?: 'ProductGlobalId';
  /**
   * Client specific unique identifier of a trade item (product)
   *
   * This id is the external id of a product which is an unique identifier for our clients
   */
  id: Scalars['String'];
  /** Defines the format for the id */
  type: ProductIdType;
};

/** Object/Type that represents a product global id */
export type ProductGlobalIdInput = {
  /**
   * Client specific unique identifier of a trade item (product)
   *
   * This id is the external id of a product which is an unique identifier for our clients
   */
  id: Scalars['String'];
  /** Defines the format for the id */
  type: ProductIdType;
};

export type ProductGroupFilterConnection = {
  __typename?: 'ProductGroupFilterConnection';
  edges: Array<ProductGroupFilterOptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long'];
};

export type ProductGroupFilterOption = {
  __typename?: 'ProductGroupFilterOption';
  /** ID of the filter item */
  id: Scalars['String'];
  name: Scalars['String'];
  values: ProductFilterConnection;
};


export type ProductGroupFilterOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  selectedAdvancedFilters?: InputMaybe<SelectedAdvancedFiltersInput>;
  textPhrase?: InputMaybe<Scalars['String']>;
};

export type ProductGroupFilterOptionEdge = {
  __typename?: 'ProductGroupFilterOptionEdge';
  cursor: Scalars['String'];
  node: ProductGroupFilterOption;
};

/** Product group input */
export type ProductGroupInput = {
  name: Scalars['String'];
  productIds: Array<Scalars['UUID']>;
  userIds: Array<Scalars['UUID']>;
};

/** Product group */
export type ProductGroupModel = {
  __typename?: 'ProductGroupModel';
  /** Product Group Id */
  id: Scalars['UUID'];
  /** Product Group Name */
  name: Scalars['String'];
  /** List of products assigned to this group */
  products: Array<InfoModel>;
  /** List of users assigned to this group */
  users: Array<InfoModel>;
};

export type ProductGroupProductOptionsFiltersInput = {
  /** Id to search by Brand */
  brandId?: InputMaybe<Scalars['UUID']>;
  /** Id to search by ProductGroup */
  productGroupId?: InputMaybe<Scalars['UUID']>;
  /** Text to search for (ProductName, BrandName, Variant, GlobalId and ShapeCollection name) */
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Enumerations of different types of global product id formats */
export enum ProductIdType {
  /** Country-code + gtin for disambiguation for countries using the same gtin: e.g. usa_00049000093261 */
  CountryGtin = 'COUNTRY_GTIN',
  /** 8-digit EAN (type of GTIN) */
  Gtin8 = 'GTIN8',
  /** 12-digit GTIN */
  Gtin12 = 'GTIN12',
  /** 13-digit GTIN */
  Gtin13 = 'GTIN13',
  /** 14-digit GTIN */
  Gtin14 = 'GTIN14',
  /** Unique code for a different system PIM/Salsify/DAM etc. */
  PimCode = 'PIM_CODE',
  /** 12-digit UPC */
  UpcA = 'UPC_A',
  /** 8-digit UPC */
  UpcE = 'UPC_E'
}

/** Product input type to create and update a product */
export type ProductInput = {
  /** Array of associated artworkSet ids */
  artworkSets: Array<Scalars['UUID']>;
  /** Associated brand id */
  brandId: Scalars['UUID'];
  /** Key/Value object to store client specific metadata */
  metadata: Array<KeyValuePairOfStringAndStringInput>;
  /** Name of the product. */
  name: Scalars['String'];
  /** Globally unique identifier of a product */
  productGlobalId: ProductGlobalIdInput;
  /** Associated project id */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Associated shapeCollection id */
  shapeCollectionId: Scalars['UUID'];
  /**
   * Product variant
   *
   * Flavor in case of a drink, "Cherry"
   * Scent in case of a perfume, such as "Fresh"
   */
  variant: Scalars['String'];
};

export type ProductListFiltersInput = {
  /** Id to search by Brand */
  brandId?: InputMaybe<Scalars['UUID']>;
  /** Text to search for (ProductName, BrandName, Variant, GlobalId and ShapeCollection name) */
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type ProductListModel = {
  __typename?: 'ProductListModel';
  /** Total number of filtered products */
  filteredTotal: Scalars['Int'];
  /** List of products */
  products: Array<ProductModel>;
};


export type ProductListModelProductsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum ProductListSortBy {
  BrandName = 'BRAND_NAME',
  GlobalId = 'GLOBAL_ID',
  ModifiedAt = 'MODIFIED_AT',
  ProductId = 'PRODUCT_ID',
  ProductName = 'PRODUCT_NAME',
  ShapeCollectionName = 'SHAPE_COLLECTION_NAME',
  Variant = 'VARIANT'
}

export type ProductListSortInput = {
  /** Contains which column to use in sorting */
  sortBy: ProductListSortBy;
  /** Contains which order to use in sorting */
  sortDirection: SortDirection;
};

/** Object/Type that represents a product */
export type ProductModel = {
  __typename?: 'ProductModel';
  additionalImages: Array<AdditionalImageModel>;
  /** Array of associated artworkSets */
  artworkSets: Array<ArtworkSetModel>;
  /** Associated brand of the product. */
  brand: BrandModel;
  /** Globally unique identifier of a product (trade item) */
  globalId?: Maybe<ProductGlobalId>;
  id: Scalars['UUID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** Key/Value object to store client specific metadata */
  metadata: Array<KeyValuePairOfStringAndString>;
  /** Name of the product. Can not be null or empty. */
  name: Scalars['String'];
  previewStats: PreviewStatsModel;
  /** Associated project of the product. */
  project?: Maybe<ProjectModel>;
  shapeCollection: ShapeCollectionModel;
  thumbnailUrl: Scalars['String'];
  /**
   * Product variant
   *
   * Flavor in case of a drink, "Cherry"
   * Scent in case of a perfume, such as "Fresh"
   */
  variant: Scalars['String'];
};

/** Object/Type that represents a product */
export type ProductModelInput = {
  additionalImages: Array<AdditionalImageModelInput>;
  /** Array of associated artworkSets */
  artworkSets: Array<ArtworkSetModelInput>;
  /** Associated brand of the product. */
  brand: BrandModelInput;
  /** Globally unique identifier of a product (trade item) */
  globalId?: InputMaybe<ProductGlobalIdInput>;
  id: Scalars['UUID'];
  lastModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** Key/Value object to store client specific metadata */
  metadata: Array<KeyValuePairOfStringAndStringInput>;
  /** Name of the product. Can not be null or empty. */
  name: Scalars['String'];
  /** Associated project of the product. */
  project?: InputMaybe<ProjectModelInput>;
  /**
   * Product variant
   *
   * Flavor in case of a drink, "Cherry"
   * Scent in case of a perfume, such as "Fresh"
   */
  variant: Scalars['String'];
};

export type ProductOrderSuggestion = OrderSuggestionModel & {
  __typename?: 'ProductOrderSuggestion';
  globalId?: Maybe<ProductGlobalId>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: OrderSearchEntityType;
};

export type ProductOutputCombinationGroupModel = OutputCombinationGroupModel & {
  __typename?: 'ProductOutputCombinationGroupModel';
  /** Main product brand */
  brand: BrandModel;
  /** Main product global id */
  globalId?: Maybe<ProductGlobalId>;
  id: Scalars['String'];
  mainEntityId: Scalars['UUID'];
  name: Scalars['String'];
  outputCombinations: OutputCombinationListModel;
  /** Full product details */
  product: ProductModel;
  /** Default product preview for product card */
  thumbnailUrl: Scalars['String'];
  /** Main product variant name */
  variant: Scalars['String'];
};

export type ProductPreviewsFiltersInput = {
  /** Id to search by Product */
  productId: Scalars['UUID'];
  /** Text to search for (TemplateName, TemplateId or PreviewId) */
  searchTerm?: InputMaybe<Scalars['String']>;
  /** Status to search */
  status?: InputMaybe<PreviewStatus>;
  /** Id to search by Template */
  templateId?: InputMaybe<Scalars['UUID']>;
};

export type ProductSearchFilters = {
  __typename?: 'ProductSearchFilters';
  /** Selected brand id filters */
  brands?: Maybe<FindByIdFilter>;
  /** Selected product id filters */
  products?: Maybe<FindByIdFilter>;
};

export type ProductSearchFiltersInput = {
  /** Selected brand id filters */
  brands?: InputMaybe<FindByIdFilterInput>;
  /** Selected product id filters */
  products?: InputMaybe<FindByIdFilterInput>;
};

/** Product selection input to define user preferences in the editor */
export type ProductSelectionInput = {
  artworkId: Scalars['UUID'];
  entityId: Scalars['UUID'];
  entityType: EntityType;
  productId: Scalars['UUID'];
  shapeCollectionId: Scalars['UUID'];
};

/** Product selection, represents the combination of shape and artworks of the product to generate an image */
export type ProductSelectionModel = {
  __typename?: 'ProductSelectionModel';
  /** Selected Artwork Id */
  artworkId: Scalars['UUID'];
  /** Selected entityId such as shape.Id, scene.Id, etc */
  entityId: Scalars['UUID'];
  /** Type of entity to be used for rendering purposes. Options are SHAPE, pre-rendered SCENE */
  entityType: EntityType;
  /** Product Id */
  productId: Scalars['UUID'];
  /** Selected ShapeCollection Id */
  shapeCollectionId: Scalars['UUID'];
};

export type ProductSuggestion = SuggestionModel & {
  __typename?: 'ProductSuggestion';
  globalId?: Maybe<ProductGlobalId>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: SearchEntityType;
};

/** Project input type to create and update a project */
export type ProjectInput = {
  /** Name of the project. Can not be null or empty */
  name: Scalars['String'];
};

/** Object/Type that represents a project */
export type ProjectModel = {
  __typename?: 'ProjectModel';
  /** Unique Internal project Id */
  id: Scalars['UUID'];
  /** Name of the project. Can not be null or empty. */
  name: Scalars['String'];
};

/** Object/Type that represents a project */
export type ProjectModelInput = {
  /** Unique Internal project Id */
  id: Scalars['UUID'];
  /** Name of the project. Can not be null or empty. */
  name: Scalars['String'];
};

export type PublishTemplateInput = {
  regeneratePreviews: Scalars['Boolean'];
  templateId: Scalars['UUID'];
};

export type Query = {
  __typename?: 'Query';
  /** Get additional image type by Id */
  additionalImageTypeById: AdditionalImageTypeModel;
  /** Get all additional image types */
  additionalImageTypes: Array<AdditionalImageTypeModel>;
  additionalImagesByProductId: Array<AdditionalImageModel>;
  adminBudgets: BudgetListModel;
  advancedFiltersSettings: AdvancedFiltersSettings;
  /** Returns advanced filter options for products */
  advancedProductFilterOptions: AdvancedProductFilterOptions;
  /**
   * Gets all output-combinations by given filters
   *
   *
   * **Returns:**
   * Returns single output-combination group. See IOutputCombinationGroupModel for more information.
   */
  allOutputCombinationsByFilters: OutputCombinationConnection;
  /**
   * Checks if the list of productIds are compatible with the provided filter and returns a boolean
   *
   *
   * **Returns:**
   * List product model
   */
  areProductsCompatible: Array<ProductCompatibilityResult>;
  /** Returns an ArtworkSet by given artworkSet Id */
  artworkSetById: ArtworkSetModel;
  /** Returns all artwork sets */
  artworkSets: ArtworkSetListModel;
  /**
   * Returns a brand by given brand Id
   *
   *
   * **Returns:**
   * Brand model
   */
  brandById: BrandModel;
  /**
   * Returns first matching brand by given brand name
   *
   *
   * **Returns:**
   * Brand model
   */
  brandByName: BrandModel;
  /**
   * Returns all brands that the user has access to
   *
   *
   * **Returns:**
   * Brand model
   */
  brands: Array<BrandModel>;
  budgetById: BudgetModel;
  budgets: BudgetListModel;
  /** Get Content Kit by the given Id */
  contentKitById: ContentKitModel;
  /** Return ContentKits filtered by given filter inputs */
  contentKits: ContentKitsModel;
  courierToken: Scalars['String'];
  /** Get the current basket */
  currentBasket: BasketModel;
  currentUser: CurrentUserModel;
  /**
   * Returns a draft product by given Id
   *
   *
   * **Returns:**
   * Product model
   */
  draftProductById: DraftProductModel;
  /**
   * Returns all draft products
   *
   *
   * **Returns:**
   * Product model
   */
  draftProducts: Array<DraftProductModel>;
  /** Get the hash key for the editor combination */
  editorCombinationDetails: EditorCombinationDetailsModel;
  /** Get editor initial values */
  editorInitialValues: EditorInitialValuesModel;
  /**
   * Expands a grouping by given template id and main entity id
   *
   *
   * **Returns:**
   * Returns a collection of output-combinations. See OutputCombinationModel for more information
   */
  expandOutputCombinationGrouping: Array<OutputCombinationModel>;
  filtersBySearchId: FiltersModel;
  /** Returns Weekly/Monthly financial reports by given dates and filters */
  financialReports: FinancialReportModel;
  /**
   * Returns products filtered by given filter inputs
   *
   *
   * **Returns:**
   * Product model
   */
  findCompatibleProducts: CompatibleProductSearchResult;
  /**
   * Searches for output-combination groups by given filters
   * Intended use is to search for output-combination groups and display them in a list for IS.
   *
   * Filters used for this search will be saved and can be used later, and also can be shared with other users.
   * searchId field is a MD5 hash of the search context. See  for more information.
   *
   *
   *
   * **Returns:**
   * Returns output-combination groups. See OutputCombinationGroupListModel for more information.
   */
  findOutputCombinationGroups: OutputCombinationGroupListModel;
  /**
   * Returns a genre by given brand Id
   *
   *
   * **Returns:**
   * Genre model
   */
  genreById: GenreModel;
  /**
   * Returns all genres that the user has access to
   *
   *
   * **Returns:**
   * Array of genre model
   */
  genres: Array<GenreModel>;
  /** Get INDG users */
  internalUsers: Array<InternalUserModel>;
  /**
   * Checks if a product compatible with the provided filter and returns a boolean
   *
   *
   * **Returns:**
   * Product model
   */
  isProductsCompatible: ProductCompatibilityResult;
  /** Get all tenants that the user has management access */
  managedTenants: Array<TenantModel>;
  /** Returns metadata advanced filter options for given metadata key */
  metadataFilterOptions: MetadataKeyFilterOption;
  /**
   * Get the MSAL configuration for a given email
   *
   *
   * **Returns:**
   * The authorization journey for the user
   */
  msalConfiguration: MsalConfigurationModel;
  /**
   * Returns list of MyOrders for a given search text
   *
   *
   * **Returns:**
   * List of MyOrders
   */
  myOrders: MyOrderListModel;
  /**
   * Returns order by given orderId
   *
   *
   * **Returns:**
   * Array of genre model
   */
  order: OrderModel;
  /** Returns product, budget, orders and users suggestions by given text */
  orderQuickSearch: Array<OrderSuggestionModel>;
  /**
   * Gets output-combination by given id
   *
   *
   * **Returns:**
   * Returns an output-combination by given id
   */
  outputCombinationById: OutputCombinationModel;
  /**
   * Gets output-combinations by given order id
   *
   *
   * **Returns:**
   * Returns a collection of output-combinations. by given order id
   */
  outputCombinationByOrderId: Array<OutputCombinationModel>;
  /**
   * Returns output-combination group by given search id (MD5 hash of the search context) and group id
   *
   *
   * **Returns:**
   * Returns single output-combination group. See IOutputCombinationGroupModel for more information.
   */
  outputCombinationGroup: OutputCombinationGroupModel;
  /**
   * Searches for output-combination groups by given search id (MD5 hash of the search context)
   * Intended use is for requesting more rows on IS or sharing a search result using the MD5 hash
   */
  outputCombinationGroups: OutputCombinationGroupListModel;
  /**
   * Returns a product by given product Id
   *
   *
   * **Returns:**
   * Product model
   */
  productById: ProductModel;
  /**
   * Returns available product search options
   *
   *
   * **Returns:**
   * Product model
   */
  productCompatibilityOptions: ProductCompatibilityOptions;
  /** Get product group by Id */
  productGroupById: ProductGroupModel;
  productGroupFilterOptions: ProductGroupFilterOption;
  /**
   * Return products (with those belonging to the product group first) filtered by given filter entries
   *
   *
   * **Returns:**
   * List of Products
   */
  productGroupProductOptions: ProductListModel;
  /** Get all product groups */
  productGroups: Array<ProductGroupModel>;
  /** Returns a list of previews for the given product and filters */
  productPreviews: PreviewsListModel;
  /**
   * Return products filtered by given filter inputs
   *
   *
   * **Returns:**
   * List of Products
   */
  products: ProductListModel;
  /**
   * Returns a project by given project Id
   *
   *
   * **Returns:**
   * Project model
   */
  projectById: ProjectModel;
  /**
   * Returns a list of projects that the user has access
   *
   *
   * **Returns:**
   * Project model
   */
  projects: Array<ProjectModel>;
  /** Returns product and content-kit suggestions by given text */
  quickSearch: Array<SuggestionModel>;
  /** Returns a scene by given Id */
  sceneById: SceneModel;
  /** Returns all Scenes */
  scenes: Array<SceneModel>;
  /**
   * Returns all available filter options based on given filters. Available filter options will be extracted from matching previews.
   *
   *
   * **Returns:**
   * Returns available filter options. See SearchFilterOptions for more information.
   */
  searchFilterOptions: SearchFilterOptions;
  searchIdByFilters: Scalars['String'];
  /**
   * Returns a search result summary for selected filters.
   * Intended use is to give a sneak peek of the search result before actually searching for output-combinations.
   *
   *
   * **Returns:**
   * Returns a summary of search result such as content-kit count, product count, etc. See SearchResultSummary for more information.
   */
  searchSummary: SearchResultSummary;
  /** Returns a ShapeCollection by given shapeCollectionId */
  shapeCollectionById: ShapeCollectionModel;
  shapeCollectionFilterOptions: ShapeCollectionFilterOption;
  /**
   * Get a shape collection scene
   *
   *
   * **Returns:**
   * Newly created scene
   */
  shapeCollectionSceneById: ShapeCollectionSceneModel;
  /**
   * Get all shape collection scenes from a shape collection
   *
   *
   * **Returns:**
   * Newly created scene
   */
  shapeCollectionScenesByShapeCollection: Array<ShapeCollectionSceneModel>;
  /** Returns a ShapeCollections for loggedin user */
  shapeCollections: ShapeCollectionListModel;
  /** Retrieves previously stashed editor details by given ticket */
  stashedEditorDetailsInput: StashItemModelOfEditorDetailsModel;
  /** Get Template by the given Id */
  templateById: TemplateModel;
  /** Returns a list of previews for the given template and filters */
  templatePreviews: PreviewsListModel;
  /** Get all templates */
  templates: TemplateListModel;
  tenant: TenantModel;
  /** Get all additional image types available for the current tenant */
  tenantAdditionalImageTypes: Array<AdditionalImageTypeModel>;
  /** Returns all Scenes allowed for the current tenant */
  tenantScenes: Array<SceneModel>;
  /** Get all tenants that the user has access */
  tenants: Array<TenantModel>;
  /** Returns transactions by dates and filters */
  transactionReports: TransactionReportModel;
  /**
   * Get the user journey for a given email
   *
   *
   * **Returns:**
   * The authorization journey for the user
   * @deprecated This query is deprecated and will be removed in the next update, use the MsalConfiguration query instead.
   */
  userJourney: UserJourneyModel;
  /** Get user profile */
  userProfile: UserProfileModel;
  /** Returns user provisioning table for given id */
  userProvisioningTableById: UserProvisioningTableModel;
  /** Returns all user provisioning tables */
  userProvisioningTables: Array<UserProvisioningTableModel>;
  /** Get tenant users */
  users: Array<UserWithBudgetsModel>;
};


export type QueryAdditionalImageTypeByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryAdditionalImagesByProductIdArgs = {
  productId: Scalars['UUID'];
};


export type QueryAdminBudgetsArgs = {
  filters?: InputMaybe<BudgetFiltersInput>;
  sortBy?: InputMaybe<BudgetSortType>;
};


export type QueryAdvancedProductFilterOptionsArgs = {
  searchFilters: SearchFilterOptionsInput;
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type QueryAllOutputCombinationsByFiltersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters: SearchFilterInput;
  first: Scalars['Int'];
  layout: SearchResultLayout;
  onlyShowMyOrders: Scalars['Boolean'];
  sortDirection: SortDirection;
};


export type QueryAreProductsCompatibleArgs = {
  compatibilityRequirements: ProductCompatibilityInput;
  productIds: Array<Scalars['UUID']>;
};


export type QueryArtworkSetByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryArtworkSetsArgs = {
  filters?: InputMaybe<ArtworkSetListFilterInput>;
  sort?: InputMaybe<ArtworkSetListFilterSortInput>;
};


export type QueryBrandByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryBrandByNameArgs = {
  name: Scalars['String'];
};


export type QueryBudgetByIdArgs = {
  budgetId: Scalars['UUID'];
};


export type QueryBudgetsArgs = {
  filters?: InputMaybe<BudgetFiltersInput>;
  sortBy?: InputMaybe<BudgetSortType>;
};


export type QueryContentKitByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryContentKitsArgs = {
  filters?: InputMaybe<ContentKitsFiltersInput>;
  sort?: InputMaybe<ContentKitsSortInput>;
};


export type QueryDraftProductByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryEditorCombinationDetailsArgs = {
  input: EditorDetailsInput;
};


export type QueryEditorInitialValuesArgs = {
  input: EditorInitialValuesInput;
};


export type QueryExpandOutputCombinationGroupingArgs = {
  contentKitId?: InputMaybe<Scalars['UUID']>;
  onlyShowMyOrders: Scalars['Boolean'];
  productId: Scalars['UUID'];
  searchId: Scalars['String'];
  sortDirection: SortDirection;
  templateId: Scalars['UUID'];
};


export type QueryFiltersBySearchIdArgs = {
  searchId: Scalars['String'];
};


export type QueryFinancialReportsArgs = {
  filters?: InputMaybe<FinancialReportFiltersInput>;
  from: Scalars['DateTime'];
  reportType: ReportType;
  sortBy?: InputMaybe<FinancialReportSortType>;
  to: Scalars['DateTime'];
};


export type QueryFindCompatibleProductsArgs = {
  compatibilityRequirements?: InputMaybe<ProductCompatibilityInput>;
};


export type QueryFindOutputCombinationGroupsArgs = {
  filters: SearchFilterInput;
  groupSortBy: SortBy;
  groupSortDirection: SortDirection;
  layout: SearchResultLayout;
  sortBy: SortBy;
  sortDirection: SortDirection;
};


export type QueryGenreByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryIsProductsCompatibleArgs = {
  compatibilityRequirements: ProductCompatibilityInput;
  product: ProductModelInput;
};


export type QueryMetadataFilterOptionsArgs = {
  metadataKeyFilterId: Scalars['String'];
  searchFilters: SearchFilterOptionsInput;
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type QueryMsalConfigurationArgs = {
  input: AuthenticationInput;
};


export type QueryMyOrdersArgs = {
  filters: MyOrdersFiltersInput;
  sort: MyOrdersSortInput;
};


export type QueryOrderArgs = {
  id: Scalars['UUID'];
};


export type QueryOrderQuickSearchArgs = {
  input: OrderQuickSearchInput;
};


export type QueryOutputCombinationByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryOutputCombinationByOrderIdArgs = {
  orderId: Scalars['UUID'];
};


export type QueryOutputCombinationGroupArgs = {
  groupId: Scalars['String'];
  groupSortBy: SortBy;
  groupSortDirection: SortDirection;
  searchId: Scalars['String'];
};


export type QueryOutputCombinationGroupsArgs = {
  searchId: Scalars['String'];
};


export type QueryProductByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryProductGroupByIdArgs = {
  productGroupId: Scalars['UUID'];
};


export type QueryProductGroupFilterOptionsArgs = {
  productGroupFilterId: Scalars['String'];
  searchFilters: SearchFilterOptionsInput;
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type QueryProductGroupProductOptionsArgs = {
  filters?: InputMaybe<ProductGroupProductOptionsFiltersInput>;
  sort?: InputMaybe<ProductListSortInput>;
};


export type QueryProductPreviewsArgs = {
  filters: ProductPreviewsFiltersInput;
};


export type QueryProductsArgs = {
  filters?: InputMaybe<ProductListFiltersInput>;
  sort?: InputMaybe<ProductListSortInput>;
};


export type QueryProjectByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryQuickSearchArgs = {
  text: Scalars['String'];
};


export type QuerySceneByIdArgs = {
  sceneId: Scalars['UUID'];
};


export type QuerySearchFilterOptionsArgs = {
  searchFilters: SearchFilterOptionsInput;
};


export type QuerySearchIdByFiltersArgs = {
  filters: SearchFilterInput;
  groupSortBy: SortBy;
  groupSortDirection: SortDirection;
  layout: SearchResultLayout;
  sortBy: SortBy;
  sortDirection: SortDirection;
};


export type QuerySearchSummaryArgs = {
  filters: SearchFilterInput;
};


export type QueryShapeCollectionByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryShapeCollectionFilterOptionsArgs = {
  searchFilters: SearchFilterOptionsInput;
  shapeCollectionFilterId: Scalars['String'];
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type QueryShapeCollectionSceneByIdArgs = {
  shapeCollectionSceneId: Scalars['UUID'];
};


export type QueryShapeCollectionScenesByShapeCollectionArgs = {
  shapeCollectionId: Scalars['UUID'];
};


export type QueryShapeCollectionsArgs = {
  filters?: InputMaybe<ShapeCollectionListFilterInput>;
  sort?: InputMaybe<ShapeCollectionListSortInput>;
};


export type QueryStashedEditorDetailsInputArgs = {
  key: Scalars['String'];
};


export type QueryTemplateByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryTemplatePreviewsArgs = {
  filters: TemplatePreviewsFiltersInput;
};


export type QueryTemplatesArgs = {
  filter?: InputMaybe<TemplateFiltersInput>;
  sort?: InputMaybe<TemplateSortInput>;
};


export type QueryTenantArgs = {
  id: Scalars['UUID'];
};


export type QueryTransactionReportsArgs = {
  filters?: InputMaybe<TransactionFiltersInput>;
  from: Scalars['DateTime'];
  sortBy?: InputMaybe<TransactionReportSortType>;
  to: Scalars['DateTime'];
};


export type QueryUserJourneyArgs = {
  input: AuthenticationInput;
};


export type QueryUserProvisioningTableByIdArgs = {
  id: Scalars['UUID'];
};

export type RangeFilter = {
  __typename?: 'RangeFilter';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type RangeFilterInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type ReportRowModel = {
  __typename?: 'ReportRowModel';
  autoTopUp: Scalars['Int'];
  budget: BudgetModel;
  creditValue: Scalars['Decimal'];
  creditsAdded: Scalars['Int'];
  creditsExceeded: Scalars['Int'];
  creditsRollover: Scalars['Int'];
  creditsUsed: Scalars['Int'];
  from: Scalars['DateTime'];
  id: Scalars['UUID'];
  /** Month or Week number */
  period: Scalars['Int'];
  reportType: ReportType;
  salesForceIds?: Maybe<Scalars['String']>;
  tenant: TenantModel;
  to: Scalars['DateTime'];
  totalCreditsValue: Scalars['Decimal'];
  year: Scalars['Int'];
};

export enum ReportType {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type SalesForceInput = {
  /** Optional SalesForceId description */
  description?: InputMaybe<Scalars['String']>;
  /** SalesForceId for financial use */
  salesForceId?: InputMaybe<Scalars['String']>;
};

export type SalesForceReferenceModel = {
  __typename?: 'SalesForceReferenceModel';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  salesForceId: Scalars['String'];
};

export type SceneInfoModel = {
  __typename?: 'SceneInfoModel';
  id: Scalars['UUID'];
  /** Scene Type Name */
  name: Scalars['String'];
  type: SceneType;
};

/** Additional File Type input */
export type SceneInput = {
  /** Type Description */
  description: Scalars['String'];
  /** File Type Name/Label */
  name: Scalars['String'];
  /** List of tenants ids with acces */
  tenants?: InputMaybe<Array<Scalars['UUID']>>;
  /** File Type. Ignored on update. */
  type?: InputMaybe<SceneType>;
};

export type SceneModel = {
  __typename?: 'SceneModel';
  /** Scene Type Description */
  description: Scalars['String'];
  /** Scene Type Id */
  id: Scalars['UUID'];
  /** Scene Type Name */
  name: Scalars['String'];
  /** List of tenents assigned to this type */
  tenants: Array<TenantModel>;
  type: SceneType;
};

export enum SceneType {
  /**
   * scene will contain
   *  - prerendered image
   *  - uv mapping image
   */
  Image = 'IMAGE',
  /**
   * scene will contain
   *  - prerendered mp4
   *  - zip of uv mapping files
   */
  Video = 'VIDEO',
  /**
   * scene will contain
   *  - zip of uv mapping files
   */
  VideoUvRemap = 'VIDEO_UV_REMAP'
}

export type SearchContext = {
  __typename?: 'SearchContext';
  contentKitFilters: ContentKitSearchFilters;
  groupSortBy: SortBy;
  groupSortDirection: SortDirection;
  layout: SearchResultLayout;
  productFilters: ProductSearchFilters;
  sortBy: SortBy;
  sortDirection: SortDirection;
  statusFilter: SearchStatusFilterFlags;
  textPhrase: Scalars['String'];
};

export enum SearchEntityType {
  Contentkit = 'CONTENTKIT',
  Product = 'PRODUCT'
}

export type SearchFilterInput = {
  /**
   * Selected content-kit filters to narrow down the search result. These filters contains array of genres and content-kits.
   * Id filter behavior could be tweaked by setting the requirement property. See FindByIdRequirement for more information.
   */
  contentKitFilters?: InputMaybe<ContentKitSearchFiltersInput>;
  /**
   * Selected product filters to narrow down the search result. These filters contains array of brands and products.
   * Id filter behavior could be tweaked by setting the requirement property. See FindByIdRequirement for more information.
   */
  productFilters?: InputMaybe<ProductSearchFiltersInput>;
  /** Preview status filter to narrow down filter options by type such as downloadable (completed order and ready to download), in-progress (ordered but not completed), etc. */
  statusFilter: SearchStatusFilterFlagsInput;
  /**
   * Text to search.
   * This text will be used to match previews, target fields for text search are:
   * - product.name
   * - product.variant
   * - product.brand.name
   * - product.globalId.id
   * - contentKit.name
   * - contentKit.genre.name
   */
  textPhrase?: InputMaybe<Scalars['String']>;
};

export type SearchFilterOptions = {
  __typename?: 'SearchFilterOptions';
  /** Available brand filter options */
  brands: FilterOptions;
  /** Available content-kit filter options */
  contentKits: FilterOptions;
  /** Available genre filter options */
  genres: FilterOptions;
  /** Available product filter options */
  products: FilterOptions;
};


export type SearchFilterOptionsBrandsArgs = {
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type SearchFilterOptionsContentKitsArgs = {
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type SearchFilterOptionsGenresArgs = {
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  textPhrase?: InputMaybe<Scalars['String']>;
};


export type SearchFilterOptionsProductsArgs = {
  page?: Scalars['Int'];
  pageSize?: Scalars['Int'];
  textPhrase?: InputMaybe<Scalars['String']>;
};

export type SearchFilterOptionsInput = {
  contentKitId?: InputMaybe<Scalars['UUID']>;
  productId?: InputMaybe<Scalars['UUID']>;
  selectedAdvancedProductFilters?: InputMaybe<SelectedAdvancedFiltersInput>;
  /** Preview status filter to narrow down filter options by type such as downloadable (completed order and ready to download), in-progress (ordered but not completed), etc. */
  statusFilter: SearchStatusFilterFlagsInput;
  textPhrase?: InputMaybe<Scalars['String']>;
};

export enum SearchResultLayout {
  /** Every row is focused on a content kit, with every column being different products */
  ContentKit = 'CONTENT_KIT',
  /** Every row is focused on a single product, with every column being a new output combination with a template */
  Product = 'PRODUCT'
}

export type SearchResultSummary = {
  __typename?: 'SearchResultSummary';
  /** Content-Kit count that are in relation with the output-combinations */
  contentKitCount: Scalars['Int'];
  /** Output-Combination count */
  previewCount: Scalars['Long'];
  /** Product count that are in relation with the output-combinations */
  productCount: Scalars['Int'];
};

export type SearchStatusFilterFlags = {
  __typename?: 'SearchStatusFilterFlags';
  isAll: Scalars['Boolean'];
  isDownloadable: Scalars['Boolean'];
  isInProgress: Scalars['Boolean'];
  isPreview: Scalars['Boolean'];
  isSaved: Scalars['Boolean'];
};

export type SearchStatusFilterFlagsInput = {
  isAll?: InputMaybe<Scalars['Boolean']>;
  isDownloadable?: InputMaybe<Scalars['Boolean']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  isPreview?: InputMaybe<Scalars['Boolean']>;
  isSaved?: InputMaybe<Scalars['Boolean']>;
};

export type SelectedAdvancedFiltersInput = {
  metadataFilters: Array<AdvancedFilterSelectionInput>;
  productGroupFilters: Array<AdvancedFilterSelectionInput>;
  shapeCollectionFilters: Array<AdvancedFilterSelectionInput>;
};

export enum Severity {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type ShapeCollectionCompatibilityInput = {
  dimensionFilter?: InputMaybe<DimensionFilterInput>;
  measurementFilter?: InputMaybe<BaseMeasurementFilterInput>;
  shapeCollectionIds?: InputMaybe<Array<Scalars['UUID']>>;
  shapeCollectionTypes?: InputMaybe<Array<ShapeCollectionType>>;
  shapeCollectionTypesOperator?: InputMaybe<OperatorHelper>;
  shapeTypesOperator?: InputMaybe<OperatorHelper>;
  shapes?: InputMaybe<Array<Scalars['String']>>;
};

export type ShapeCollectionCompatibilityOptions = {
  __typename?: 'ShapeCollectionCompatibilityOptions';
  baseMeasurements: Array<BaseMeasurement>;
  scenes: Array<SceneInfoModel>;
  shapeCollectionTypes: Array<ShapeCollectionType>;
  shapeTypes: Array<Scalars['String']>;
};

/** Shape collection filter */
export type ShapeCollectionFilter = {
  __typename?: 'ShapeCollectionFilter';
  dimensionFilter?: Maybe<DimensionFilter>;
  measurementFilter?: Maybe<BaseMeasurementFilter>;
  shapeCollectionIds?: Maybe<Array<Scalars['UUID']>>;
  shapeCollectionTypes?: Maybe<Array<ShapeCollectionType>>;
  shapeCollectionTypesOperator: OperatorHelper;
  shapeTypesOperator: OperatorHelper;
  shapes?: Maybe<Array<Scalars['String']>>;
};

export type ShapeCollectionFilterConnection = {
  __typename?: 'ShapeCollectionFilterConnection';
  edges: Array<ShapeCollectionFilterOptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long'];
};

export type ShapeCollectionFilterOption = {
  __typename?: 'ShapeCollectionFilterOption';
  /** ID of the filter item */
  id: Scalars['String'];
  name: Scalars['String'];
  productCount: Scalars['Long'];
  values: ShapeFilterConnection;
};


export type ShapeCollectionFilterOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  selectedAdvancedFilters?: InputMaybe<SelectedAdvancedFiltersInput>;
  textPhrase?: InputMaybe<Scalars['String']>;
};

export type ShapeCollectionFilterOptionEdge = {
  __typename?: 'ShapeCollectionFilterOptionEdge';
  cursor: Scalars['String'];
  node: ShapeCollectionFilterOption;
};

export type ShapeCollectionImageSceneModel = ShapeCollectionSceneModel & {
  __typename?: 'ShapeCollectionImageSceneModel';
  /** Hdri File URL */
  hdriFileUrl?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** Json file content */
  json: Scalars['String'];
  jsonFileUrl: Scalars['String'];
  /** Lut File URL */
  lutFileUrl?: Maybe<Scalars['String']>;
  /** Prerendered Image File URL */
  prerenderedImageFileUrl: Scalars['String'];
  /** Scene */
  scene: SceneInfoModel;
  shapeCollectionId: Scalars['UUID'];
  shapeViewerData?: Maybe<ShapeViewerDataModel>;
  /** Uv File URL */
  uvFileUrl: Scalars['String'];
};

/** ShapeCollection input type to create and update a shapeCollection */
export type ShapeCollectionInput = {
  /** Artwork mapping details */
  artworkMapping: ArtworkMappingInput;
  /** 3D dimensions of a shape in mm */
  dimensions: DimensionsInput;
  /** Linked shapeCollection such as a shrink wrapped version of a single pack */
  linkedCollection?: InputMaybe<Scalars['UUID']>;
  /** Name of the shapeCollection. Can not be null or empty. */
  name: Scalars['String'];
  /** Shape Id's referencing Grip shapes */
  shapes: Array<ShapeInput>;
  /** ShapeCollection semantic label. SINGLE | MULTI | BUNDLE */
  type: ShapeCollectionType;
  /** Measurement of ShapeCollection content */
  unitSize: UnitSizeInput;
};

export type ShapeCollectionListFilterInput = {
  /** Text to search for (Name, Id, Types) */
  searchTerm?: InputMaybe<Scalars['String']>;
  /** List of ShapeCollectionTypes to filter by */
  shapeCollectionTypes?: InputMaybe<Array<ShapeCollectionType>>;
};

export type ShapeCollectionListModel = {
  __typename?: 'ShapeCollectionListModel';
  filteredTotal: Scalars['Int'];
  shapeCollections: Array<ShapeCollectionModel>;
};


export type ShapeCollectionListModelShapeCollectionsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum ShapeCollectionListSortBy {
  Id = 'ID',
  Name = 'NAME',
  Type = 'TYPE'
}

export type ShapeCollectionListSortInput = {
  /** Contains which column to use in sorting */
  sortBy: ShapeCollectionListSortBy;
  /** Contains which order to use in sorting */
  sortDirection: SortDirection;
};

/** Object/Type that represents a ShapeCollection */
export type ShapeCollectionModel = {
  __typename?: 'ShapeCollectionModel';
  /** Artwork mapping details */
  artworkMapping: ArtworkMapping;
  /** 3D dimensions of a shape in mm */
  dimensions: Dimensions;
  /** Unique Internal ShapeCollection Id */
  id: Scalars['UUID'];
  linkedShapeCollection?: Maybe<ShapeCollectionModel>;
  /** Name of the shapeCollection. Can not be null or empty. */
  name: Scalars['String'];
  /** Shape Collection Scenes */
  shapeCollectionScenes: Array<CompleteShapeCollectionSceneModel>;
  shapes: Array<ShapeModel>;
  /** ShapeCollection semantic label. SINGLE | MULTI | BUNDLE */
  type: ShapeCollectionType;
  /** Measurement of ShapeCollection content */
  unitSize: UnitSize;
};

/** Scene */
export type ShapeCollectionSceneModel = {
  /** Scene Id */
  id: Scalars['UUID'];
  /** Json File URL */
  jsonFileUrl: Scalars['String'];
  scene: SceneInfoModel;
  /** Shape Collection Id */
  shapeCollectionId: Scalars['UUID'];
};

/** ShapeCollection semantic label */
export enum ShapeCollectionType {
  Bundle = 'BUNDLE',
  Multi = 'MULTI',
  Single = 'SINGLE'
}

export type ShapeCollectionUvRemapSceneModel = ShapeCollectionSceneModel & {
  __typename?: 'ShapeCollectionUVRemapSceneModel';
  id: Scalars['UUID'];
  jsonFileUrl: Scalars['String'];
  /** Lut File URL */
  lutFileUrl?: Maybe<Scalars['String']>;
  /** Scene */
  scene: SceneInfoModel;
  shapeCollectionId: Scalars['UUID'];
  videoUvFileUrl: Scalars['String'];
};

export type ShapeCollectionVideoSceneModel = ShapeCollectionSceneModel & {
  __typename?: 'ShapeCollectionVideoSceneModel';
  id: Scalars['UUID'];
  /** Json file content */
  json: Scalars['String'];
  jsonFileUrl: Scalars['String'];
  /** Lut File URL */
  lutFileUrl?: Maybe<Scalars['String']>;
  prerenderedVideoFileUrl: Scalars['String'];
  /** Scene */
  scene: SceneInfoModel;
  shapeCollectionId: Scalars['UUID'];
  shapeViewerData?: Maybe<ShapeViewerDataModel>;
  videoUvFileUrl: Scalars['String'];
};

export type ShapeFilterConnection = {
  __typename?: 'ShapeFilterConnection';
  edges: Array<ShapeFilterOptionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Long'];
};

export type ShapeFilterOption = {
  __typename?: 'ShapeFilterOption';
  /** ID of the filter item */
  id: Scalars['String'];
  name: Scalars['String'];
  /** Product hit count for this filter */
  productCount: Scalars['Long'];
};

export type ShapeFilterOptionEdge = {
  __typename?: 'ShapeFilterOptionEdge';
  cursor: Scalars['String'];
  node: ShapeFilterOption;
};

export type ShapeInput = {
  /** Grip Id of selected shape */
  id: Scalars['UUID'];
  /** Shape semantic label. DEFAULT, WET, OPEN, etc. */
  type: ShapeType;
};

/** Object/Type that represents an Shape */
export type ShapeModel = {
  __typename?: 'ShapeModel';
  /**
   * Internal ShapeId
   *
   * The Id must correspond with an Shape Id on Grip
   */
  id: Scalars['UUID'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** Name of the Shape. Can not be null or empty. */
  name: Scalars['String'];
  /**
   * Internal FileSystem Id for the actual shape
   *
   * The NasId is mainly used by render farm
   */
  nasId: Scalars['String'];
  thumbnail: Scalars['String'];
  /** Shape semantic label. DEFAULT, WET, OPEN, etc. */
  type: ShapeType;
  /** Information indicating by whom/what the shape was validated */
  validatedBy: Validator;
  /** Viewer specific shape data as a field resolver only available on graphQl */
  viewerData: ShapeViewerDataModel;
};

/** Shape semantic label */
export enum ShapeType {
  Default = 'DEFAULT',
  Empty = 'EMPTY',
  InnerOutOfPack = 'INNER_OUT_OF_PACK',
  Open = 'OPEN',
  Wet = 'WET'
}

/** Object/Type that contains viewer data for a Shape */
export type ShapeViewerDataModel = {
  __typename?: 'ShapeViewerDataModel';
  /** Shape image sequence, array of absolute urls for sequence images */
  imageSequence?: Maybe<Array<Scalars['String']>>;
  /** Json configuration for a shape */
  json: Scalars['String'];
  /** Lut file signed absolute URL */
  lut?: Maybe<Scalars['String']>;
  /** Mp4 file signed absolute URL */
  mp4: Scalars['String'];
  /** A collection of signed absolute URLs for uv mappings */
  uvMappings: Array<Scalars['String']>;
  /** WebM file signed absolute URL */
  webM: Scalars['String'];
};

/** Object/Type that represents a lightweight version of ArtworkSet */
export type SimplifiedArtworkSetModel = {
  __typename?: 'SimplifiedArtworkSetModel';
  /** Artworks. */
  artworks: Array<ArtworkModel>;
  /** Unique Internal ArtworkSet Id */
  id: Scalars['UUID'];
  locale: Array<Scalars['String']>;
  /** Name of the ArtworkSet. Can not be null or empty. */
  name: Scalars['String'];
  /** ArtworkSet semantic label. DEFAULT | CLEAN | OPTIMIZED */
  type: ArtworkType;
};

export type SimplifiedProductModel = {
  __typename?: 'SimplifiedProductModel';
  /** Associated additional images */
  additionalImages: Array<AdditionalImageModel>;
  /** Array of associated artworkSets */
  artworkSets: Array<SimplifiedArtworkSetModel>;
  brand: BrandModel;
  /** Globally unique identifier of a product (trade item) */
  globalId?: Maybe<ProductGlobalId>;
  id: Scalars['UUID'];
  /** Name of the product. Can not be null or empty. */
  name: Scalars['String'];
  /** Associated product groups */
  productGroups: Array<ProductGroupModel>;
  /** Associated shapeCollection */
  shapeCollection: SimplifiedShapeCollectionModel;
  /**
   * Product variant
   *
   * Flavor in case of a drink, "Cherry"
   * Scent in case of a perfume, such as "Fresh"
   */
  variant: Scalars['String'];
};

export type SimplifiedShapeCollectionModel = {
  __typename?: 'SimplifiedShapeCollectionModel';
  /** 3D dimensions of a shape in mm */
  dimensions: Dimensions;
  /** Unique Internal ShapeCollection Id */
  id: Scalars['UUID'];
  linkedShapeCollection?: Maybe<SimplifiedShapeCollectionModel>;
  /** Name of the shapeCollection. Can not be null or empty. */
  name: Scalars['String'];
  /** List of scenes */
  shapeCollectionScenes: Array<CompleteShapeCollectionSceneModel>;
  /** List of shapes */
  shapes: Array<ShapeModel>;
  /** ShapeCollection semantic label. SINGLE | MULTI | BUNDLE */
  type: ShapeCollectionType;
  /** Measurement of ShapeCollection content */
  unitSize: UnitSize;
};

export enum SortBy {
  Brand = 'BRAND',
  CreatedAt = 'CREATED_AT',
  Genre = 'GENRE',
  Gtin = 'GTIN',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
  OrderedAt = 'ORDERED_AT'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StashItemModelOfEditorDetailsModel = {
  __typename?: 'StashItemModelOfEditorDetailsModel';
  key: Scalars['String'];
  stashData: EditorDetailsModel;
  tenantId: Scalars['UUID'];
};

export type SubscriptionBudgetInput = {
  /** Single credit value equivalent in Euros */
  creditValue: Scalars['Decimal'];
  /** Subscription duration in months */
  duration: Scalars['Int'];
  /** Budget name */
  name: Scalars['String'];
  /** Budget owner UserId */
  ownerId: Scalars['UUID'];
  /** PrePaid | PostPaid */
  paymentType: BudgetPaymentType;
  /** Optional SalesForce Reference */
  salesForceReference?: InputMaybe<SalesForceInput>;
  /** Monthly credit reset amount */
  subscriptionCreditAmount: Scalars['Int'];
};

export type SuggestionModel = {
  /** Globally unique identifier of an entity */
  id: Scalars['UUID'];
  /** Name of the entity */
  name: Scalars['String'];
  /** Type of an entity */
  type: SearchEntityType;
};

export type TemplateAiTextSuggestion = {
  __typename?: 'TemplateAITextSuggestion';
  /** Editable Prompt text part to send request to ChatGPT */
  editablePromptPart: Scalars['String'];
  /** Flag to enable or disable AI Text suggestion */
  isTextAiSuggestionEnabled: Scalars['Boolean'];
};

export type TemplateAiTextSuggestionInput = {
  /** Editable Prompt text part to send request to ChatGPT */
  editablePromptPart: Scalars['String'];
  /** Flag to enable or disable AI Text suggestion */
  isTextAiSuggestionEnabled: Scalars['Boolean'];
};

/** Click Drag Interaction */
export type TemplateClickDragInteraction = {
  __typename?: 'TemplateClickDragInteraction';
  /** Loop */
  loop: Scalars['Boolean'];
  /** Type */
  type: TemplateClickDragInteractionType;
};

/** Click Drag Interaction Type */
export enum TemplateClickDragInteractionType {
  /** Horizontal */
  Horizontal = 'HORIZONTAL',
  /** Vertical */
  Vertical = 'VERTICAL'
}

export type TemplateFileResource = {
  __typename?: 'TemplateFileResource';
  resourceRef: Scalars['String'];
};

export type TemplateFiltersInput = {
  /** End date of the creation search */
  dataCreationEnd?: InputMaybe<Scalars['DateTime']>;
  /** Start date of the creation search */
  dataCreationStart?: InputMaybe<Scalars['DateTime']>;
  /** Text to search for (TemplateName, TemplateId, ContentKitName) */
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Template input */
export type TemplateInput = {
  /** AI text suggestion configurations */
  aiTextSuggestion: TemplateAiTextSuggestionInput;
  /** Node Graph Json. https://grip-mlp-dev-idunn-app.azurewebsites.net/redoc.html#tag/Graph-CRUD/paths/~1api~1v1~1graphs~1/post */
  graph?: InputMaybe<Scalars['Any']>;
  /** Flag to enable or disable AI prediction */
  isPerformanceCoachEnabled?: Scalars['Boolean'];
  /** Template Name */
  name: Scalars['String'];
  /** Text Translation configurations */
  textTranslation: TemplateTextTranslationInput;
};

/** Type that represents a Template Input */
export type TemplateInputModel = {
  __typename?: 'TemplateInputModel';
  /** Template input category */
  category: Scalars['String'];
  clickDragInteraction?: Maybe<TemplateClickDragInteraction>;
  colorPalette?: Maybe<Array<Scalars['String']>>;
  /** Product compatibility object. Available when Input.Type is Product */
  compatibility?: Maybe<ProductFilter>;
  customValueOption?: Maybe<TemplateInputOptions>;
  /** Default value. Available when Input.Type is not 'Product' */
  default?: Maybe<Scalars['String']>;
  maxFontSize?: Maybe<Scalars['Int']>;
  /** Max Length for text inputs. Available when type is 'String' */
  maxLength?: Maybe<Scalars['Int']>;
  minFontSize?: Maybe<Scalars['Int']>;
  /** Template input name */
  name: Scalars['String'];
  /** Template input Node Id */
  nodeId: Scalars['String'];
  /** Input Options. Available when Input.Type is 'Image Selection' or 'Color Selection' */
  options?: Maybe<Array<TemplateInputOptions>>;
  /** Input order */
  order: Scalars['Int'];
  /** Range. Available when Input.Type is 'Number Range' */
  range?: Maybe<TemplateInputRange>;
  /** Template input type */
  type: TemplateInputType;
  /** Values. Available when Input.Type are 'Predefined Strings' or 'Predefined Numbers' */
  values?: Maybe<Array<Scalars['String']>>;
};

export type TemplateInputOptions = {
  __typename?: 'TemplateInputOptions';
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<TemplateFileResource>;
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<TemplateFileResource>;
  value?: Maybe<Scalars['String']>;
};

export type TemplateInputRange = {
  __typename?: 'TemplateInputRange';
  max: Scalars['Float'];
  min: Scalars['Float'];
  step: Scalars['Float'];
};

/** Template input type */
export enum TemplateInputType {
  /** Color */
  Color = 'COLOR',
  /** Color Selection */
  ColorSelection = 'COLOR_SELECTION',
  /** Image Selection */
  ImageSelection = 'IMAGE_SELECTION',
  /** Number */
  Number = 'NUMBER',
  /** Number Range */
  NumberWithinRange = 'NUMBER_WITHIN_RANGE',
  /** Product */
  Product = 'PRODUCT',
  /** Rich Text */
  RichText = 'RICH_TEXT',
  /** String */
  String = 'STRING',
  /** String Selection */
  StringSelection = 'STRING_SELECTION',
  /** String Selection with a custom value */
  TextDropdownSelection = 'TEXT_DROPDOWN_SELECTION'
}

export type TemplateListModel = {
  __typename?: 'TemplateListModel';
  /** Total number of filtered templates */
  filteredTotal: Scalars['Int'];
  /** List of templates */
  templates: Array<TemplateModel>;
};


export type TemplateListModelTemplatesArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TemplateMetadata = {
  __typename?: 'TemplateMetadata';
  /** Main product input name */
  mainProductInputName: Scalars['String'];
  /** Default output variant name */
  name: Scalars['String'];
  /** Template output variants */
  outputs: Array<TemplateOutput>;
};

/** Object/Type that represents a Template */
export type TemplateModel = {
  __typename?: 'TemplateModel';
  /** AI text suggestion configurations */
  aiTextSuggestion: TemplateAiTextSuggestion;
  /** Unique Internal Template Id */
  id: Scalars['UUID'];
  /** Flag to enable or disable AI prediction */
  isPerformanceCoachEnabled: Scalars['Boolean'];
  linkedContentKits: Array<InfoModel>;
  /** Template metadata */
  metadata: TemplateMetadata;
  /** Date Last Modify at */
  modifiedAt: Scalars['DateTime'];
  /** Template name */
  name: Scalars['String'];
  /** Get the node graph Json string */
  nodeGraph: Scalars['String'];
  previewStats: PreviewStatsModel;
  previews: Array<PreviewModel>;
  rootTemplate?: Maybe<TemplateModel>;
  templateStatus: TemplateStatus;
  /** Text Translation configurations */
  textTranslation: TemplateTextTranslation;
  thumbnailUrl: Scalars['String'];
  /** Version of the template */
  version: Scalars['Int'];
};

/** Object/Type that represents a Template Output */
export type TemplateOutput = {
  __typename?: 'TemplateOutput';
  /** Output code name */
  code?: Maybe<Scalars['String']>;
  /** Credit cost of running this output node */
  cost: Scalars['Int'];
  /** Default full output type */
  fullType: TemplateOutputType;
  /** Template Output height */
  height: Scalars['Int'];
  /** Template Inputs */
  inputs: Array<TemplateInputModel>;
  /** Template Output Name */
  name: Scalars['String'];
  /** Template Output NodeId */
  nodeId: Scalars['String'];
  /** Default preview output type */
  previewType: TemplateOutputType;
  segments?: Maybe<Array<TemplateSegment>>;
  /** Should Auto Apply User Changes on the viewer */
  shouldAutoApplyUserChanges: Scalars['Boolean'];
  skipFrames?: Maybe<Scalars['Int']>;
  /**
   * Output type
   * @deprecated Please use Types, FullType and PreviewType instead
   */
  type: TemplateOutputType;
  /** Supported output types */
  types: Array<TemplateOutputType>;
  videoLength?: Maybe<Scalars['Float']>;
  /** Template Output width */
  width: Scalars['Int'];
};

/** Template output type */
export enum TemplateOutputType {
  /** Image output */
  Image = 'IMAGE',
  ImageWithClippingPath = 'IMAGE_WITH_CLIPPING_PATH',
  /** PSD output */
  Psd = 'PSD',
  /** URL output */
  Url = 'URL',
  /** Video output */
  Video = 'VIDEO'
}

export type TemplatePreviewsFiltersInput = {
  /** Id to search by Product */
  productId?: InputMaybe<Scalars['UUID']>;
  /** Text to search for (TemplateName, TemplateId or PreviewId) */
  searchTerm?: InputMaybe<Scalars['String']>;
  /** Status to search */
  status?: InputMaybe<PreviewStatus>;
  /** Id to search by Template */
  templateId: Scalars['UUID'];
};

export type TemplateSegment = {
  __typename?: 'TemplateSegment';
  inputs?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  preview?: Maybe<TemplateSegmentPreview>;
  videoLength: Scalars['Float'];
};

export type TemplateSegmentPreview = {
  __typename?: 'TemplateSegmentPreview';
  resourceRef: Scalars['String'];
};

export enum TemplateSortBy {
  ModifiedAt = 'MODIFIED_AT',
  TemplateId = 'TEMPLATE_ID',
  TemplateName = 'TEMPLATE_NAME',
  TemplateStatus = 'TEMPLATE_STATUS'
}

export type TemplateSortInput = {
  /** Contains which column to use in sorting */
  sortBy: TemplateSortBy;
  /** Contains which order to use in sorting */
  sortDirection: SortDirection;
};

export enum TemplateStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type TemplateTextTranslation = {
  __typename?: 'TemplateTextTranslation';
  /** Flag to enable or disable AI Text translation */
  isTextTranslationEnabled: Scalars['Boolean'];
  /** List of enabled languages */
  languageList: Array<Scalars['String']>;
};

export type TemplateTextTranslationInput = {
  /** Flag to enable or disable AI Text translation */
  isTextTranslationEnabled: Scalars['Boolean'];
  /** List of enabled languages */
  languageList: Array<Scalars['String']>;
};

/** Object/type that represents a Tenant */
export type TenantModel = {
  __typename?: 'TenantModel';
  /**
   * Tenant domain associated to its Users to resolve the Identity Provider. Unique.
   *
   * It's not required to create a Tenant, but it is required to discover the UserJourney for the Tenant
   */
  domain?: Maybe<Scalars['String']>;
  /** Domains associated with SSO user flows. */
  domains: Array<Scalars['String']>;
  /** Unique internal Tenant Id */
  id: Scalars['UUID'];
  /** Logo URL */
  logoUrl?: Maybe<Scalars['String']>;
  /** Name of the Tenant. Can not be null or empty */
  name: Scalars['String'];
  noMatchesImageUrl: Scalars['String'];
  projectManagers: Array<InfoModel>;
  /** Name of the User Journey for Tenant's users. Should be filled after tenant configuration on Azure B2C */
  userJourney?: Maybe<Scalars['String']>;
};

export type TransactionFiltersInput = {
  /** BudgetId filter */
  budgetIds?: InputMaybe<Array<Scalars['UUID']>>;
  /** Budget type filter */
  budgetTypeFilter?: InputMaybe<BudgetTypeFilterFlagsInput>;
  /** User filter */
  userIds?: InputMaybe<Array<Scalars['UUID']>>;
};

export type TransactionModel = {
  __typename?: 'TransactionModel';
  budget: BudgetModel;
  createdAt: Scalars['DateTime'];
  credits: Scalars['Int'];
  description: Scalars['String'];
  orderItem?: Maybe<OrderItemModel>;
  tenant: TenantModel;
  transactionType: TransactionType;
  user: UserModel;
};

export type TransactionReportModel = {
  __typename?: 'TransactionReportModel';
  /** Total number of budgets */
  totalNumber: Scalars['Int'];
  /** List of transactions */
  transactions: Array<TransactionModel>;
};


export type TransactionReportModelTransactionsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export enum TransactionReportSortType {
  ByBudgetName = 'BY_BUDGET_NAME',
  LatestFirst = 'LATEST_FIRST'
}

export enum TransactionType {
  Added = 'ADDED',
  AutoTopUp = 'AUTO_TOP_UP',
  Created = 'CREATED',
  Deducted = 'DEDUCTED',
  Used = 'USED'
}

/** Object/Type that contains size data for a shapeCollection */
export type UnitSize = {
  __typename?: 'UnitSize';
  /** Value of the measurement */
  content: Scalars['Int'];
  /** Base measurement for an object such as net-content, weight, unit-count, etc. */
  measurement: BaseMeasurement;
  /** Number of items if the uniteType is defined in pieces */
  quantity: Scalars['Int'];
  /** Unit type for measurement */
  unitType: UnitType;
};

/** Object/Type that contains size data for a shapeCollection */
export type UnitSizeInput = {
  /** Value of the measurement */
  content: Scalars['Int'];
  /** Base measurement for an object such as net-content, weight, unit-count, etc. */
  measurement: BaseMeasurement;
  /** Number of items if the uniteType is defined in pieces */
  quantity: Scalars['Int'];
  /** Unit type for measurement */
  unitType: UnitType;
};

/** Unit type for measurements */
export enum UnitType {
  Centigrams = 'CENTIGRAMS',
  Centiliter = 'CENTILITER',
  Centimeters = 'CENTIMETERS',
  FluidOunce = 'FLUID_OUNCE',
  Grams = 'GRAMS',
  Kilograms = 'KILOGRAMS',
  Liter = 'LITER',
  Milligrams = 'MILLIGRAMS',
  Milliliter = 'MILLILITER',
  Pieces = 'PIECES'
}

/** Brand input type to create and update a brand */
export type UpdateBrandInput = {
  /** Brand color in RGB hex */
  color: Scalars['String'];
  /** Brand logo image, ignored from update if not provided */
  logo?: InputMaybe<Scalars['Upload']>;
  /** Name of the brand */
  name: Scalars['String'];
};

export type UpdateBudgetPremiumOrderingInput = {
  budgetId: Scalars['UUID'];
  isEnabled: Scalars['Boolean'];
  multiplier: Scalars['Int'];
};

/** Genre input type to create and update genres */
export type UpdateGenreInput = {
  /** Genre color in RGB hex */
  color: Scalars['String'];
  /** Genre logo image, ignored from update if not provided */
  logo?: InputMaybe<Scalars['Upload']>;
  /** Genre name */
  name: Scalars['String'];
};

/** Input to update Image scene. All file or optional file will be ignored from update if not provided */
export type UpdateShapeCollectionImageSceneInput = {
  /** Hdri file */
  hdriFile?: InputMaybe<OptionalFileInput>;
  jsonFile?: InputMaybe<Scalars['Upload']>;
  /** Lut file */
  lutFile?: InputMaybe<OptionalFileInput>;
  /** Prerendered image file */
  prerenderedImageFile?: InputMaybe<Scalars['Upload']>;
  shapeCollectionSceneId: Scalars['UUID'];
  /** Uv mapping file */
  uvFile?: InputMaybe<Scalars['Upload']>;
};

/** Input to update Video scene. All file or optional file will be ignored from update if not provided */
export type UpdateShapeCollectionUvRemapSceneInput = {
  /** Hdri file */
  hdriFile?: InputMaybe<OptionalFileInput>;
  jsonFile?: InputMaybe<Scalars['Upload']>;
  /** Lut file */
  lutFile?: InputMaybe<OptionalFileInput>;
  shapeCollectionSceneId: Scalars['UUID'];
  /** Video Scene UV file */
  videoUvFile?: InputMaybe<Scalars['Upload']>;
};

/** Input to update Video scene. All file or optional file will be ignored from update if not provided */
export type UpdateShapeCollectionVideoSceneInput = {
  /** Hdri file */
  hdriFile?: InputMaybe<OptionalFileInput>;
  jsonFile?: InputMaybe<Scalars['Upload']>;
  /** Lut file */
  lutFile?: InputMaybe<OptionalFileInput>;
  /** Prerendered Scene Video */
  prerenderedVideoFile?: InputMaybe<Scalars['Upload']>;
  shapeCollectionSceneId: Scalars['UUID'];
  /** Video Scene UV file */
  videoUvFile?: InputMaybe<Scalars['Upload']>;
};

/** Input type to Update a Tenant */
export type UpdateTenantInput = {
  /** Tenant IdP domain. Must be unique when not null. */
  domain?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<OptionalFileInput>;
  /** Tenant name. Can not be null or empty. */
  name: Scalars['String'];
  /** Image used to present No MachesImage */
  noMatchesImage?: InputMaybe<Scalars['Upload']>;
  /** Tenant authorization journey name on Azure B2C */
  userJourney?: InputMaybe<Scalars['String']>;
};

/** User Provisioning Table Update Name Input */
export type UpdateUserProvisioningTableInput = {
  /** A csv file that contains user provisioning information regarding budget and productGroups */
  id: Scalars['UUID'];
  /** Name of the uploaded user provisioning table */
  name: Scalars['String'];
};

/** User journey for authentication */
export type UserJourneyModel = {
  __typename?: 'UserJourneyModel';
  /** Name of the journey in the AD B2C */
  userJourney: Scalars['String'];
  /** Link to authorize the user and retrieve the token */
  userJourneyUrl: Scalars['String'];
};

/** User */
export type UserModel = {
  __typename?: 'UserModel';
  /** User email */
  email: Scalars['String'];
  /** User Id */
  id: Scalars['UUID'];
  /** If a user logged in to the system before this flag will return true */
  loggedInBefore: Scalars['Boolean'];
  /** User name */
  name: Scalars['String'];
  /** User role for its own tenant */
  roles: Array<UserRoleEnum>;
};

export type UserOrderSuggestion = OrderSuggestionModel & {
  __typename?: 'UserOrderSuggestion';
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: OrderSearchEntityType;
};

export type UserProfileModel = {
  __typename?: 'UserProfileModel';
  /** Returns true if logged in user has read access to any product */
  hasProductAccess: Scalars['Boolean'];
  /** List of recently used content-kits ids */
  recentlyUsedContentKits?: Maybe<Array<Scalars['UUID']>>;
  /** List of recently used product ids */
  recentlyUsedProducts?: Maybe<Array<Scalars['UUID']>>;
  /** Returns true if welcome message has been shown to logged in user, otherwise false */
  welcomeMessageShown: Scalars['Boolean'];
};

/** Object/Type that represents a provisioning table item */
export type UserProvisioningTableItemModel = {
  __typename?: 'UserProvisioningTableItemModel';
  budgets: Array<BudgetModel>;
  email: Scalars['String'];
  processed: Scalars['Boolean'];
  processedAt?: Maybe<Scalars['DateTime']>;
  productGroups: Array<ProductGroupModel>;
  /** Matched user */
  user?: Maybe<UserModel>;
};

/** Object/Type that represents a provisioning table */
export type UserProvisioningTableModel = {
  __typename?: 'UserProvisioningTableModel';
  createdAt: Scalars['DateTime'];
  createdBy: UserModel;
  id: Scalars['UUID'];
  items: Array<UserProvisioningTableItemModel>;
  lastModifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type UserProvisioningTableResponse = {
  succeeded: Scalars['Boolean'];
};

export enum UserRoleEnum {
  BudgetOwner = 'BUDGET_OWNER',
  DefaultUser = 'DEFAULT_USER',
  DraftProductManager = 'DRAFT_PRODUCT_MANAGER',
  IndgAdmin = 'INDG_ADMIN',
  IndgDefaultUser = 'INDG_DEFAULT_USER',
  IndgPm = 'INDG_PM',
  ProductGroupAdmin = 'PRODUCT_GROUP_ADMIN',
  ProductManager = 'PRODUCT_MANAGER',
  Revoked = 'REVOKED'
}

export type UserWithBudgetsModel = {
  __typename?: 'UserWithBudgetsModel';
  /** Budgets that the user has explicit access to (PMs and Admins have access to all) */
  assignedBudgets: Array<InfoModel>;
  /** User email */
  email: Scalars['String'];
  /** User Id */
  id: Scalars['UUID'];
  /** If a user logged in to the system before this flag will return true */
  loggedInBefore: Scalars['Boolean'];
  /** User name */
  name: Scalars['String'];
  /** Budgets that the user own */
  ownedBudgets: Array<InfoModel>;
  /** List of product groups that the user has access to */
  productGroups: Array<InfoModel>;
  /** User role for its own tenant */
  roles: Array<UserRoleEnum>;
};

export type ValidationErrorModel = {
  __typename?: 'ValidationErrorModel';
  /** Gets or sets the error code. */
  errorCode?: Maybe<Scalars['String']>;
  /** The error message */
  errorMessage: Scalars['String'];
  /** The name of the property. */
  propertyName: Scalars['String'];
};

export type ValidationFailure = {
  __typename?: 'ValidationFailure';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  formattedMessagePlaceholderValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  propertyName?: Maybe<Scalars['String']>;
  severity: Severity;
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  errors?: Maybe<Array<Maybe<ValidationFailure>>>;
  isValid: Scalars['Boolean'];
  ruleSetsExecuted?: Maybe<Array<Maybe<Scalars['String']>>>;
  toDictionary?: Maybe<Array<KeyValuePairOfStringAndString__>>;
};

export type ValidationResultModel = {
  __typename?: 'ValidationResultModel';
  errors: Array<ValidationErrorModel>;
  isValid: Scalars['Boolean'];
};

export type Validator = {
  __typename?: 'Validator';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: ValidatorType;
  userId: Scalars['UUID'];
};

export type ValidatorInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: ValidatorType;
  userId: Scalars['UUID'];
};

export enum ValidatorType {
  Agency = 'AGENCY',
  Client = 'CLIENT',
  Grip = 'GRIP',
  Software = 'SOFTWARE'
}

export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  AddUserProvisioningTableFailed?: (data: WithTypename<AddUserProvisioningTableFailed>) => null | string,
  AddUserProvisioningTableSucceeded?: (data: WithTypename<AddUserProvisioningTableSucceeded>) => null | string,
  AdditionalImageModel?: (data: WithTypename<AdditionalImageModel>) => null | string,
  AdditionalImageTypeModel?: (data: WithTypename<AdditionalImageTypeModel>) => null | string,
  AdvancedFiltersSettings?: (data: WithTypename<AdvancedFiltersSettings>) => null | string,
  AdvancedProductFilterOptions?: (data: WithTypename<AdvancedProductFilterOptions>) => null | string,
  ArtworkMapping?: (data: WithTypename<ArtworkMapping>) => null | string,
  ArtworkMappingInputObject?: (data: WithTypename<ArtworkMappingInputObject>) => null | string,
  ArtworkMappingOption?: (data: WithTypename<ArtworkMappingOption>) => null | string,
  ArtworkModel?: (data: WithTypename<ArtworkModel>) => null | string,
  ArtworkSetCompatibilityOptions?: (data: WithTypename<ArtworkSetCompatibilityOptions>) => null | string,
  ArtworkSetFilter?: (data: WithTypename<ArtworkSetFilter>) => null | string,
  ArtworkSetListModel?: (data: WithTypename<ArtworkSetListModel>) => null | string,
  ArtworkSetModel?: (data: WithTypename<ArtworkSetModel>) => null | string,
  BaseMeasurementFilter?: (data: WithTypename<BaseMeasurementFilter>) => null | string,
  BasketGroupModel?: (data: WithTypename<BasketGroupModel>) => null | string,
  BasketItemModel?: (data: WithTypename<BasketItemModel>) => null | string,
  BasketItemStatusFlags?: (data: WithTypename<BasketItemStatusFlags>) => null | string,
  BasketModel?: (data: WithTypename<BasketModel>) => null | string,
  BrandModel?: (data: WithTypename<BrandModel>) => null | string,
  BudgetListModel?: (data: WithTypename<BudgetListModel>) => null | string,
  BudgetModel?: (data: WithTypename<BudgetModel>) => null | string,
  BudgetOrderSuggestion?: (data: WithTypename<BudgetOrderSuggestion>) => null | string,
  CompatibleProductSearchResult?: (data: WithTypename<CompatibleProductSearchResult>) => null | string,
  CompleteShapeCollectionSceneModel?: (data: WithTypename<CompleteShapeCollectionSceneModel>) => null | string,
  ConfigurationModel?: (data: WithTypename<ConfigurationModel>) => null | string,
  ContentKitModel?: (data: WithTypename<ContentKitModel>) => null | string,
  ContentKitOutputCombinationGroupModel?: (data: WithTypename<ContentKitOutputCombinationGroupModel>) => null | string,
  ContentKitSearchFilters?: (data: WithTypename<ContentKitSearchFilters>) => null | string,
  ContentKitSuggestion?: (data: WithTypename<ContentKitSuggestion>) => null | string,
  ContentKitsModel?: (data: WithTypename<ContentKitsModel>) => null | string,
  CurrentUserModel?: (data: WithTypename<CurrentUserModel>) => null | string,
  DimensionFilter?: (data: WithTypename<DimensionFilter>) => null | string,
  Dimensions?: (data: WithTypename<Dimensions>) => null | string,
  DownloadModel?: (data: WithTypename<DownloadModel>) => null | string,
  DraftProductModel?: (data: WithTypename<DraftProductModel>) => null | string,
  EditorCombinationDetailsModel?: (data: WithTypename<EditorCombinationDetailsModel>) => null | string,
  EditorDetailsModel?: (data: WithTypename<EditorDetailsModel>) => null | string,
  EditorInitialValuesModel?: (data: WithTypename<EditorInitialValuesModel>) => null | string,
  ExportAsModel?: (data: WithTypename<ExportAsModel>) => null | string,
  FileInfo?: (data: WithTypename<FileInfo>) => null | string,
  FilterOptions?: (data: WithTypename<FilterOptions>) => null | string,
  FiltersModel?: (data: WithTypename<FiltersModel>) => null | string,
  FinancialReportModel?: (data: WithTypename<FinancialReportModel>) => null | string,
  FindByIdFilter?: (data: WithTypename<FindByIdFilter>) => null | string,
  GenericFilterOptionItem?: (data: WithTypename<GenericFilterOptionItem>) => null | string,
  GenreModel?: (data: WithTypename<GenreModel>) => null | string,
  InfoModel?: (data: WithTypename<InfoModel>) => null | string,
  InternalUserModel?: (data: WithTypename<InternalUserModel>) => null | string,
  KeyValuePairOfStringAndObject?: (data: WithTypename<KeyValuePairOfStringAndObject>) => null | string,
  KeyValuePairOfStringAndProductSelectionModel?: (data: WithTypename<KeyValuePairOfStringAndProductSelectionModel>) => null | string,
  KeyValuePairOfStringAndString?: (data: WithTypename<KeyValuePairOfStringAndString>) => null | string,
  KeyValuePairOfStringAndString__?: (data: WithTypename<KeyValuePairOfStringAndString__>) => null | string,
  MetadataKeyFilterConnection?: (data: WithTypename<MetadataKeyFilterConnection>) => null | string,
  MetadataKeyFilterOption?: (data: WithTypename<MetadataKeyFilterOption>) => null | string,
  MetadataKeyFilterOptionEdge?: (data: WithTypename<MetadataKeyFilterOptionEdge>) => null | string,
  MetadataValueFilterConnection?: (data: WithTypename<MetadataValueFilterConnection>) => null | string,
  MetadataValueFilterOption?: (data: WithTypename<MetadataValueFilterOption>) => null | string,
  MetadataValueFilterOptionEdge?: (data: WithTypename<MetadataValueFilterOptionEdge>) => null | string,
  MsalConfigurationModel?: (data: WithTypename<MsalConfigurationModel>) => null | string,
  MyOrderListModel?: (data: WithTypename<MyOrderListModel>) => null | string,
  OrderItemModel?: (data: WithTypename<OrderItemModel>) => null | string,
  OrderModel?: (data: WithTypename<OrderModel>) => null | string,
  OrderSuggestion?: (data: WithTypename<OrderSuggestion>) => null | string,
  OutputCombinationConnection?: (data: WithTypename<OutputCombinationConnection>) => null | string,
  OutputCombinationEdge?: (data: WithTypename<OutputCombinationEdge>) => null | string,
  OutputCombinationGroupListModel?: (data: WithTypename<OutputCombinationGroupListModel>) => null | string,
  OutputCombinationGroupingInfoModel?: (data: WithTypename<OutputCombinationGroupingInfoModel>) => null | string,
  OutputCombinationListModel?: (data: WithTypename<OutputCombinationListModel>) => null | string,
  OutputCombinationModel?: (data: WithTypename<OutputCombinationModel>) => null | string,
  OutputCombinationOrderInfo?: (data: WithTypename<OutputCombinationOrderInfo>) => null | string,
  PageInfo?: (data: WithTypename<PageInfo>) => null | string,
  PremiumOrderingModel?: (data: WithTypename<PremiumOrderingModel>) => null | string,
  PreviewModel?: (data: WithTypename<PreviewModel>) => null | string,
  PreviewStatsModel?: (data: WithTypename<PreviewStatsModel>) => null | string,
  PreviewsListModel?: (data: WithTypename<PreviewsListModel>) => null | string,
  ProductCompatibilityOptions?: (data: WithTypename<ProductCompatibilityOptions>) => null | string,
  ProductCompatibilityResult?: (data: WithTypename<ProductCompatibilityResult>) => null | string,
  ProductFilter?: (data: WithTypename<ProductFilter>) => null | string,
  ProductFilterConnection?: (data: WithTypename<ProductFilterConnection>) => null | string,
  ProductFilterOption?: (data: WithTypename<ProductFilterOption>) => null | string,
  ProductFilterOptionEdge?: (data: WithTypename<ProductFilterOptionEdge>) => null | string,
  ProductFilterOptionItem?: (data: WithTypename<ProductFilterOptionItem>) => null | string,
  ProductGlobalId?: (data: WithTypename<ProductGlobalId>) => null | string,
  ProductGroupFilterConnection?: (data: WithTypename<ProductGroupFilterConnection>) => null | string,
  ProductGroupFilterOption?: (data: WithTypename<ProductGroupFilterOption>) => null | string,
  ProductGroupFilterOptionEdge?: (data: WithTypename<ProductGroupFilterOptionEdge>) => null | string,
  ProductGroupModel?: (data: WithTypename<ProductGroupModel>) => null | string,
  ProductListModel?: (data: WithTypename<ProductListModel>) => null | string,
  ProductModel?: (data: WithTypename<ProductModel>) => null | string,
  ProductOrderSuggestion?: (data: WithTypename<ProductOrderSuggestion>) => null | string,
  ProductOutputCombinationGroupModel?: (data: WithTypename<ProductOutputCombinationGroupModel>) => null | string,
  ProductSearchFilters?: (data: WithTypename<ProductSearchFilters>) => null | string,
  ProductSelectionModel?: (data: WithTypename<ProductSelectionModel>) => null | string,
  ProductSuggestion?: (data: WithTypename<ProductSuggestion>) => null | string,
  ProjectModel?: (data: WithTypename<ProjectModel>) => null | string,
  RangeFilter?: (data: WithTypename<RangeFilter>) => null | string,
  ReportRowModel?: (data: WithTypename<ReportRowModel>) => null | string,
  SalesForceReferenceModel?: (data: WithTypename<SalesForceReferenceModel>) => null | string,
  SceneInfoModel?: (data: WithTypename<SceneInfoModel>) => null | string,
  SceneModel?: (data: WithTypename<SceneModel>) => null | string,
  SearchContext?: (data: WithTypename<SearchContext>) => null | string,
  SearchFilterOptions?: (data: WithTypename<SearchFilterOptions>) => null | string,
  SearchResultSummary?: (data: WithTypename<SearchResultSummary>) => null | string,
  SearchStatusFilterFlags?: (data: WithTypename<SearchStatusFilterFlags>) => null | string,
  ShapeCollectionCompatibilityOptions?: (data: WithTypename<ShapeCollectionCompatibilityOptions>) => null | string,
  ShapeCollectionFilter?: (data: WithTypename<ShapeCollectionFilter>) => null | string,
  ShapeCollectionFilterConnection?: (data: WithTypename<ShapeCollectionFilterConnection>) => null | string,
  ShapeCollectionFilterOption?: (data: WithTypename<ShapeCollectionFilterOption>) => null | string,
  ShapeCollectionFilterOptionEdge?: (data: WithTypename<ShapeCollectionFilterOptionEdge>) => null | string,
  ShapeCollectionImageSceneModel?: (data: WithTypename<ShapeCollectionImageSceneModel>) => null | string,
  ShapeCollectionListModel?: (data: WithTypename<ShapeCollectionListModel>) => null | string,
  ShapeCollectionModel?: (data: WithTypename<ShapeCollectionModel>) => null | string,
  ShapeCollectionUVRemapSceneModel?: (data: WithTypename<ShapeCollectionUvRemapSceneModel>) => null | string,
  ShapeCollectionVideoSceneModel?: (data: WithTypename<ShapeCollectionVideoSceneModel>) => null | string,
  ShapeFilterConnection?: (data: WithTypename<ShapeFilterConnection>) => null | string,
  ShapeFilterOption?: (data: WithTypename<ShapeFilterOption>) => null | string,
  ShapeFilterOptionEdge?: (data: WithTypename<ShapeFilterOptionEdge>) => null | string,
  ShapeModel?: (data: WithTypename<ShapeModel>) => null | string,
  ShapeViewerDataModel?: (data: WithTypename<ShapeViewerDataModel>) => null | string,
  SimplifiedArtworkSetModel?: (data: WithTypename<SimplifiedArtworkSetModel>) => null | string,
  SimplifiedProductModel?: (data: WithTypename<SimplifiedProductModel>) => null | string,
  SimplifiedShapeCollectionModel?: (data: WithTypename<SimplifiedShapeCollectionModel>) => null | string,
  StashItemModelOfEditorDetailsModel?: (data: WithTypename<StashItemModelOfEditorDetailsModel>) => null | string,
  TemplateAITextSuggestion?: (data: WithTypename<TemplateAiTextSuggestion>) => null | string,
  TemplateClickDragInteraction?: (data: WithTypename<TemplateClickDragInteraction>) => null | string,
  TemplateFileResource?: (data: WithTypename<TemplateFileResource>) => null | string,
  TemplateInputModel?: (data: WithTypename<TemplateInputModel>) => null | string,
  TemplateInputOptions?: (data: WithTypename<TemplateInputOptions>) => null | string,
  TemplateInputRange?: (data: WithTypename<TemplateInputRange>) => null | string,
  TemplateListModel?: (data: WithTypename<TemplateListModel>) => null | string,
  TemplateMetadata?: (data: WithTypename<TemplateMetadata>) => null | string,
  TemplateModel?: (data: WithTypename<TemplateModel>) => null | string,
  TemplateOutput?: (data: WithTypename<TemplateOutput>) => null | string,
  TemplateSegment?: (data: WithTypename<TemplateSegment>) => null | string,
  TemplateSegmentPreview?: (data: WithTypename<TemplateSegmentPreview>) => null | string,
  TemplateTextTranslation?: (data: WithTypename<TemplateTextTranslation>) => null | string,
  TenantModel?: (data: WithTypename<TenantModel>) => null | string,
  TransactionModel?: (data: WithTypename<TransactionModel>) => null | string,
  TransactionReportModel?: (data: WithTypename<TransactionReportModel>) => null | string,
  UnitSize?: (data: WithTypename<UnitSize>) => null | string,
  UserJourneyModel?: (data: WithTypename<UserJourneyModel>) => null | string,
  UserModel?: (data: WithTypename<UserModel>) => null | string,
  UserOrderSuggestion?: (data: WithTypename<UserOrderSuggestion>) => null | string,
  UserProfileModel?: (data: WithTypename<UserProfileModel>) => null | string,
  UserProvisioningTableItemModel?: (data: WithTypename<UserProvisioningTableItemModel>) => null | string,
  UserProvisioningTableModel?: (data: WithTypename<UserProvisioningTableModel>) => null | string,
  UserWithBudgetsModel?: (data: WithTypename<UserWithBudgetsModel>) => null | string,
  ValidationErrorModel?: (data: WithTypename<ValidationErrorModel>) => null | string,
  ValidationFailure?: (data: WithTypename<ValidationFailure>) => null | string,
  ValidationResult?: (data: WithTypename<ValidationResult>) => null | string,
  ValidationResultModel?: (data: WithTypename<ValidationResultModel>) => null | string,
  Validator?: (data: WithTypename<Validator>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    additionalImageTypeById?: GraphCacheResolver<WithTypename<Query>, QueryAdditionalImageTypeByIdArgs, WithTypename<AdditionalImageTypeModel> | string>,
    additionalImageTypes?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<AdditionalImageTypeModel> | string>>,
    additionalImagesByProductId?: GraphCacheResolver<WithTypename<Query>, QueryAdditionalImagesByProductIdArgs, Array<WithTypename<AdditionalImageModel> | string>>,
    adminBudgets?: GraphCacheResolver<WithTypename<Query>, QueryAdminBudgetsArgs, WithTypename<BudgetListModel> | string>,
    advancedFiltersSettings?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<AdvancedFiltersSettings> | string>,
    advancedProductFilterOptions?: GraphCacheResolver<WithTypename<Query>, QueryAdvancedProductFilterOptionsArgs, WithTypename<AdvancedProductFilterOptions> | string>,
    allOutputCombinationsByFilters?: GraphCacheResolver<WithTypename<Query>, QueryAllOutputCombinationsByFiltersArgs, WithTypename<OutputCombinationConnection> | string>,
    areProductsCompatible?: GraphCacheResolver<WithTypename<Query>, QueryAreProductsCompatibleArgs, Array<WithTypename<ProductCompatibilityResult> | string>>,
    artworkSetById?: GraphCacheResolver<WithTypename<Query>, QueryArtworkSetByIdArgs, WithTypename<ArtworkSetModel> | string>,
    artworkSets?: GraphCacheResolver<WithTypename<Query>, QueryArtworkSetsArgs, WithTypename<ArtworkSetListModel> | string>,
    brandById?: GraphCacheResolver<WithTypename<Query>, QueryBrandByIdArgs, WithTypename<BrandModel> | string>,
    brandByName?: GraphCacheResolver<WithTypename<Query>, QueryBrandByNameArgs, WithTypename<BrandModel> | string>,
    brands?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<BrandModel> | string>>,
    budgetById?: GraphCacheResolver<WithTypename<Query>, QueryBudgetByIdArgs, WithTypename<BudgetModel> | string>,
    budgets?: GraphCacheResolver<WithTypename<Query>, QueryBudgetsArgs, WithTypename<BudgetListModel> | string>,
    contentKitById?: GraphCacheResolver<WithTypename<Query>, QueryContentKitByIdArgs, WithTypename<ContentKitModel> | string>,
    contentKits?: GraphCacheResolver<WithTypename<Query>, QueryContentKitsArgs, WithTypename<ContentKitsModel> | string>,
    courierToken?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Scalars['String'] | string>,
    currentBasket?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<BasketModel> | string>,
    currentUser?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<CurrentUserModel> | string>,
    draftProductById?: GraphCacheResolver<WithTypename<Query>, QueryDraftProductByIdArgs, WithTypename<DraftProductModel> | string>,
    draftProducts?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<DraftProductModel> | string>>,
    editorCombinationDetails?: GraphCacheResolver<WithTypename<Query>, QueryEditorCombinationDetailsArgs, WithTypename<EditorCombinationDetailsModel> | string>,
    editorInitialValues?: GraphCacheResolver<WithTypename<Query>, QueryEditorInitialValuesArgs, WithTypename<EditorInitialValuesModel> | string>,
    expandOutputCombinationGrouping?: GraphCacheResolver<WithTypename<Query>, QueryExpandOutputCombinationGroupingArgs, Array<WithTypename<OutputCombinationModel> | string>>,
    filtersBySearchId?: GraphCacheResolver<WithTypename<Query>, QueryFiltersBySearchIdArgs, WithTypename<FiltersModel> | string>,
    financialReports?: GraphCacheResolver<WithTypename<Query>, QueryFinancialReportsArgs, WithTypename<FinancialReportModel> | string>,
    findCompatibleProducts?: GraphCacheResolver<WithTypename<Query>, QueryFindCompatibleProductsArgs, WithTypename<CompatibleProductSearchResult> | string>,
    findOutputCombinationGroups?: GraphCacheResolver<WithTypename<Query>, QueryFindOutputCombinationGroupsArgs, WithTypename<OutputCombinationGroupListModel> | string>,
    genreById?: GraphCacheResolver<WithTypename<Query>, QueryGenreByIdArgs, WithTypename<GenreModel> | string>,
    genres?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<GenreModel> | string>>,
    internalUsers?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<InternalUserModel> | string>>,
    isProductsCompatible?: GraphCacheResolver<WithTypename<Query>, QueryIsProductsCompatibleArgs, WithTypename<ProductCompatibilityResult> | string>,
    managedTenants?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<TenantModel> | string>>,
    metadataFilterOptions?: GraphCacheResolver<WithTypename<Query>, QueryMetadataFilterOptionsArgs, WithTypename<MetadataKeyFilterOption> | string>,
    msalConfiguration?: GraphCacheResolver<WithTypename<Query>, QueryMsalConfigurationArgs, WithTypename<MsalConfigurationModel> | string>,
    myOrders?: GraphCacheResolver<WithTypename<Query>, QueryMyOrdersArgs, WithTypename<MyOrderListModel> | string>,
    order?: GraphCacheResolver<WithTypename<Query>, QueryOrderArgs, WithTypename<OrderModel> | string>,
    orderQuickSearch?: GraphCacheResolver<WithTypename<Query>, QueryOrderQuickSearchArgs, Array<WithTypename<BudgetOrderSuggestion> | WithTypename<OrderSuggestion> | WithTypename<ProductOrderSuggestion> | WithTypename<UserOrderSuggestion> | string>>,
    outputCombinationById?: GraphCacheResolver<WithTypename<Query>, QueryOutputCombinationByIdArgs, WithTypename<OutputCombinationModel> | string>,
    outputCombinationByOrderId?: GraphCacheResolver<WithTypename<Query>, QueryOutputCombinationByOrderIdArgs, Array<WithTypename<OutputCombinationModel> | string>>,
    outputCombinationGroup?: GraphCacheResolver<WithTypename<Query>, QueryOutputCombinationGroupArgs, WithTypename<ContentKitOutputCombinationGroupModel> | WithTypename<ProductOutputCombinationGroupModel> | string>,
    outputCombinationGroups?: GraphCacheResolver<WithTypename<Query>, QueryOutputCombinationGroupsArgs, WithTypename<OutputCombinationGroupListModel> | string>,
    productById?: GraphCacheResolver<WithTypename<Query>, QueryProductByIdArgs, WithTypename<ProductModel> | string>,
    productCompatibilityOptions?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<ProductCompatibilityOptions> | string>,
    productGroupById?: GraphCacheResolver<WithTypename<Query>, QueryProductGroupByIdArgs, WithTypename<ProductGroupModel> | string>,
    productGroupFilterOptions?: GraphCacheResolver<WithTypename<Query>, QueryProductGroupFilterOptionsArgs, WithTypename<ProductGroupFilterOption> | string>,
    productGroupProductOptions?: GraphCacheResolver<WithTypename<Query>, QueryProductGroupProductOptionsArgs, WithTypename<ProductListModel> | string>,
    productGroups?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<ProductGroupModel> | string>>,
    productPreviews?: GraphCacheResolver<WithTypename<Query>, QueryProductPreviewsArgs, WithTypename<PreviewsListModel> | string>,
    products?: GraphCacheResolver<WithTypename<Query>, QueryProductsArgs, WithTypename<ProductListModel> | string>,
    projectById?: GraphCacheResolver<WithTypename<Query>, QueryProjectByIdArgs, WithTypename<ProjectModel> | string>,
    projects?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<ProjectModel> | string>>,
    quickSearch?: GraphCacheResolver<WithTypename<Query>, QueryQuickSearchArgs, Array<WithTypename<ContentKitSuggestion> | WithTypename<ProductSuggestion> | string>>,
    sceneById?: GraphCacheResolver<WithTypename<Query>, QuerySceneByIdArgs, WithTypename<SceneModel> | string>,
    scenes?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<SceneModel> | string>>,
    searchFilterOptions?: GraphCacheResolver<WithTypename<Query>, QuerySearchFilterOptionsArgs, WithTypename<SearchFilterOptions> | string>,
    searchIdByFilters?: GraphCacheResolver<WithTypename<Query>, QuerySearchIdByFiltersArgs, Scalars['String'] | string>,
    searchSummary?: GraphCacheResolver<WithTypename<Query>, QuerySearchSummaryArgs, WithTypename<SearchResultSummary> | string>,
    shapeCollectionById?: GraphCacheResolver<WithTypename<Query>, QueryShapeCollectionByIdArgs, WithTypename<ShapeCollectionModel> | string>,
    shapeCollectionFilterOptions?: GraphCacheResolver<WithTypename<Query>, QueryShapeCollectionFilterOptionsArgs, WithTypename<ShapeCollectionFilterOption> | string>,
    shapeCollectionSceneById?: GraphCacheResolver<WithTypename<Query>, QueryShapeCollectionSceneByIdArgs, WithTypename<ShapeCollectionImageSceneModel> | WithTypename<ShapeCollectionUvRemapSceneModel> | WithTypename<ShapeCollectionVideoSceneModel> | string>,
    shapeCollectionScenesByShapeCollection?: GraphCacheResolver<WithTypename<Query>, QueryShapeCollectionScenesByShapeCollectionArgs, Array<WithTypename<ShapeCollectionImageSceneModel> | WithTypename<ShapeCollectionUvRemapSceneModel> | WithTypename<ShapeCollectionVideoSceneModel> | string>>,
    shapeCollections?: GraphCacheResolver<WithTypename<Query>, QueryShapeCollectionsArgs, WithTypename<ShapeCollectionListModel> | string>,
    stashedEditorDetailsInput?: GraphCacheResolver<WithTypename<Query>, QueryStashedEditorDetailsInputArgs, WithTypename<StashItemModelOfEditorDetailsModel> | string>,
    templateById?: GraphCacheResolver<WithTypename<Query>, QueryTemplateByIdArgs, WithTypename<TemplateModel> | string>,
    templatePreviews?: GraphCacheResolver<WithTypename<Query>, QueryTemplatePreviewsArgs, WithTypename<PreviewsListModel> | string>,
    templates?: GraphCacheResolver<WithTypename<Query>, QueryTemplatesArgs, WithTypename<TemplateListModel> | string>,
    tenant?: GraphCacheResolver<WithTypename<Query>, QueryTenantArgs, WithTypename<TenantModel> | string>,
    tenantAdditionalImageTypes?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<AdditionalImageTypeModel> | string>>,
    tenantScenes?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<SceneModel> | string>>,
    tenants?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<TenantModel> | string>>,
    transactionReports?: GraphCacheResolver<WithTypename<Query>, QueryTransactionReportsArgs, WithTypename<TransactionReportModel> | string>,
    userJourney?: GraphCacheResolver<WithTypename<Query>, QueryUserJourneyArgs, WithTypename<UserJourneyModel> | string>,
    userProfile?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<UserProfileModel> | string>,
    userProvisioningTableById?: GraphCacheResolver<WithTypename<Query>, QueryUserProvisioningTableByIdArgs, WithTypename<UserProvisioningTableModel> | string>,
    userProvisioningTables?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<UserProvisioningTableModel> | string>>,
    users?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<UserWithBudgetsModel> | string>>
  },
  AddUserProvisioningTableFailed?: {
    csvDownloadUrl?: GraphCacheResolver<WithTypename<AddUserProvisioningTableFailed>, Record<string, never>, Scalars['String'] | string>,
    succeeded?: GraphCacheResolver<WithTypename<AddUserProvisioningTableFailed>, Record<string, never>, Scalars['Boolean'] | string>,
    validationResult?: GraphCacheResolver<WithTypename<AddUserProvisioningTableFailed>, Record<string, never>, WithTypename<ValidationResult> | string>
  },
  AddUserProvisioningTableSucceeded?: {
    data?: GraphCacheResolver<WithTypename<AddUserProvisioningTableSucceeded>, Record<string, never>, WithTypename<UserProvisioningTableModel> | string>,
    succeeded?: GraphCacheResolver<WithTypename<AddUserProvisioningTableSucceeded>, Record<string, never>, Scalars['Boolean'] | string>
  },
  AdditionalImageModel?: {
    additionalImageType?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, WithTypename<AdditionalImageTypeModel> | string>,
    additionalImageTypeId?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['UUID'] | string>,
    fileName?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['String'] | string>,
    fileType?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['String'] | string>,
    height?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['UUID'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['String'] | string>,
    productId?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['UUID'] | string>,
    tenantId?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['UUID'] | string>,
    width?: GraphCacheResolver<WithTypename<AdditionalImageModel>, Record<string, never>, Scalars['Int'] | string>
  },
  AdditionalImageTypeModel?: {
    description?: GraphCacheResolver<WithTypename<AdditionalImageTypeModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<AdditionalImageTypeModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<AdditionalImageTypeModel>, Record<string, never>, Scalars['String'] | string>,
    tenants?: GraphCacheResolver<WithTypename<AdditionalImageTypeModel>, Record<string, never>, Array<WithTypename<TenantModel> | string>>
  },
  AdvancedFiltersSettings?: {
    metadataFiltersEnabled?: GraphCacheResolver<WithTypename<AdvancedFiltersSettings>, Record<string, never>, Scalars['Boolean'] | string>,
    productGroupFiltersEnabled?: GraphCacheResolver<WithTypename<AdvancedFiltersSettings>, Record<string, never>, Scalars['Boolean'] | string>,
    shapeCollectionFiltersEnabled?: GraphCacheResolver<WithTypename<AdvancedFiltersSettings>, Record<string, never>, Scalars['Boolean'] | string>
  },
  AdvancedProductFilterOptions?: {
    metadataFilterOptions?: GraphCacheResolver<WithTypename<AdvancedProductFilterOptions>, AdvancedProductFilterOptionsMetadataFilterOptionsArgs, WithTypename<MetadataKeyFilterConnection> | string>,
    productGroupFilterOptions?: GraphCacheResolver<WithTypename<AdvancedProductFilterOptions>, AdvancedProductFilterOptionsProductGroupFilterOptionsArgs, WithTypename<ProductGroupFilterConnection> | string>,
    shapeCollectionFilterOptions?: GraphCacheResolver<WithTypename<AdvancedProductFilterOptions>, AdvancedProductFilterOptionsShapeCollectionFilterOptionsArgs, WithTypename<ShapeCollectionFilterConnection> | string>
  },
  ArtworkMapping?: {
    inputs?: GraphCacheResolver<WithTypename<ArtworkMapping>, Record<string, never>, Array<WithTypename<ArtworkMappingInputObject> | string>>,
    mappingUrl?: GraphCacheResolver<WithTypename<ArtworkMapping>, Record<string, never>, Scalars['String'] | string>
  },
  ArtworkMappingInputObject?: {
    id?: GraphCacheResolver<WithTypename<ArtworkMappingInputObject>, Record<string, never>, Scalars['String'] | string>,
    inputType?: GraphCacheResolver<WithTypename<ArtworkMappingInputObject>, Record<string, never>, ArtworkMappingInputType | string>,
    name?: GraphCacheResolver<WithTypename<ArtworkMappingInputObject>, Record<string, never>, Scalars['String'] | string>,
    options?: GraphCacheResolver<WithTypename<ArtworkMappingInputObject>, Record<string, never>, Array<WithTypename<ArtworkMappingOption> | string>>,
    required?: GraphCacheResolver<WithTypename<ArtworkMappingInputObject>, Record<string, never>, Scalars['Boolean'] | string>
  },
  ArtworkMappingOption?: {
    name?: GraphCacheResolver<WithTypename<ArtworkMappingOption>, Record<string, never>, Scalars['String'] | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<ArtworkMappingOption>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<ArtworkMappingOption>, Record<string, never>, Scalars['String'] | string>
  },
  ArtworkModel?: {
    fullQuality?: GraphCacheResolver<WithTypename<ArtworkModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<ArtworkModel>, Record<string, never>, Scalars['UUID'] | string>,
    nasId?: GraphCacheResolver<WithTypename<ArtworkModel>, Record<string, never>, Scalars['String'] | string>,
    preview?: GraphCacheResolver<WithTypename<ArtworkModel>, Record<string, never>, Scalars['String'] | string>,
    shapeCollectionId?: GraphCacheResolver<WithTypename<ArtworkModel>, Record<string, never>, Scalars['UUID'] | string>,
    thumbnail?: GraphCacheResolver<WithTypename<ArtworkModel>, Record<string, never>, Scalars['String'] | string>
  },
  ArtworkSetCompatibilityOptions?: {
    artworkTypes?: GraphCacheResolver<WithTypename<ArtworkSetCompatibilityOptions>, Record<string, never>, Array<ArtworkType | string>>
  },
  ArtworkSetFilter?: {
    artworkSetTypes?: GraphCacheResolver<WithTypename<ArtworkSetFilter>, Record<string, never>, Array<ArtworkType | string>>
  },
  ArtworkSetListModel?: {
    artworkSets?: GraphCacheResolver<WithTypename<ArtworkSetListModel>, ArtworkSetListModelArtworkSetsArgs, Array<WithTypename<ArtworkSetModel> | string>>,
    filteredTotal?: GraphCacheResolver<WithTypename<ArtworkSetListModel>, Record<string, never>, Scalars['Int'] | string>
  },
  ArtworkSetModel?: {
    artworks?: GraphCacheResolver<WithTypename<ArtworkSetModel>, Record<string, never>, Array<WithTypename<ArtworkModel> | string>>,
    id?: GraphCacheResolver<WithTypename<ArtworkSetModel>, Record<string, never>, Scalars['UUID'] | string>,
    locale?: GraphCacheResolver<WithTypename<ArtworkSetModel>, Record<string, never>, Array<Scalars['String'] | string>>,
    name?: GraphCacheResolver<WithTypename<ArtworkSetModel>, Record<string, never>, Scalars['String'] | string>,
    source?: GraphCacheResolver<WithTypename<ArtworkSetModel>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ArtworkSetModel>, Record<string, never>, ArtworkType | string>,
    validatedBy?: GraphCacheResolver<WithTypename<ArtworkSetModel>, Record<string, never>, WithTypename<Validator> | string>
  },
  BaseMeasurementFilter?: {
    max?: GraphCacheResolver<WithTypename<BaseMeasurementFilter>, Record<string, never>, Scalars['Int'] | string>,
    measurement?: GraphCacheResolver<WithTypename<BaseMeasurementFilter>, Record<string, never>, BaseMeasurement | string>,
    min?: GraphCacheResolver<WithTypename<BaseMeasurementFilter>, Record<string, never>, Scalars['Int'] | string>
  },
  BasketGroupModel?: {
    createdAt?: GraphCacheResolver<WithTypename<BasketGroupModel>, Record<string, never>, Scalars['DateTime'] | string>,
    groupId?: GraphCacheResolver<WithTypename<BasketGroupModel>, Record<string, never>, Scalars['UUID'] | string>,
    items?: GraphCacheResolver<WithTypename<BasketGroupModel>, Record<string, never>, Array<WithTypename<BasketItemModel> | string>>
  },
  BasketItemModel?: {
    configuration?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, WithTypename<ConfigurationModel> | string>,
    contentKit?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, WithTypename<ContentKitModel> | string>,
    creditCost?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Scalars['Int'] | string>,
    hashKey?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Scalars['UUID'] | string>,
    isPremiumOrderable?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Scalars['Boolean'] | string>,
    mainProduct?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, WithTypename<ProductModel> | string>,
    outputVariant?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Scalars['String'] | string>,
    productSelections?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndProductSelectionModel> | string>>,
    products?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Array<WithTypename<SimplifiedProductModel> | string>>,
    status?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, WithTypename<BasketItemStatusFlags> | string>,
    template?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, WithTypename<TemplateModel> | string>,
    templateVersion?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Scalars['Int'] | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Scalars['String'] | string>,
    userInputs?: GraphCacheResolver<WithTypename<BasketItemModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString> | string>>
  },
  BasketItemStatusFlags?: {
    isInBasket?: GraphCacheResolver<WithTypename<BasketItemStatusFlags>, Record<string, never>, Scalars['Boolean'] | string>,
    isOrdered?: GraphCacheResolver<WithTypename<BasketItemStatusFlags>, Record<string, never>, Scalars['Boolean'] | string>
  },
  BasketModel?: {
    groups?: GraphCacheResolver<WithTypename<BasketModel>, Record<string, never>, Array<WithTypename<BasketGroupModel> | string>>
  },
  BrandModel?: {
    color?: GraphCacheResolver<WithTypename<BrandModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<BrandModel>, Record<string, never>, Scalars['UUID'] | string>,
    logoUrl?: GraphCacheResolver<WithTypename<BrandModel>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<BrandModel>, Record<string, never>, Scalars['String'] | string>
  },
  BudgetListModel?: {
    budgets?: GraphCacheResolver<WithTypename<BudgetListModel>, BudgetListModelBudgetsArgs, Array<WithTypename<BudgetModel> | string>>,
    totalNumber?: GraphCacheResolver<WithTypename<BudgetListModel>, Record<string, never>, Scalars['Int'] | string>
  },
  BudgetModel?: {
    assignedUsers?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    budgetType?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, BudgetType | string>,
    createdAt?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['DateTime'] | string>,
    creditValue?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['Decimal'] | string>,
    credits?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['Int'] | string>,
    expiresAt?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['UUID'] | string>,
    isExpired?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['Boolean'] | string>,
    isInUse?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['Boolean'] | string>,
    isSuspended?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['String'] | string>,
    owner?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, WithTypename<InfoModel> | string>,
    paymentType?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, BudgetPaymentType | string>,
    premiumOrdering?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, WithTypename<PremiumOrderingModel> | string>,
    salesForceReferences?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Array<WithTypename<SalesForceReferenceModel> | string>>,
    subscriptionCreditAmount?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['Int'] | string>,
    subscriptionStart?: GraphCacheResolver<WithTypename<BudgetModel>, Record<string, never>, Scalars['DateTime'] | string>
  },
  BudgetOrderSuggestion?: {
    id?: GraphCacheResolver<WithTypename<BudgetOrderSuggestion>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<BudgetOrderSuggestion>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<BudgetOrderSuggestion>, Record<string, never>, OrderSearchEntityType | string>
  },
  CompatibleProductSearchResult?: {
    products?: GraphCacheResolver<WithTypename<CompatibleProductSearchResult>, CompatibleProductSearchResultProductsArgs, Array<WithTypename<ProductModel> | string>>,
    totalNumber?: GraphCacheResolver<WithTypename<CompatibleProductSearchResult>, Record<string, never>, Scalars['Int'] | string>
  },
  CompleteShapeCollectionSceneModel?: {
    hdriFileUrl?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    json?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>,
    jsonFileUrl?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>,
    lutFileUrl?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>,
    prerenderedImageFileUrl?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>,
    prerenderedVideoFileUrl?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>,
    scene?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, WithTypename<SceneInfoModel> | string>,
    shapeCollectionId?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    shapeViewerData?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, WithTypename<ShapeViewerDataModel> | string>,
    uvFileUrl?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>,
    videoUvFileUrl?: GraphCacheResolver<WithTypename<CompleteShapeCollectionSceneModel>, Record<string, never>, Scalars['String'] | string>
  },
  ConfigurationModel?: {
    outputVariant?: GraphCacheResolver<WithTypename<ConfigurationModel>, Record<string, never>, Scalars['String'] | string>,
    productSelections?: GraphCacheResolver<WithTypename<ConfigurationModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndProductSelectionModel> | string>>,
    userInputs?: GraphCacheResolver<WithTypename<ConfigurationModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString> | string>>
  },
  ContentKitModel?: {
    genre?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, WithTypename<GenreModel> | string>,
    id?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, Scalars['UUID'] | string>,
    lastModifiedAt?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, Scalars['DateTime'] | string>,
    metadata?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString> | string>>,
    name?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, Scalars['String'] | string>,
    project?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, WithTypename<ProjectModel> | string>,
    related?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, Array<WithTypename<ContentKitModel> | string>>,
    templates?: GraphCacheResolver<WithTypename<ContentKitModel>, Record<string, never>, Array<WithTypename<TemplateModel> | string>>
  },
  ContentKitOutputCombinationGroupModel?: {
    contentKit?: GraphCacheResolver<WithTypename<ContentKitOutputCombinationGroupModel>, Record<string, never>, WithTypename<ContentKitModel> | string>,
    genre?: GraphCacheResolver<WithTypename<ContentKitOutputCombinationGroupModel>, Record<string, never>, WithTypename<GenreModel> | string>,
    id?: GraphCacheResolver<WithTypename<ContentKitOutputCombinationGroupModel>, Record<string, never>, Scalars['String'] | string>,
    mainEntityId?: GraphCacheResolver<WithTypename<ContentKitOutputCombinationGroupModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ContentKitOutputCombinationGroupModel>, Record<string, never>, Scalars['String'] | string>,
    outputCombinations?: GraphCacheResolver<WithTypename<ContentKitOutputCombinationGroupModel>, Record<string, never>, WithTypename<OutputCombinationListModel> | string>
  },
  ContentKitSearchFilters?: {
    contentKits?: GraphCacheResolver<WithTypename<ContentKitSearchFilters>, Record<string, never>, WithTypename<FindByIdFilter> | string>,
    genres?: GraphCacheResolver<WithTypename<ContentKitSearchFilters>, Record<string, never>, WithTypename<FindByIdFilter> | string>
  },
  ContentKitSuggestion?: {
    id?: GraphCacheResolver<WithTypename<ContentKitSuggestion>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ContentKitSuggestion>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ContentKitSuggestion>, Record<string, never>, SearchEntityType | string>
  },
  ContentKitsModel?: {
    contentKits?: GraphCacheResolver<WithTypename<ContentKitsModel>, ContentKitsModelContentKitsArgs, Array<WithTypename<ContentKitModel> | string>>,
    filteredTotal?: GraphCacheResolver<WithTypename<ContentKitsModel>, Record<string, never>, Scalars['Int'] | string>
  },
  CurrentUserModel?: {
    email?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Scalars['String'] | string>,
    firstName?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Scalars['UUID'] | string>,
    lastName?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Scalars['String'] | string>,
    roles?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Array<UserRoleEnum | string>>,
    selectedTenantId?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Scalars['UUID'] | string>,
    tenantId?: GraphCacheResolver<WithTypename<CurrentUserModel>, Record<string, never>, Scalars['UUID'] | string>
  },
  DimensionFilter?: {
    depth?: GraphCacheResolver<WithTypename<DimensionFilter>, Record<string, never>, WithTypename<RangeFilter> | string>,
    height?: GraphCacheResolver<WithTypename<DimensionFilter>, Record<string, never>, WithTypename<RangeFilter> | string>,
    width?: GraphCacheResolver<WithTypename<DimensionFilter>, Record<string, never>, WithTypename<RangeFilter> | string>
  },
  Dimensions?: {
    depth?: GraphCacheResolver<WithTypename<Dimensions>, Record<string, never>, Scalars['Int'] | string>,
    height?: GraphCacheResolver<WithTypename<Dimensions>, Record<string, never>, Scalars['Int'] | string>,
    width?: GraphCacheResolver<WithTypename<Dimensions>, Record<string, never>, Scalars['Int'] | string>
  },
  DownloadModel?: {
    downloadUrl?: GraphCacheResolver<WithTypename<DownloadModel>, Record<string, never>, Scalars['String'] | string>
  },
  DraftProductModel?: {
    artworkSets?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, Array<WithTypename<ArtworkSetModel> | string>>,
    brand?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, WithTypename<BrandModel> | string>,
    globalId?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, WithTypename<ProductGlobalId> | string>,
    id?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, Scalars['UUID'] | string>,
    metadata?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString> | string>>,
    name?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, Scalars['String'] | string>,
    project?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, WithTypename<ProjectModel> | string>,
    shapeCollection?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, WithTypename<ShapeCollectionModel> | string>,
    variant?: GraphCacheResolver<WithTypename<DraftProductModel>, Record<string, never>, Scalars['String'] | string>
  },
  EditorCombinationDetailsModel?: {
    hashKey?: GraphCacheResolver<WithTypename<EditorCombinationDetailsModel>, Record<string, never>, Scalars['String'] | string>,
    orderHistory?: GraphCacheResolver<WithTypename<EditorCombinationDetailsModel>, Record<string, never>, Array<WithTypename<OutputCombinationOrderInfo> | string>>
  },
  EditorDetailsModel?: {
    contentKitId?: GraphCacheResolver<WithTypename<EditorDetailsModel>, Record<string, never>, Scalars['UUID'] | string>,
    outputVariant?: GraphCacheResolver<WithTypename<EditorDetailsModel>, Record<string, never>, Scalars['String'] | string>,
    productSelections?: GraphCacheResolver<WithTypename<EditorDetailsModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndProductSelectionModel> | string>>,
    templateId?: GraphCacheResolver<WithTypename<EditorDetailsModel>, Record<string, never>, Scalars['UUID'] | string>,
    userInputs?: GraphCacheResolver<WithTypename<EditorDetailsModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString> | string>>
  },
  EditorInitialValuesModel?: {
    configuration?: GraphCacheResolver<WithTypename<EditorInitialValuesModel>, Record<string, never>, WithTypename<ConfigurationModel> | string>,
    contentKit?: GraphCacheResolver<WithTypename<EditorInitialValuesModel>, Record<string, never>, WithTypename<ContentKitModel> | string>,
    products?: GraphCacheResolver<WithTypename<EditorInitialValuesModel>, Record<string, never>, Array<WithTypename<ProductModel> | string>>,
    template?: GraphCacheResolver<WithTypename<EditorInitialValuesModel>, Record<string, never>, WithTypename<TemplateModel> | string>
  },
  ExportAsModel?: {
    downloadUrl?: GraphCacheResolver<WithTypename<ExportAsModel>, Record<string, never>, Scalars['String'] | string>
  },
  FileInfo?: {
    contentType?: GraphCacheResolver<WithTypename<FileInfo>, Record<string, never>, Scalars['String'] | string>,
    extension?: GraphCacheResolver<WithTypename<FileInfo>, Record<string, never>, Scalars['String'] | string>,
    height?: GraphCacheResolver<WithTypename<FileInfo>, Record<string, never>, Scalars['Int'] | string>,
    originalFileName?: GraphCacheResolver<WithTypename<FileInfo>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<FileInfo>, Record<string, never>, OutputCombinationFileType | string>,
    width?: GraphCacheResolver<WithTypename<FileInfo>, Record<string, never>, Scalars['Int'] | string>
  },
  FilterOptions?: {
    options?: GraphCacheResolver<WithTypename<FilterOptions>, Record<string, never>, Array<WithTypename<GenericFilterOptionItem> | WithTypename<ProductFilterOptionItem> | string>>,
    total?: GraphCacheResolver<WithTypename<FilterOptions>, Record<string, never>, Scalars['Long'] | string>
  },
  FiltersModel?: {
    brands?: GraphCacheResolver<WithTypename<FiltersModel>, FiltersModelBrandsArgs, Array<WithTypename<GenericFilterOptionItem> | WithTypename<ProductFilterOptionItem> | string>>,
    contentKits?: GraphCacheResolver<WithTypename<FiltersModel>, FiltersModelContentKitsArgs, Array<WithTypename<GenericFilterOptionItem> | WithTypename<ProductFilterOptionItem> | string>>,
    genres?: GraphCacheResolver<WithTypename<FiltersModel>, FiltersModelGenresArgs, Array<WithTypename<GenericFilterOptionItem> | WithTypename<ProductFilterOptionItem> | string>>,
    products?: GraphCacheResolver<WithTypename<FiltersModel>, FiltersModelProductsArgs, Array<WithTypename<GenericFilterOptionItem> | WithTypename<ProductFilterOptionItem> | string>>,
    searchContext?: GraphCacheResolver<WithTypename<FiltersModel>, Record<string, never>, WithTypename<SearchContext> | string>
  },
  FinancialReportModel?: {
    rows?: GraphCacheResolver<WithTypename<FinancialReportModel>, FinancialReportModelRowsArgs, Array<WithTypename<ReportRowModel> | string>>,
    totalNumber?: GraphCacheResolver<WithTypename<FinancialReportModel>, Record<string, never>, Scalars['Int'] | string>
  },
  FindByIdFilter?: {
    idList?: GraphCacheResolver<WithTypename<FindByIdFilter>, Record<string, never>, Array<Scalars['UUID'] | string>>,
    requirement?: GraphCacheResolver<WithTypename<FindByIdFilter>, Record<string, never>, FindByIdRequirement | string>
  },
  GenericFilterOptionItem?: {
    id?: GraphCacheResolver<WithTypename<GenericFilterOptionItem>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<GenericFilterOptionItem>, Record<string, never>, Scalars['String'] | string>,
    product?: GraphCacheResolver<WithTypename<GenericFilterOptionItem>, Record<string, never>, WithTypename<ProductModel> | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<GenericFilterOptionItem>, Record<string, never>, Scalars['String'] | string>
  },
  GenreModel?: {
    color?: GraphCacheResolver<WithTypename<GenreModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<GenreModel>, Record<string, never>, Scalars['UUID'] | string>,
    logoUrl?: GraphCacheResolver<WithTypename<GenreModel>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<GenreModel>, Record<string, never>, Scalars['String'] | string>
  },
  InfoModel?: {
    id?: GraphCacheResolver<WithTypename<InfoModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<InfoModel>, Record<string, never>, Scalars['String'] | string>
  },
  InternalUserModel?: {
    email?: GraphCacheResolver<WithTypename<InternalUserModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<InternalUserModel>, Record<string, never>, Scalars['UUID'] | string>,
    loggedInBefore?: GraphCacheResolver<WithTypename<InternalUserModel>, Record<string, never>, Scalars['Boolean'] | string>,
    managedTenants?: GraphCacheResolver<WithTypename<InternalUserModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    name?: GraphCacheResolver<WithTypename<InternalUserModel>, Record<string, never>, Scalars['String'] | string>,
    roles?: GraphCacheResolver<WithTypename<InternalUserModel>, Record<string, never>, Array<UserRoleEnum | string>>,
    tenants?: GraphCacheResolver<WithTypename<InternalUserModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>
  },
  KeyValuePairOfStringAndObject?: {
    key?: GraphCacheResolver<WithTypename<KeyValuePairOfStringAndObject>, Record<string, never>, Scalars['String'] | string>
  },
  KeyValuePairOfStringAndProductSelectionModel?: {
    key?: GraphCacheResolver<WithTypename<KeyValuePairOfStringAndProductSelectionModel>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<KeyValuePairOfStringAndProductSelectionModel>, Record<string, never>, WithTypename<ProductSelectionModel> | string>
  },
  KeyValuePairOfStringAndString?: {
    key?: GraphCacheResolver<WithTypename<KeyValuePairOfStringAndString>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<KeyValuePairOfStringAndString>, Record<string, never>, Scalars['String'] | string>
  },
  KeyValuePairOfStringAndString__?: {
    key?: GraphCacheResolver<WithTypename<KeyValuePairOfStringAndString__>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<KeyValuePairOfStringAndString__>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  MetadataKeyFilterConnection?: {
    edges?: GraphCacheResolver<WithTypename<MetadataKeyFilterConnection>, Record<string, never>, Array<WithTypename<MetadataKeyFilterOptionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<MetadataKeyFilterConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<MetadataKeyFilterConnection>, Record<string, never>, Scalars['Long'] | string>
  },
  MetadataKeyFilterOption?: {
    id?: GraphCacheResolver<WithTypename<MetadataKeyFilterOption>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<MetadataKeyFilterOption>, Record<string, never>, Scalars['String'] | string>,
    values?: GraphCacheResolver<WithTypename<MetadataKeyFilterOption>, MetadataKeyFilterOptionValuesArgs, WithTypename<MetadataValueFilterConnection> | string>
  },
  MetadataKeyFilterOptionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<MetadataKeyFilterOptionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<MetadataKeyFilterOptionEdge>, Record<string, never>, WithTypename<MetadataKeyFilterOption> | string>
  },
  MetadataValueFilterConnection?: {
    edges?: GraphCacheResolver<WithTypename<MetadataValueFilterConnection>, Record<string, never>, Array<WithTypename<MetadataValueFilterOptionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<MetadataValueFilterConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<MetadataValueFilterConnection>, Record<string, never>, Scalars['Long'] | string>
  },
  MetadataValueFilterOption?: {
    id?: GraphCacheResolver<WithTypename<MetadataValueFilterOption>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<MetadataValueFilterOption>, Record<string, never>, Scalars['String'] | string>,
    productCount?: GraphCacheResolver<WithTypename<MetadataValueFilterOption>, Record<string, never>, Scalars['Long'] | string>
  },
  MetadataValueFilterOptionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<MetadataValueFilterOptionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<MetadataValueFilterOptionEdge>, Record<string, never>, WithTypename<MetadataValueFilterOption> | string>
  },
  MsalConfigurationModel?: {
    clientId?: GraphCacheResolver<WithTypename<MsalConfigurationModel>, Record<string, never>, Scalars['String'] | string>,
    domain?: GraphCacheResolver<WithTypename<MsalConfigurationModel>, Record<string, never>, Scalars['String'] | string>,
    instance?: GraphCacheResolver<WithTypename<MsalConfigurationModel>, Record<string, never>, Scalars['String'] | string>,
    userJourney?: GraphCacheResolver<WithTypename<MsalConfigurationModel>, Record<string, never>, Scalars['String'] | string>,
    userJourneyUrl?: GraphCacheResolver<WithTypename<MsalConfigurationModel>, Record<string, never>, Scalars['String'] | string>
  },
  MyOrderListModel?: {
    filteredTotal?: GraphCacheResolver<WithTypename<MyOrderListModel>, Record<string, never>, Scalars['Int'] | string>,
    myOrders?: GraphCacheResolver<WithTypename<MyOrderListModel>, MyOrderListModelMyOrdersArgs, Array<WithTypename<OrderModel> | string>>
  },
  OrderItemModel?: {
    basketHashKey?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['String'] | string>,
    completedAt?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['DateTime'] | string>,
    contentKitId?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['UUID'] | string>,
    cost?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['Int'] | string>,
    downloadUrl?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['String'] | string>,
    failedAt?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['DateTime'] | string>,
    failureReason?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['String'] | string>,
    fileInfo?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, WithTypename<FileInfo> | string>,
    generatorRunId?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['String'] | string>,
    height?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['UUID'] | string>,
    isDeleted?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['Boolean'] | string>,
    mainProductId?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['UUID'] | string>,
    outputVariant?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['String'] | string>,
    premiumOrdering?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, WithTypename<PremiumOrderingModel> | string>,
    preview?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, WithTypename<PreviewModel> | string>,
    processingStartedAt?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['DateTime'] | string>,
    productSelections?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndProductSelectionModel> | string>>,
    products?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Array<WithTypename<SimplifiedProductModel> | string>>,
    retryCount?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['Int'] | string>,
    runId?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, OrderItemStatus | string>,
    template?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, WithTypename<TemplateModel> | string>,
    templateVersion?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['Int'] | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['String'] | string>,
    userInputs?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString> | string>>,
    width?: GraphCacheResolver<WithTypename<OrderItemModel>, Record<string, never>, Scalars['Int'] | string>
  },
  OrderModel?: {
    budget?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, WithTypename<BudgetModel> | string>,
    createdAt?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['UUID'] | string>,
    isDeleted?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['Boolean'] | string>,
    isProcessed?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['Boolean'] | string>,
    orderCompleted?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['DateTime'] | string>,
    orderItems?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Array<WithTypename<OrderItemModel> | string>>,
    orderPartial?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['DateTime'] | string>,
    orderRendering?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['DateTime'] | string>,
    orderedBy?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, OrderStatus | string>,
    totalCost?: GraphCacheResolver<WithTypename<OrderModel>, Record<string, never>, Scalars['Int'] | string>
  },
  OrderSuggestion?: {
    id?: GraphCacheResolver<WithTypename<OrderSuggestion>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<OrderSuggestion>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<OrderSuggestion>, Record<string, never>, OrderSearchEntityType | string>
  },
  OutputCombinationConnection?: {
    edges?: GraphCacheResolver<WithTypename<OutputCombinationConnection>, Record<string, never>, Array<WithTypename<OutputCombinationEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<OutputCombinationConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<OutputCombinationConnection>, Record<string, never>, Scalars['Long'] | string>
  },
  OutputCombinationEdge?: {
    cursor?: GraphCacheResolver<WithTypename<OutputCombinationEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<OutputCombinationEdge>, Record<string, never>, WithTypename<OutputCombinationModel> | string>
  },
  OutputCombinationGroupListModel?: {
    id?: GraphCacheResolver<WithTypename<OutputCombinationGroupListModel>, Record<string, never>, Scalars['String'] | string>,
    items?: GraphCacheResolver<WithTypename<OutputCombinationGroupListModel>, OutputCombinationGroupListModelItemsArgs, Array<WithTypename<ContentKitOutputCombinationGroupModel> | WithTypename<ProductOutputCombinationGroupModel> | string>>,
    searchId?: GraphCacheResolver<WithTypename<OutputCombinationGroupListModel>, Record<string, never>, Scalars['String'] | string>,
    total?: GraphCacheResolver<WithTypename<OutputCombinationGroupListModel>, Record<string, never>, Scalars['Long'] | string>
  },
  OutputCombinationGroupingInfoModel?: {
    completedCount?: GraphCacheResolver<WithTypename<OutputCombinationGroupingInfoModel>, Record<string, never>, Scalars['Long'] | string>,
    contentKitId?: GraphCacheResolver<WithTypename<OutputCombinationGroupingInfoModel>, Record<string, never>, Scalars['UUID'] | string>,
    inProgressCount?: GraphCacheResolver<WithTypename<OutputCombinationGroupingInfoModel>, Record<string, never>, Scalars['Long'] | string>,
    productId?: GraphCacheResolver<WithTypename<OutputCombinationGroupingInfoModel>, Record<string, never>, Scalars['UUID'] | string>,
    templateId?: GraphCacheResolver<WithTypename<OutputCombinationGroupingInfoModel>, Record<string, never>, Scalars['UUID'] | string>,
    totalCount?: GraphCacheResolver<WithTypename<OutputCombinationGroupingInfoModel>, Record<string, never>, Scalars['Long'] | string>
  },
  OutputCombinationListModel?: {
    items?: GraphCacheResolver<WithTypename<OutputCombinationListModel>, OutputCombinationListModelItemsArgs, Array<WithTypename<OutputCombinationModel> | string>>,
    total?: GraphCacheResolver<WithTypename<OutputCombinationListModel>, Record<string, never>, Scalars['Long'] | string>
  },
  OutputCombinationModel?: {
    configuration?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, WithTypename<ConfigurationModel> | string>,
    contentKit?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, WithTypename<ContentKitModel> | string>,
    downloadUrl?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, Scalars['String'] | string>,
    groupingInfo?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, WithTypename<OutputCombinationGroupingInfoModel> | string>,
    hashKey?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, Scalars['String'] | string>,
    isDownloadable?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, Scalars['Boolean'] | string>,
    isSaved?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, Scalars['Boolean'] | string>,
    mainProductId?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, Scalars['UUID'] | string>,
    orderInfo?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, WithTypename<OutputCombinationOrderInfo> | string>,
    products?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, Array<WithTypename<ProductModel> | string>>,
    status?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, OutputCombinationStatus | string>,
    template?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, WithTypename<TemplateModel> | string>,
    thumbnail?: GraphCacheResolver<WithTypename<OutputCombinationModel>, Record<string, never>, WithTypename<PreviewModel> | string>
  },
  OutputCombinationOrderInfo?: {
    createdBy?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, Scalars['UUID'] | string>,
    downloadUrl?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, Scalars['String'] | string>,
    fileInfo?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, WithTypename<FileInfo> | string>,
    orderId?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, Scalars['UUID'] | string>,
    orderItemId?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, Scalars['UUID'] | string>,
    orderedAt?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, Scalars['DateTime'] | string>,
    preview?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, WithTypename<PreviewModel> | string>,
    status?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, OrderItemStatus | string>,
    templateVersion?: GraphCacheResolver<WithTypename<OutputCombinationOrderInfo>, Record<string, never>, Scalars['Int'] | string>
  },
  PageInfo?: {
    endCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['String'] | string>,
    hasNextPage?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    startCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['String'] | string>
  },
  PremiumOrderingModel?: {
    enabled?: GraphCacheResolver<WithTypename<PremiumOrderingModel>, Record<string, never>, Scalars['Boolean'] | string>,
    multiplier?: GraphCacheResolver<WithTypename<PremiumOrderingModel>, Record<string, never>, Scalars['Int'] | string>
  },
  PreviewModel?: {
    failureReason?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Scalars['String'] | string>,
    height?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Scalars['UUID'] | string>,
    mainProductId?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Scalars['UUID'] | string>,
    outputFullType?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, TemplateOutputType | string>,
    productSelections?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndProductSelectionModel> | string>>,
    products?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    runId?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Scalars['Float'] | string>,
    status?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, PreviewStatus | string>,
    template?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, WithTypename<TemplateModel> | string>,
    url?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Scalars['String'] | string>,
    width?: GraphCacheResolver<WithTypename<PreviewModel>, Record<string, never>, Scalars['Int'] | string>
  },
  PreviewStatsModel?: {
    completed?: GraphCacheResolver<WithTypename<PreviewStatsModel>, Record<string, never>, Scalars['Long'] | string>,
    failed?: GraphCacheResolver<WithTypename<PreviewStatsModel>, Record<string, never>, Scalars['Long'] | string>,
    processing?: GraphCacheResolver<WithTypename<PreviewStatsModel>, Record<string, never>, Scalars['Long'] | string>,
    total?: GraphCacheResolver<WithTypename<PreviewStatsModel>, Record<string, never>, Scalars['Long'] | string>
  },
  PreviewsListModel?: {
    filteredTotal?: GraphCacheResolver<WithTypename<PreviewsListModel>, Record<string, never>, Scalars['Long'] | string>,
    previews?: GraphCacheResolver<WithTypename<PreviewsListModel>, PreviewsListModelPreviewsArgs, Array<WithTypename<PreviewModel> | string>>
  },
  ProductCompatibilityOptions?: {
    additionalImageTypes?: GraphCacheResolver<WithTypename<ProductCompatibilityOptions>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    artworkSetCompatibilityOptions?: GraphCacheResolver<WithTypename<ProductCompatibilityOptions>, Record<string, never>, WithTypename<ArtworkSetCompatibilityOptions> | string>,
    brands?: GraphCacheResolver<WithTypename<ProductCompatibilityOptions>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    shapeCollectionCompatibilityOptions?: GraphCacheResolver<WithTypename<ProductCompatibilityOptions>, Record<string, never>, WithTypename<ShapeCollectionCompatibilityOptions> | string>
  },
  ProductCompatibilityResult?: {
    failureReason?: GraphCacheResolver<WithTypename<ProductCompatibilityResult>, Record<string, never>, Scalars['String'] | string>,
    isCompatible?: GraphCacheResolver<WithTypename<ProductCompatibilityResult>, Record<string, never>, Scalars['Boolean'] | string>,
    productId?: GraphCacheResolver<WithTypename<ProductCompatibilityResult>, Record<string, never>, Scalars['UUID'] | string>
  },
  ProductFilter?: {
    additionalImageTypeIds?: GraphCacheResolver<WithTypename<ProductFilter>, Record<string, never>, Array<Scalars['UUID'] | string>>,
    artworkSetFilter?: GraphCacheResolver<WithTypename<ProductFilter>, Record<string, never>, WithTypename<ArtworkSetFilter> | string>,
    brandIds?: GraphCacheResolver<WithTypename<ProductFilter>, Record<string, never>, Array<Scalars['UUID'] | string>>,
    shapeCollectionFilter?: GraphCacheResolver<WithTypename<ProductFilter>, Record<string, never>, WithTypename<ShapeCollectionFilter> | string>,
    skuList?: GraphCacheResolver<WithTypename<ProductFilter>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  ProductFilterConnection?: {
    edges?: GraphCacheResolver<WithTypename<ProductFilterConnection>, Record<string, never>, Array<WithTypename<ProductFilterOptionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<ProductFilterConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<ProductFilterConnection>, Record<string, never>, Scalars['Long'] | string>
  },
  ProductFilterOption?: {
    id?: GraphCacheResolver<WithTypename<ProductFilterOption>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ProductFilterOption>, Record<string, never>, Scalars['String'] | string>,
    product?: GraphCacheResolver<WithTypename<ProductFilterOption>, Record<string, never>, WithTypename<ProductModel> | string>,
    productCount?: GraphCacheResolver<WithTypename<ProductFilterOption>, Record<string, never>, Scalars['Long'] | string>
  },
  ProductFilterOptionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<ProductFilterOptionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<ProductFilterOptionEdge>, Record<string, never>, WithTypename<ProductFilterOption> | string>
  },
  ProductFilterOptionItem?: {
    globalId?: GraphCacheResolver<WithTypename<ProductFilterOptionItem>, Record<string, never>, WithTypename<ProductGlobalId> | string>,
    id?: GraphCacheResolver<WithTypename<ProductFilterOptionItem>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ProductFilterOptionItem>, Record<string, never>, Scalars['String'] | string>,
    product?: GraphCacheResolver<WithTypename<ProductFilterOptionItem>, Record<string, never>, WithTypename<ProductModel> | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<ProductFilterOptionItem>, Record<string, never>, Scalars['String'] | string>
  },
  ProductGlobalId?: {
    id?: GraphCacheResolver<WithTypename<ProductGlobalId>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ProductGlobalId>, Record<string, never>, ProductIdType | string>
  },
  ProductGroupFilterConnection?: {
    edges?: GraphCacheResolver<WithTypename<ProductGroupFilterConnection>, Record<string, never>, Array<WithTypename<ProductGroupFilterOptionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<ProductGroupFilterConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<ProductGroupFilterConnection>, Record<string, never>, Scalars['Long'] | string>
  },
  ProductGroupFilterOption?: {
    id?: GraphCacheResolver<WithTypename<ProductGroupFilterOption>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ProductGroupFilterOption>, Record<string, never>, Scalars['String'] | string>,
    values?: GraphCacheResolver<WithTypename<ProductGroupFilterOption>, ProductGroupFilterOptionValuesArgs, WithTypename<ProductFilterConnection> | string>
  },
  ProductGroupFilterOptionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<ProductGroupFilterOptionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<ProductGroupFilterOptionEdge>, Record<string, never>, WithTypename<ProductGroupFilterOption> | string>
  },
  ProductGroupModel?: {
    id?: GraphCacheResolver<WithTypename<ProductGroupModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ProductGroupModel>, Record<string, never>, Scalars['String'] | string>,
    products?: GraphCacheResolver<WithTypename<ProductGroupModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    users?: GraphCacheResolver<WithTypename<ProductGroupModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>
  },
  ProductListModel?: {
    filteredTotal?: GraphCacheResolver<WithTypename<ProductListModel>, Record<string, never>, Scalars['Int'] | string>,
    products?: GraphCacheResolver<WithTypename<ProductListModel>, ProductListModelProductsArgs, Array<WithTypename<ProductModel> | string>>
  },
  ProductModel?: {
    additionalImages?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Array<WithTypename<AdditionalImageModel> | string>>,
    artworkSets?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Array<WithTypename<ArtworkSetModel> | string>>,
    brand?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, WithTypename<BrandModel> | string>,
    globalId?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, WithTypename<ProductGlobalId> | string>,
    id?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Scalars['UUID'] | string>,
    lastModifiedAt?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Scalars['DateTime'] | string>,
    metadata?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString> | string>>,
    name?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Scalars['String'] | string>,
    previewStats?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, WithTypename<PreviewStatsModel> | string>,
    project?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, WithTypename<ProjectModel> | string>,
    shapeCollection?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, WithTypename<ShapeCollectionModel> | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Scalars['String'] | string>,
    variant?: GraphCacheResolver<WithTypename<ProductModel>, Record<string, never>, Scalars['String'] | string>
  },
  ProductOrderSuggestion?: {
    globalId?: GraphCacheResolver<WithTypename<ProductOrderSuggestion>, Record<string, never>, WithTypename<ProductGlobalId> | string>,
    id?: GraphCacheResolver<WithTypename<ProductOrderSuggestion>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ProductOrderSuggestion>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ProductOrderSuggestion>, Record<string, never>, OrderSearchEntityType | string>
  },
  ProductOutputCombinationGroupModel?: {
    brand?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, WithTypename<BrandModel> | string>,
    globalId?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, WithTypename<ProductGlobalId> | string>,
    id?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, Scalars['String'] | string>,
    mainEntityId?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, Scalars['String'] | string>,
    outputCombinations?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, WithTypename<OutputCombinationListModel> | string>,
    product?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, WithTypename<ProductModel> | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, Scalars['String'] | string>,
    variant?: GraphCacheResolver<WithTypename<ProductOutputCombinationGroupModel>, Record<string, never>, Scalars['String'] | string>
  },
  ProductSearchFilters?: {
    brands?: GraphCacheResolver<WithTypename<ProductSearchFilters>, Record<string, never>, WithTypename<FindByIdFilter> | string>,
    products?: GraphCacheResolver<WithTypename<ProductSearchFilters>, Record<string, never>, WithTypename<FindByIdFilter> | string>
  },
  ProductSelectionModel?: {
    artworkId?: GraphCacheResolver<WithTypename<ProductSelectionModel>, Record<string, never>, Scalars['UUID'] | string>,
    entityId?: GraphCacheResolver<WithTypename<ProductSelectionModel>, Record<string, never>, Scalars['UUID'] | string>,
    entityType?: GraphCacheResolver<WithTypename<ProductSelectionModel>, Record<string, never>, EntityType | string>,
    productId?: GraphCacheResolver<WithTypename<ProductSelectionModel>, Record<string, never>, Scalars['UUID'] | string>,
    shapeCollectionId?: GraphCacheResolver<WithTypename<ProductSelectionModel>, Record<string, never>, Scalars['UUID'] | string>
  },
  ProductSuggestion?: {
    globalId?: GraphCacheResolver<WithTypename<ProductSuggestion>, Record<string, never>, WithTypename<ProductGlobalId> | string>,
    id?: GraphCacheResolver<WithTypename<ProductSuggestion>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ProductSuggestion>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ProductSuggestion>, Record<string, never>, SearchEntityType | string>
  },
  ProjectModel?: {
    id?: GraphCacheResolver<WithTypename<ProjectModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<ProjectModel>, Record<string, never>, Scalars['String'] | string>
  },
  RangeFilter?: {
    max?: GraphCacheResolver<WithTypename<RangeFilter>, Record<string, never>, Scalars['Int'] | string>,
    min?: GraphCacheResolver<WithTypename<RangeFilter>, Record<string, never>, Scalars['Int'] | string>
  },
  ReportRowModel?: {
    autoTopUp?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Int'] | string>,
    budget?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, WithTypename<BudgetModel> | string>,
    creditValue?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Decimal'] | string>,
    creditsAdded?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Int'] | string>,
    creditsExceeded?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Int'] | string>,
    creditsRollover?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Int'] | string>,
    creditsUsed?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Int'] | string>,
    from?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['UUID'] | string>,
    period?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Int'] | string>,
    reportType?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, ReportType | string>,
    salesForceIds?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['String'] | string>,
    tenant?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, WithTypename<TenantModel> | string>,
    to?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['DateTime'] | string>,
    totalCreditsValue?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Decimal'] | string>,
    year?: GraphCacheResolver<WithTypename<ReportRowModel>, Record<string, never>, Scalars['Int'] | string>
  },
  SalesForceReferenceModel?: {
    createdAt?: GraphCacheResolver<WithTypename<SalesForceReferenceModel>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<SalesForceReferenceModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<SalesForceReferenceModel>, Record<string, never>, Scalars['UUID'] | string>,
    isActive?: GraphCacheResolver<WithTypename<SalesForceReferenceModel>, Record<string, never>, Scalars['Boolean'] | string>,
    salesForceId?: GraphCacheResolver<WithTypename<SalesForceReferenceModel>, Record<string, never>, Scalars['String'] | string>
  },
  SceneInfoModel?: {
    id?: GraphCacheResolver<WithTypename<SceneInfoModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<SceneInfoModel>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<SceneInfoModel>, Record<string, never>, SceneType | string>
  },
  SceneModel?: {
    description?: GraphCacheResolver<WithTypename<SceneModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<SceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<SceneModel>, Record<string, never>, Scalars['String'] | string>,
    tenants?: GraphCacheResolver<WithTypename<SceneModel>, Record<string, never>, Array<WithTypename<TenantModel> | string>>,
    type?: GraphCacheResolver<WithTypename<SceneModel>, Record<string, never>, SceneType | string>
  },
  SearchContext?: {
    contentKitFilters?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, WithTypename<ContentKitSearchFilters> | string>,
    groupSortBy?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, SortBy | string>,
    groupSortDirection?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, SortDirection | string>,
    layout?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, SearchResultLayout | string>,
    productFilters?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, WithTypename<ProductSearchFilters> | string>,
    sortBy?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, SortBy | string>,
    sortDirection?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, SortDirection | string>,
    statusFilter?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, WithTypename<SearchStatusFilterFlags> | string>,
    textPhrase?: GraphCacheResolver<WithTypename<SearchContext>, Record<string, never>, Scalars['String'] | string>
  },
  SearchFilterOptions?: {
    brands?: GraphCacheResolver<WithTypename<SearchFilterOptions>, SearchFilterOptionsBrandsArgs, WithTypename<FilterOptions> | string>,
    contentKits?: GraphCacheResolver<WithTypename<SearchFilterOptions>, SearchFilterOptionsContentKitsArgs, WithTypename<FilterOptions> | string>,
    genres?: GraphCacheResolver<WithTypename<SearchFilterOptions>, SearchFilterOptionsGenresArgs, WithTypename<FilterOptions> | string>,
    products?: GraphCacheResolver<WithTypename<SearchFilterOptions>, SearchFilterOptionsProductsArgs, WithTypename<FilterOptions> | string>
  },
  SearchResultSummary?: {
    contentKitCount?: GraphCacheResolver<WithTypename<SearchResultSummary>, Record<string, never>, Scalars['Int'] | string>,
    previewCount?: GraphCacheResolver<WithTypename<SearchResultSummary>, Record<string, never>, Scalars['Long'] | string>,
    productCount?: GraphCacheResolver<WithTypename<SearchResultSummary>, Record<string, never>, Scalars['Int'] | string>
  },
  SearchStatusFilterFlags?: {
    isAll?: GraphCacheResolver<WithTypename<SearchStatusFilterFlags>, Record<string, never>, Scalars['Boolean'] | string>,
    isDownloadable?: GraphCacheResolver<WithTypename<SearchStatusFilterFlags>, Record<string, never>, Scalars['Boolean'] | string>,
    isInProgress?: GraphCacheResolver<WithTypename<SearchStatusFilterFlags>, Record<string, never>, Scalars['Boolean'] | string>,
    isPreview?: GraphCacheResolver<WithTypename<SearchStatusFilterFlags>, Record<string, never>, Scalars['Boolean'] | string>,
    isSaved?: GraphCacheResolver<WithTypename<SearchStatusFilterFlags>, Record<string, never>, Scalars['Boolean'] | string>
  },
  ShapeCollectionCompatibilityOptions?: {
    baseMeasurements?: GraphCacheResolver<WithTypename<ShapeCollectionCompatibilityOptions>, Record<string, never>, Array<BaseMeasurement | string>>,
    scenes?: GraphCacheResolver<WithTypename<ShapeCollectionCompatibilityOptions>, Record<string, never>, Array<WithTypename<SceneInfoModel> | string>>,
    shapeCollectionTypes?: GraphCacheResolver<WithTypename<ShapeCollectionCompatibilityOptions>, Record<string, never>, Array<ShapeCollectionType | string>>,
    shapeTypes?: GraphCacheResolver<WithTypename<ShapeCollectionCompatibilityOptions>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  ShapeCollectionFilter?: {
    dimensionFilter?: GraphCacheResolver<WithTypename<ShapeCollectionFilter>, Record<string, never>, WithTypename<DimensionFilter> | string>,
    measurementFilter?: GraphCacheResolver<WithTypename<ShapeCollectionFilter>, Record<string, never>, WithTypename<BaseMeasurementFilter> | string>,
    shapeCollectionIds?: GraphCacheResolver<WithTypename<ShapeCollectionFilter>, Record<string, never>, Array<Scalars['UUID'] | string>>,
    shapeCollectionTypes?: GraphCacheResolver<WithTypename<ShapeCollectionFilter>, Record<string, never>, Array<ShapeCollectionType | string>>,
    shapeCollectionTypesOperator?: GraphCacheResolver<WithTypename<ShapeCollectionFilter>, Record<string, never>, OperatorHelper | string>,
    shapeTypesOperator?: GraphCacheResolver<WithTypename<ShapeCollectionFilter>, Record<string, never>, OperatorHelper | string>,
    shapes?: GraphCacheResolver<WithTypename<ShapeCollectionFilter>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  ShapeCollectionFilterConnection?: {
    edges?: GraphCacheResolver<WithTypename<ShapeCollectionFilterConnection>, Record<string, never>, Array<WithTypename<ShapeCollectionFilterOptionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<ShapeCollectionFilterConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<ShapeCollectionFilterConnection>, Record<string, never>, Scalars['Long'] | string>
  },
  ShapeCollectionFilterOption?: {
    id?: GraphCacheResolver<WithTypename<ShapeCollectionFilterOption>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ShapeCollectionFilterOption>, Record<string, never>, Scalars['String'] | string>,
    productCount?: GraphCacheResolver<WithTypename<ShapeCollectionFilterOption>, Record<string, never>, Scalars['Long'] | string>,
    values?: GraphCacheResolver<WithTypename<ShapeCollectionFilterOption>, ShapeCollectionFilterOptionValuesArgs, WithTypename<ShapeFilterConnection> | string>
  },
  ShapeCollectionFilterOptionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<ShapeCollectionFilterOptionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<ShapeCollectionFilterOptionEdge>, Record<string, never>, WithTypename<ShapeCollectionFilterOption> | string>
  },
  ShapeCollectionImageSceneModel?: {
    hdriFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    json?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['String'] | string>,
    jsonFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['String'] | string>,
    lutFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['String'] | string>,
    prerenderedImageFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['String'] | string>,
    scene?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, WithTypename<SceneInfoModel> | string>,
    shapeCollectionId?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    shapeViewerData?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, WithTypename<ShapeViewerDataModel> | string>,
    uvFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionImageSceneModel>, Record<string, never>, Scalars['String'] | string>
  },
  ShapeCollectionListModel?: {
    filteredTotal?: GraphCacheResolver<WithTypename<ShapeCollectionListModel>, Record<string, never>, Scalars['Int'] | string>,
    shapeCollections?: GraphCacheResolver<WithTypename<ShapeCollectionListModel>, ShapeCollectionListModelShapeCollectionsArgs, Array<WithTypename<ShapeCollectionModel> | string>>
  },
  ShapeCollectionModel?: {
    artworkMapping?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, WithTypename<ArtworkMapping> | string>,
    dimensions?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, WithTypename<Dimensions> | string>,
    id?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, Scalars['UUID'] | string>,
    linkedShapeCollection?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, WithTypename<ShapeCollectionModel> | string>,
    name?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, Scalars['String'] | string>,
    shapeCollectionScenes?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, Array<WithTypename<CompleteShapeCollectionSceneModel> | string>>,
    shapes?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, Array<WithTypename<ShapeModel> | string>>,
    type?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, ShapeCollectionType | string>,
    unitSize?: GraphCacheResolver<WithTypename<ShapeCollectionModel>, Record<string, never>, WithTypename<UnitSize> | string>
  },
  ShapeCollectionUVRemapSceneModel?: {
    id?: GraphCacheResolver<WithTypename<ShapeCollectionUvRemapSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    jsonFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionUvRemapSceneModel>, Record<string, never>, Scalars['String'] | string>,
    lutFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionUvRemapSceneModel>, Record<string, never>, Scalars['String'] | string>,
    scene?: GraphCacheResolver<WithTypename<ShapeCollectionUvRemapSceneModel>, Record<string, never>, WithTypename<SceneInfoModel> | string>,
    shapeCollectionId?: GraphCacheResolver<WithTypename<ShapeCollectionUvRemapSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    videoUvFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionUvRemapSceneModel>, Record<string, never>, Scalars['String'] | string>
  },
  ShapeCollectionVideoSceneModel?: {
    id?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    json?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, Scalars['String'] | string>,
    jsonFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, Scalars['String'] | string>,
    lutFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, Scalars['String'] | string>,
    prerenderedVideoFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, Scalars['String'] | string>,
    scene?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, WithTypename<SceneInfoModel> | string>,
    shapeCollectionId?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, Scalars['UUID'] | string>,
    shapeViewerData?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, WithTypename<ShapeViewerDataModel> | string>,
    videoUvFileUrl?: GraphCacheResolver<WithTypename<ShapeCollectionVideoSceneModel>, Record<string, never>, Scalars['String'] | string>
  },
  ShapeFilterConnection?: {
    edges?: GraphCacheResolver<WithTypename<ShapeFilterConnection>, Record<string, never>, Array<WithTypename<ShapeFilterOptionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<ShapeFilterConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<ShapeFilterConnection>, Record<string, never>, Scalars['Long'] | string>
  },
  ShapeFilterOption?: {
    id?: GraphCacheResolver<WithTypename<ShapeFilterOption>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ShapeFilterOption>, Record<string, never>, Scalars['String'] | string>,
    productCount?: GraphCacheResolver<WithTypename<ShapeFilterOption>, Record<string, never>, Scalars['Long'] | string>
  },
  ShapeFilterOptionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<ShapeFilterOptionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<ShapeFilterOptionEdge>, Record<string, never>, WithTypename<ShapeFilterOption> | string>
  },
  ShapeModel?: {
    id?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, Scalars['UUID'] | string>,
    lastModifiedAt?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, Scalars['DateTime'] | string>,
    name?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, Scalars['String'] | string>,
    nasId?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, Scalars['String'] | string>,
    thumbnail?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, ShapeType | string>,
    validatedBy?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, WithTypename<Validator> | string>,
    viewerData?: GraphCacheResolver<WithTypename<ShapeModel>, Record<string, never>, WithTypename<ShapeViewerDataModel> | string>
  },
  ShapeViewerDataModel?: {
    imageSequence?: GraphCacheResolver<WithTypename<ShapeViewerDataModel>, Record<string, never>, Array<Scalars['String'] | string>>,
    json?: GraphCacheResolver<WithTypename<ShapeViewerDataModel>, Record<string, never>, Scalars['String'] | string>,
    lut?: GraphCacheResolver<WithTypename<ShapeViewerDataModel>, Record<string, never>, Scalars['String'] | string>,
    mp4?: GraphCacheResolver<WithTypename<ShapeViewerDataModel>, Record<string, never>, Scalars['String'] | string>,
    uvMappings?: GraphCacheResolver<WithTypename<ShapeViewerDataModel>, Record<string, never>, Array<Scalars['String'] | string>>,
    webM?: GraphCacheResolver<WithTypename<ShapeViewerDataModel>, Record<string, never>, Scalars['String'] | string>
  },
  SimplifiedArtworkSetModel?: {
    artworks?: GraphCacheResolver<WithTypename<SimplifiedArtworkSetModel>, Record<string, never>, Array<WithTypename<ArtworkModel> | string>>,
    id?: GraphCacheResolver<WithTypename<SimplifiedArtworkSetModel>, Record<string, never>, Scalars['UUID'] | string>,
    locale?: GraphCacheResolver<WithTypename<SimplifiedArtworkSetModel>, Record<string, never>, Array<Scalars['String'] | string>>,
    name?: GraphCacheResolver<WithTypename<SimplifiedArtworkSetModel>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<SimplifiedArtworkSetModel>, Record<string, never>, ArtworkType | string>
  },
  SimplifiedProductModel?: {
    additionalImages?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, Array<WithTypename<AdditionalImageModel> | string>>,
    artworkSets?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, Array<WithTypename<SimplifiedArtworkSetModel> | string>>,
    brand?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, WithTypename<BrandModel> | string>,
    globalId?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, WithTypename<ProductGlobalId> | string>,
    id?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, Scalars['String'] | string>,
    productGroups?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, Array<WithTypename<ProductGroupModel> | string>>,
    shapeCollection?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, WithTypename<SimplifiedShapeCollectionModel> | string>,
    variant?: GraphCacheResolver<WithTypename<SimplifiedProductModel>, Record<string, never>, Scalars['String'] | string>
  },
  SimplifiedShapeCollectionModel?: {
    dimensions?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, WithTypename<Dimensions> | string>,
    id?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, Scalars['UUID'] | string>,
    linkedShapeCollection?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, WithTypename<SimplifiedShapeCollectionModel> | string>,
    name?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, Scalars['String'] | string>,
    shapeCollectionScenes?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, Array<WithTypename<CompleteShapeCollectionSceneModel> | string>>,
    shapes?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, Array<WithTypename<ShapeModel> | string>>,
    type?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, ShapeCollectionType | string>,
    unitSize?: GraphCacheResolver<WithTypename<SimplifiedShapeCollectionModel>, Record<string, never>, WithTypename<UnitSize> | string>
  },
  StashItemModelOfEditorDetailsModel?: {
    key?: GraphCacheResolver<WithTypename<StashItemModelOfEditorDetailsModel>, Record<string, never>, Scalars['String'] | string>,
    stashData?: GraphCacheResolver<WithTypename<StashItemModelOfEditorDetailsModel>, Record<string, never>, WithTypename<EditorDetailsModel> | string>,
    tenantId?: GraphCacheResolver<WithTypename<StashItemModelOfEditorDetailsModel>, Record<string, never>, Scalars['UUID'] | string>
  },
  TemplateAITextSuggestion?: {
    editablePromptPart?: GraphCacheResolver<WithTypename<TemplateAiTextSuggestion>, Record<string, never>, Scalars['String'] | string>,
    isTextAiSuggestionEnabled?: GraphCacheResolver<WithTypename<TemplateAiTextSuggestion>, Record<string, never>, Scalars['Boolean'] | string>
  },
  TemplateClickDragInteraction?: {
    loop?: GraphCacheResolver<WithTypename<TemplateClickDragInteraction>, Record<string, never>, Scalars['Boolean'] | string>,
    type?: GraphCacheResolver<WithTypename<TemplateClickDragInteraction>, Record<string, never>, TemplateClickDragInteractionType | string>
  },
  TemplateFileResource?: {
    resourceRef?: GraphCacheResolver<WithTypename<TemplateFileResource>, Record<string, never>, Scalars['String'] | string>
  },
  TemplateInputModel?: {
    category?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['String'] | string>,
    clickDragInteraction?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, WithTypename<TemplateClickDragInteraction> | string>,
    colorPalette?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Array<Scalars['String'] | string>>,
    compatibility?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, WithTypename<ProductFilter> | string>,
    customValueOption?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, WithTypename<TemplateInputOptions> | string>,
    default?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['String'] | string>,
    maxFontSize?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['Int'] | string>,
    maxLength?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['Int'] | string>,
    minFontSize?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['Int'] | string>,
    name?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['String'] | string>,
    nodeId?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['String'] | string>,
    options?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Array<WithTypename<TemplateInputOptions> | string>>,
    order?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Scalars['Int'] | string>,
    range?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, WithTypename<TemplateInputRange> | string>,
    type?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, TemplateInputType | string>,
    values?: GraphCacheResolver<WithTypename<TemplateInputModel>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  TemplateInputOptions?: {
    color?: GraphCacheResolver<WithTypename<TemplateInputOptions>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<TemplateInputOptions>, Record<string, never>, Scalars['String'] | string>,
    image?: GraphCacheResolver<WithTypename<TemplateInputOptions>, Record<string, never>, WithTypename<TemplateFileResource> | string>,
    name?: GraphCacheResolver<WithTypename<TemplateInputOptions>, Record<string, never>, Scalars['String'] | string>,
    preview?: GraphCacheResolver<WithTypename<TemplateInputOptions>, Record<string, never>, WithTypename<TemplateFileResource> | string>,
    value?: GraphCacheResolver<WithTypename<TemplateInputOptions>, Record<string, never>, Scalars['String'] | string>
  },
  TemplateInputRange?: {
    max?: GraphCacheResolver<WithTypename<TemplateInputRange>, Record<string, never>, Scalars['Float'] | string>,
    min?: GraphCacheResolver<WithTypename<TemplateInputRange>, Record<string, never>, Scalars['Float'] | string>,
    step?: GraphCacheResolver<WithTypename<TemplateInputRange>, Record<string, never>, Scalars['Float'] | string>
  },
  TemplateListModel?: {
    filteredTotal?: GraphCacheResolver<WithTypename<TemplateListModel>, Record<string, never>, Scalars['Int'] | string>,
    templates?: GraphCacheResolver<WithTypename<TemplateListModel>, TemplateListModelTemplatesArgs, Array<WithTypename<TemplateModel> | string>>
  },
  TemplateMetadata?: {
    mainProductInputName?: GraphCacheResolver<WithTypename<TemplateMetadata>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<TemplateMetadata>, Record<string, never>, Scalars['String'] | string>,
    outputs?: GraphCacheResolver<WithTypename<TemplateMetadata>, Record<string, never>, Array<WithTypename<TemplateOutput> | string>>
  },
  TemplateModel?: {
    aiTextSuggestion?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, WithTypename<TemplateAiTextSuggestion> | string>,
    id?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Scalars['UUID'] | string>,
    isPerformanceCoachEnabled?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Scalars['Boolean'] | string>,
    linkedContentKits?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    metadata?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, WithTypename<TemplateMetadata> | string>,
    modifiedAt?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Scalars['DateTime'] | string>,
    name?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Scalars['String'] | string>,
    nodeGraph?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Scalars['String'] | string>,
    previewStats?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, WithTypename<PreviewStatsModel> | string>,
    previews?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Array<WithTypename<PreviewModel> | string>>,
    rootTemplate?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, WithTypename<TemplateModel> | string>,
    templateStatus?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, TemplateStatus | string>,
    textTranslation?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, WithTypename<TemplateTextTranslation> | string>,
    thumbnailUrl?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<TemplateModel>, Record<string, never>, Scalars['Int'] | string>
  },
  TemplateOutput?: {
    code?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['String'] | string>,
    cost?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['Int'] | string>,
    fullType?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, TemplateOutputType | string>,
    height?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['Int'] | string>,
    inputs?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Array<WithTypename<TemplateInputModel> | string>>,
    name?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['String'] | string>,
    nodeId?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['String'] | string>,
    previewType?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, TemplateOutputType | string>,
    segments?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Array<WithTypename<TemplateSegment> | string>>,
    shouldAutoApplyUserChanges?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    skipFrames?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['Int'] | string>,
    type?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, TemplateOutputType | string>,
    types?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Array<TemplateOutputType | string>>,
    videoLength?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['Float'] | string>,
    width?: GraphCacheResolver<WithTypename<TemplateOutput>, Record<string, never>, Scalars['Int'] | string>
  },
  TemplateSegment?: {
    inputs?: GraphCacheResolver<WithTypename<TemplateSegment>, Record<string, never>, Array<Scalars['String'] | string>>,
    name?: GraphCacheResolver<WithTypename<TemplateSegment>, Record<string, never>, Scalars['String'] | string>,
    preview?: GraphCacheResolver<WithTypename<TemplateSegment>, Record<string, never>, WithTypename<TemplateSegmentPreview> | string>,
    videoLength?: GraphCacheResolver<WithTypename<TemplateSegment>, Record<string, never>, Scalars['Float'] | string>
  },
  TemplateSegmentPreview?: {
    resourceRef?: GraphCacheResolver<WithTypename<TemplateSegmentPreview>, Record<string, never>, Scalars['String'] | string>
  },
  TemplateTextTranslation?: {
    isTextTranslationEnabled?: GraphCacheResolver<WithTypename<TemplateTextTranslation>, Record<string, never>, Scalars['Boolean'] | string>,
    languageList?: GraphCacheResolver<WithTypename<TemplateTextTranslation>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  TenantModel?: {
    domain?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Scalars['String'] | string>,
    domains?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Array<Scalars['String'] | string>>,
    id?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Scalars['UUID'] | string>,
    logoUrl?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Scalars['String'] | string>,
    noMatchesImageUrl?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Scalars['String'] | string>,
    projectManagers?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    userJourney?: GraphCacheResolver<WithTypename<TenantModel>, Record<string, never>, Scalars['String'] | string>
  },
  TransactionModel?: {
    budget?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, WithTypename<BudgetModel> | string>,
    createdAt?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, Scalars['DateTime'] | string>,
    credits?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, Scalars['Int'] | string>,
    description?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, Scalars['String'] | string>,
    orderItem?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, WithTypename<OrderItemModel> | string>,
    tenant?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, WithTypename<TenantModel> | string>,
    transactionType?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, TransactionType | string>,
    user?: GraphCacheResolver<WithTypename<TransactionModel>, Record<string, never>, WithTypename<UserModel> | string>
  },
  TransactionReportModel?: {
    totalNumber?: GraphCacheResolver<WithTypename<TransactionReportModel>, Record<string, never>, Scalars['Int'] | string>,
    transactions?: GraphCacheResolver<WithTypename<TransactionReportModel>, TransactionReportModelTransactionsArgs, Array<WithTypename<TransactionModel> | string>>
  },
  UnitSize?: {
    content?: GraphCacheResolver<WithTypename<UnitSize>, Record<string, never>, Scalars['Int'] | string>,
    measurement?: GraphCacheResolver<WithTypename<UnitSize>, Record<string, never>, BaseMeasurement | string>,
    quantity?: GraphCacheResolver<WithTypename<UnitSize>, Record<string, never>, Scalars['Int'] | string>,
    unitType?: GraphCacheResolver<WithTypename<UnitSize>, Record<string, never>, UnitType | string>
  },
  UserJourneyModel?: {
    userJourney?: GraphCacheResolver<WithTypename<UserJourneyModel>, Record<string, never>, Scalars['String'] | string>,
    userJourneyUrl?: GraphCacheResolver<WithTypename<UserJourneyModel>, Record<string, never>, Scalars['String'] | string>
  },
  UserModel?: {
    email?: GraphCacheResolver<WithTypename<UserModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<UserModel>, Record<string, never>, Scalars['UUID'] | string>,
    loggedInBefore?: GraphCacheResolver<WithTypename<UserModel>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<UserModel>, Record<string, never>, Scalars['String'] | string>,
    roles?: GraphCacheResolver<WithTypename<UserModel>, Record<string, never>, Array<UserRoleEnum | string>>
  },
  UserOrderSuggestion?: {
    id?: GraphCacheResolver<WithTypename<UserOrderSuggestion>, Record<string, never>, Scalars['UUID'] | string>,
    name?: GraphCacheResolver<WithTypename<UserOrderSuggestion>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserOrderSuggestion>, Record<string, never>, OrderSearchEntityType | string>
  },
  UserProfileModel?: {
    hasProductAccess?: GraphCacheResolver<WithTypename<UserProfileModel>, Record<string, never>, Scalars['Boolean'] | string>,
    recentlyUsedContentKits?: GraphCacheResolver<WithTypename<UserProfileModel>, Record<string, never>, Array<Scalars['UUID'] | string>>,
    recentlyUsedProducts?: GraphCacheResolver<WithTypename<UserProfileModel>, Record<string, never>, Array<Scalars['UUID'] | string>>,
    welcomeMessageShown?: GraphCacheResolver<WithTypename<UserProfileModel>, Record<string, never>, Scalars['Boolean'] | string>
  },
  UserProvisioningTableItemModel?: {
    budgets?: GraphCacheResolver<WithTypename<UserProvisioningTableItemModel>, Record<string, never>, Array<WithTypename<BudgetModel> | string>>,
    email?: GraphCacheResolver<WithTypename<UserProvisioningTableItemModel>, Record<string, never>, Scalars['String'] | string>,
    processed?: GraphCacheResolver<WithTypename<UserProvisioningTableItemModel>, Record<string, never>, Scalars['Boolean'] | string>,
    processedAt?: GraphCacheResolver<WithTypename<UserProvisioningTableItemModel>, Record<string, never>, Scalars['DateTime'] | string>,
    productGroups?: GraphCacheResolver<WithTypename<UserProvisioningTableItemModel>, Record<string, never>, Array<WithTypename<ProductGroupModel> | string>>,
    user?: GraphCacheResolver<WithTypename<UserProvisioningTableItemModel>, Record<string, never>, WithTypename<UserModel> | string>
  },
  UserProvisioningTableModel?: {
    createdAt?: GraphCacheResolver<WithTypename<UserProvisioningTableModel>, Record<string, never>, Scalars['DateTime'] | string>,
    createdBy?: GraphCacheResolver<WithTypename<UserProvisioningTableModel>, Record<string, never>, WithTypename<UserModel> | string>,
    id?: GraphCacheResolver<WithTypename<UserProvisioningTableModel>, Record<string, never>, Scalars['UUID'] | string>,
    items?: GraphCacheResolver<WithTypename<UserProvisioningTableModel>, Record<string, never>, Array<WithTypename<UserProvisioningTableItemModel> | string>>,
    lastModifiedAt?: GraphCacheResolver<WithTypename<UserProvisioningTableModel>, Record<string, never>, Scalars['DateTime'] | string>,
    name?: GraphCacheResolver<WithTypename<UserProvisioningTableModel>, Record<string, never>, Scalars['String'] | string>
  },
  UserWithBudgetsModel?: {
    assignedBudgets?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    email?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Scalars['UUID'] | string>,
    loggedInBefore?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Scalars['String'] | string>,
    ownedBudgets?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    productGroups?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Array<WithTypename<InfoModel> | string>>,
    roles?: GraphCacheResolver<WithTypename<UserWithBudgetsModel>, Record<string, never>, Array<UserRoleEnum | string>>
  },
  ValidationErrorModel?: {
    errorCode?: GraphCacheResolver<WithTypename<ValidationErrorModel>, Record<string, never>, Scalars['String'] | string>,
    errorMessage?: GraphCacheResolver<WithTypename<ValidationErrorModel>, Record<string, never>, Scalars['String'] | string>,
    propertyName?: GraphCacheResolver<WithTypename<ValidationErrorModel>, Record<string, never>, Scalars['String'] | string>
  },
  ValidationFailure?: {
    errorCode?: GraphCacheResolver<WithTypename<ValidationFailure>, Record<string, never>, Scalars['String'] | string>,
    errorMessage?: GraphCacheResolver<WithTypename<ValidationFailure>, Record<string, never>, Scalars['String'] | string>,
    formattedMessagePlaceholderValues?: GraphCacheResolver<WithTypename<ValidationFailure>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndObject> | string>>,
    propertyName?: GraphCacheResolver<WithTypename<ValidationFailure>, Record<string, never>, Scalars['String'] | string>,
    severity?: GraphCacheResolver<WithTypename<ValidationFailure>, Record<string, never>, Severity | string>
  },
  ValidationResult?: {
    errors?: GraphCacheResolver<WithTypename<ValidationResult>, Record<string, never>, Array<WithTypename<ValidationFailure> | string>>,
    isValid?: GraphCacheResolver<WithTypename<ValidationResult>, Record<string, never>, Scalars['Boolean'] | string>,
    ruleSetsExecuted?: GraphCacheResolver<WithTypename<ValidationResult>, Record<string, never>, Array<Scalars['String'] | string>>,
    toDictionary?: GraphCacheResolver<WithTypename<ValidationResult>, Record<string, never>, Array<WithTypename<KeyValuePairOfStringAndString__> | string>>
  },
  ValidationResultModel?: {
    errors?: GraphCacheResolver<WithTypename<ValidationResultModel>, Record<string, never>, Array<WithTypename<ValidationErrorModel> | string>>,
    isValid?: GraphCacheResolver<WithTypename<ValidationResultModel>, Record<string, never>, Scalars['Boolean'] | string>
  },
  Validator?: {
    description?: GraphCacheResolver<WithTypename<Validator>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Validator>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<Validator>, Record<string, never>, ValidatorType | string>,
    userId?: GraphCacheResolver<WithTypename<Validator>, Record<string, never>, Scalars['UUID'] | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  addAdditionalImage?: GraphCacheOptimisticMutationResolver<MutationAddAdditionalImageArgs, WithTypename<AdditionalImageModel>>,
  addAdditionalImageType?: GraphCacheOptimisticMutationResolver<MutationAddAdditionalImageTypeArgs, WithTypename<AdditionalImageTypeModel>>,
  addArtworkSet?: GraphCacheOptimisticMutationResolver<MutationAddArtworkSetArgs, WithTypename<ArtworkSetModel>>,
  addArtworks?: GraphCacheOptimisticMutationResolver<MutationAddArtworksArgs, Scalars['Boolean']>,
  addBrand?: GraphCacheOptimisticMutationResolver<MutationAddBrandArgs, WithTypename<BrandModel>>,
  addBundleToBasket?: GraphCacheOptimisticMutationResolver<MutationAddBundleToBasketArgs, WithTypename<BasketModel>>,
  addContentKit?: GraphCacheOptimisticMutationResolver<MutationAddContentKitArgs, WithTypename<ContentKitModel>>,
  addCredits?: GraphCacheOptimisticMutationResolver<MutationAddCreditsArgs, Scalars['Boolean']>,
  addDraftProduct?: GraphCacheOptimisticMutationResolver<MutationAddDraftProductArgs, WithTypename<DraftProductModel>>,
  addGenre?: GraphCacheOptimisticMutationResolver<MutationAddGenreArgs, WithTypename<GenreModel>>,
  addItemToBasket?: GraphCacheOptimisticMutationResolver<MutationAddItemToBasketArgs, WithTypename<BasketModel>>,
  addPayPerUseBudget?: GraphCacheOptimisticMutationResolver<MutationAddPayPerUseBudgetArgs, WithTypename<BudgetModel>>,
  addProduct?: GraphCacheOptimisticMutationResolver<MutationAddProductArgs, WithTypename<ProductModel>>,
  addProductGroup?: GraphCacheOptimisticMutationResolver<MutationAddProductGroupArgs, WithTypename<ProductGroupModel>>,
  addProject?: GraphCacheOptimisticMutationResolver<MutationAddProjectArgs, WithTypename<ProjectModel>>,
  addQaUser?: GraphCacheOptimisticMutationResolver<MutationAddQaUserArgs, Scalars['Boolean']>,
  addSalesForceId?: GraphCacheOptimisticMutationResolver<MutationAddSalesForceIdArgs, Scalars['Boolean']>,
  addScene?: GraphCacheOptimisticMutationResolver<MutationAddSceneArgs, WithTypename<SceneModel>>,
  addShapeCollection?: GraphCacheOptimisticMutationResolver<MutationAddShapeCollectionArgs, WithTypename<ShapeCollectionModel>>,
  addShapeCollectionImageScene?: GraphCacheOptimisticMutationResolver<MutationAddShapeCollectionImageSceneArgs, WithTypename<ShapeCollectionImageSceneModel>>,
  addShapeCollectionImageSceneByShapeId?: GraphCacheOptimisticMutationResolver<MutationAddShapeCollectionImageSceneByShapeIdArgs, WithTypename<ShapeCollectionImageSceneModel>>,
  addShapeCollectionUVRemapScene?: GraphCacheOptimisticMutationResolver<MutationAddShapeCollectionUvRemapSceneArgs, WithTypename<ShapeCollectionUvRemapSceneModel>>,
  addShapeCollectionUVRemapSceneByShapeId?: GraphCacheOptimisticMutationResolver<MutationAddShapeCollectionUvRemapSceneByShapeIdArgs, WithTypename<ShapeCollectionUvRemapSceneModel>>,
  addShapeCollectionVideoScene?: GraphCacheOptimisticMutationResolver<MutationAddShapeCollectionVideoSceneArgs, WithTypename<ShapeCollectionVideoSceneModel>>,
  addShapeCollectionVideoSceneByShapeId?: GraphCacheOptimisticMutationResolver<MutationAddShapeCollectionVideoSceneByShapeIdArgs, WithTypename<ShapeCollectionVideoSceneModel>>,
  addSubscriptionBudget?: GraphCacheOptimisticMutationResolver<MutationAddSubscriptionBudgetArgs, WithTypename<BudgetModel>>,
  addTemplate?: GraphCacheOptimisticMutationResolver<MutationAddTemplateArgs, WithTypename<TemplateModel>>,
  addTenant?: GraphCacheOptimisticMutationResolver<MutationAddTenantArgs, WithTypename<TenantModel>>,
  addUserProvisioningTable?: GraphCacheOptimisticMutationResolver<MutationAddUserProvisioningTableArgs, WithTypename<AddUserProvisioningTableFailed> | WithTypename<AddUserProvisioningTableSucceeded>>,
  archiveTemplate?: GraphCacheOptimisticMutationResolver<MutationArchiveTemplateArgs, WithTypename<TemplateModel>>,
  assignBudgetsForUser?: GraphCacheOptimisticMutationResolver<MutationAssignBudgetsForUserArgs, Scalars['Boolean']>,
  assignProductGroups?: GraphCacheOptimisticMutationResolver<MutationAssignProductGroupsArgs, WithTypename<UserWithBudgetsModel>>,
  assignUserRole?: GraphCacheOptimisticMutationResolver<MutationAssignUserRoleArgs, Scalars['Boolean']>,
  assignUsersForBudget?: GraphCacheOptimisticMutationResolver<MutationAssignUsersForBudgetArgs, Scalars['Boolean']>,
  batchDownload?: GraphCacheOptimisticMutationResolver<MutationBatchDownloadArgs, WithTypename<DownloadModel>>,
  changeBudgetName?: GraphCacheOptimisticMutationResolver<MutationChangeBudgetNameArgs, Scalars['Boolean']>,
  changeBudgetOwner?: GraphCacheOptimisticMutationResolver<MutationChangeBudgetOwnerArgs, Scalars['Boolean']>,
  changeTenantView?: GraphCacheOptimisticMutationResolver<MutationChangeTenantViewArgs, WithTypename<CurrentUserModel>>,
  copyTemplate?: GraphCacheOptimisticMutationResolver<MutationCopyTemplateArgs, WithTypename<TemplateModel>>,
  deleteBrand?: GraphCacheOptimisticMutationResolver<MutationDeleteBrandArgs, Scalars['Boolean']>,
  deleteBudget?: GraphCacheOptimisticMutationResolver<MutationDeleteBudgetArgs, Scalars['Boolean']>,
  deleteContentKit?: GraphCacheOptimisticMutationResolver<MutationDeleteContentKitArgs, Scalars['Boolean']>,
  deleteGenre?: GraphCacheOptimisticMutationResolver<MutationDeleteGenreArgs, Scalars['Boolean']>,
  deleteOrder?: GraphCacheOptimisticMutationResolver<MutationDeleteOrderArgs, WithTypename<OrderModel>>,
  deleteOrderItem?: GraphCacheOptimisticMutationResolver<MutationDeleteOrderItemArgs, WithTypename<OrderItemModel>>,
  exportAs?: GraphCacheOptimisticMutationResolver<MutationExportAsArgs, WithTypename<ExportAsModel>>,
  extendSubscription?: GraphCacheOptimisticMutationResolver<MutationExtendSubscriptionArgs, Scalars['Boolean']>,
  fixOrderItem?: GraphCacheOptimisticMutationResolver<MutationFixOrderItemArgs, Scalars['Boolean']>,
  inviteUser?: GraphCacheOptimisticMutationResolver<MutationInviteUserArgs, Scalars['Boolean']>,
  makeDraftProductLive?: GraphCacheOptimisticMutationResolver<MutationMakeDraftProductLiveArgs, Scalars['Boolean']>,
  newUserNotification?: GraphCacheOptimisticMutationResolver<MutationNewUserNotificationArgs, Scalars['Boolean']>,
  notifyOfFailedViewer?: GraphCacheOptimisticMutationResolver<MutationNotifyOfFailedViewerArgs, Scalars['Boolean']>,
  order?: GraphCacheOptimisticMutationResolver<MutationOrderArgs, WithTypename<OrderModel>>,
  orderPremium?: GraphCacheOptimisticMutationResolver<MutationOrderPremiumArgs, WithTypename<OrderModel>>,
  publishTemplate?: GraphCacheOptimisticMutationResolver<MutationPublishTemplateArgs, WithTypename<TemplateModel>>,
  reTriggerOrderItem?: GraphCacheOptimisticMutationResolver<MutationReTriggerOrderItemArgs, Scalars['Boolean']>,
  refirePreviewRequest?: GraphCacheOptimisticMutationResolver<MutationRefirePreviewRequestArgs, Scalars['Boolean']>,
  refreshArtwork?: GraphCacheOptimisticMutationResolver<MutationRefreshArtworkArgs, WithTypename<ArtworkModel>>,
  refreshShape?: GraphCacheOptimisticMutationResolver<MutationRefreshShapeArgs, WithTypename<ShapeModel>>,
  removeAdditionalImage?: GraphCacheOptimisticMutationResolver<MutationRemoveAdditionalImageArgs, Scalars['Boolean']>,
  removeAdditionalImageType?: GraphCacheOptimisticMutationResolver<MutationRemoveAdditionalImageTypeArgs, Scalars['Boolean']>,
  removeArtworkSet?: GraphCacheOptimisticMutationResolver<MutationRemoveArtworkSetArgs, Scalars['Boolean']>,
  removeArtworks?: GraphCacheOptimisticMutationResolver<MutationRemoveArtworksArgs, Scalars['Boolean']>,
  removeDraftProduct?: GraphCacheOptimisticMutationResolver<MutationRemoveDraftProductArgs, Scalars['Boolean']>,
  removeEditorCombination?: GraphCacheOptimisticMutationResolver<MutationRemoveEditorCombinationArgs, Scalars['Boolean']>,
  removeItemFromBasket?: GraphCacheOptimisticMutationResolver<MutationRemoveItemFromBasketArgs, WithTypename<BasketModel>>,
  removePreview?: GraphCacheOptimisticMutationResolver<MutationRemovePreviewArgs, Scalars['Boolean']>,
  removeProduct?: GraphCacheOptimisticMutationResolver<MutationRemoveProductArgs, Scalars['Boolean']>,
  removeProductGroup?: GraphCacheOptimisticMutationResolver<MutationRemoveProductGroupArgs, Scalars['Boolean']>,
  removeProject?: GraphCacheOptimisticMutationResolver<MutationRemoveProjectArgs, Scalars['Boolean']>,
  removeScene?: GraphCacheOptimisticMutationResolver<MutationRemoveSceneArgs, Scalars['Boolean']>,
  removeShapeCollection?: GraphCacheOptimisticMutationResolver<MutationRemoveShapeCollectionArgs, Scalars['Boolean']>,
  removeShapeCollectionScene?: GraphCacheOptimisticMutationResolver<MutationRemoveShapeCollectionSceneArgs, Scalars['Boolean']>,
  removeTemplate?: GraphCacheOptimisticMutationResolver<MutationRemoveTemplateArgs, Scalars['Boolean']>,
  removeTenant?: GraphCacheOptimisticMutationResolver<MutationRemoveTenantArgs, Scalars['Boolean']>,
  removeUserProvisioningTable?: GraphCacheOptimisticMutationResolver<MutationRemoveUserProvisioningTableArgs, Scalars['Boolean']>,
  requestAccess?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['Boolean']>,
  requestPreviews?: GraphCacheOptimisticMutationResolver<MutationRequestPreviewsArgs, Scalars['String']>,
  requestPreviewsByProductId?: GraphCacheOptimisticMutationResolver<MutationRequestPreviewsByProductIdArgs, Scalars['String']>,
  resetUser?: GraphCacheOptimisticMutationResolver<MutationResetUserArgs, Scalars['Boolean']>,
  resetWelcomeMessageShown?: GraphCacheOptimisticMutationResolver<MutationResetWelcomeMessageShownArgs, Scalars['Boolean']>,
  resumeBudget?: GraphCacheOptimisticMutationResolver<MutationResumeBudgetArgs, Scalars['Boolean']>,
  revokeUserPermissions?: GraphCacheOptimisticMutationResolver<MutationRevokeUserPermissionsArgs, Scalars['Boolean']>,
  saveEditorCombination?: GraphCacheOptimisticMutationResolver<MutationSaveEditorCombinationArgs, Scalars['Boolean']>,
  setDefaultProduct?: GraphCacheOptimisticMutationResolver<MutationSetDefaultProductArgs, Scalars['Boolean']>,
  setTenantProjectManagers?: GraphCacheOptimisticMutationResolver<MutationSetTenantProjectManagersArgs, Scalars['Boolean']>,
  setUserAsAdmin?: GraphCacheOptimisticMutationResolver<MutationSetUserAsAdminArgs, Scalars['Boolean']>,
  setUserAsDefaultUser?: GraphCacheOptimisticMutationResolver<MutationSetUserAsDefaultUserArgs, Scalars['Boolean']>,
  setUserAsProjectManager?: GraphCacheOptimisticMutationResolver<MutationSetUserAsProjectManagerArgs, Scalars['Boolean']>,
  stashEditorDetailsInput?: GraphCacheOptimisticMutationResolver<MutationStashEditorDetailsInputArgs, Scalars['String']>,
  suspendBudget?: GraphCacheOptimisticMutationResolver<MutationSuspendBudgetArgs, Scalars['Boolean']>,
  toggleSalesForceId?: GraphCacheOptimisticMutationResolver<MutationToggleSalesForceIdArgs, Scalars['Boolean']>,
  updateAdditionalImageType?: GraphCacheOptimisticMutationResolver<MutationUpdateAdditionalImageTypeArgs, WithTypename<AdditionalImageTypeModel>>,
  updateAdvancedFiltersSettings?: GraphCacheOptimisticMutationResolver<MutationUpdateAdvancedFiltersSettingsArgs, WithTypename<AdvancedFiltersSettings>>,
  updateArtworkSet?: GraphCacheOptimisticMutationResolver<MutationUpdateArtworkSetArgs, WithTypename<ArtworkSetModel>>,
  updateBrand?: GraphCacheOptimisticMutationResolver<MutationUpdateBrandArgs, WithTypename<BrandModel>>,
  updateBudgetPremiumOrdering?: GraphCacheOptimisticMutationResolver<MutationUpdateBudgetPremiumOrderingArgs, Scalars['Boolean']>,
  updateContentKit?: GraphCacheOptimisticMutationResolver<MutationUpdateContentKitArgs, WithTypename<ContentKitModel>>,
  updateDefaultPreviews?: GraphCacheOptimisticMutationResolver<MutationUpdateDefaultPreviewsArgs, Scalars['Boolean']>,
  updateDraftProduct?: GraphCacheOptimisticMutationResolver<MutationUpdateDraftProductArgs, WithTypename<DraftProductModel>>,
  updateGenre?: GraphCacheOptimisticMutationResolver<MutationUpdateGenreArgs, WithTypename<GenreModel>>,
  updateProduct?: GraphCacheOptimisticMutationResolver<MutationUpdateProductArgs, WithTypename<ProductModel>>,
  updateProductGroup?: GraphCacheOptimisticMutationResolver<MutationUpdateProductGroupArgs, WithTypename<ProductGroupModel>>,
  updateProject?: GraphCacheOptimisticMutationResolver<MutationUpdateProjectArgs, WithTypename<ProjectModel>>,
  updateScene?: GraphCacheOptimisticMutationResolver<MutationUpdateSceneArgs, WithTypename<SceneModel>>,
  updateShapeCollection?: GraphCacheOptimisticMutationResolver<MutationUpdateShapeCollectionArgs, WithTypename<ShapeCollectionModel>>,
  updateShapeCollectionImageScene?: GraphCacheOptimisticMutationResolver<MutationUpdateShapeCollectionImageSceneArgs, WithTypename<ShapeCollectionImageSceneModel>>,
  updateShapeCollectionUVRemapScene?: GraphCacheOptimisticMutationResolver<MutationUpdateShapeCollectionUvRemapSceneArgs, WithTypename<ShapeCollectionUvRemapSceneModel>>,
  updateShapeCollectionVideoScene?: GraphCacheOptimisticMutationResolver<MutationUpdateShapeCollectionVideoSceneArgs, WithTypename<ShapeCollectionVideoSceneModel>>,
  updateTemplate?: GraphCacheOptimisticMutationResolver<MutationUpdateTemplateArgs, WithTypename<TemplateModel>>,
  updateTenant?: GraphCacheOptimisticMutationResolver<MutationUpdateTenantArgs, WithTypename<TenantModel>>,
  updateUserProvisioningTable?: GraphCacheOptimisticMutationResolver<MutationUpdateUserProvisioningTableArgs, WithTypename<UserProvisioningTableModel>>,
  uploadShapeImageSequence?: GraphCacheOptimisticMutationResolver<MutationUploadShapeImageSequenceArgs, Scalars['Boolean']>,
  validateDraftProduct?: GraphCacheOptimisticMutationResolver<MutationValidateDraftProductArgs, WithTypename<ValidationResultModel>>,
  welcomeMessageShown?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['Boolean']>
};

export type GraphCacheUpdaters = {
  Query?: {
    additionalImageTypeById?: GraphCacheUpdateResolver<{ additionalImageTypeById: WithTypename<AdditionalImageTypeModel> }, QueryAdditionalImageTypeByIdArgs>,
    additionalImageTypes?: GraphCacheUpdateResolver<{ additionalImageTypes: Array<WithTypename<AdditionalImageTypeModel>> }, Record<string, never>>,
    additionalImagesByProductId?: GraphCacheUpdateResolver<{ additionalImagesByProductId: Array<WithTypename<AdditionalImageModel>> }, QueryAdditionalImagesByProductIdArgs>,
    adminBudgets?: GraphCacheUpdateResolver<{ adminBudgets: WithTypename<BudgetListModel> }, QueryAdminBudgetsArgs>,
    advancedFiltersSettings?: GraphCacheUpdateResolver<{ advancedFiltersSettings: WithTypename<AdvancedFiltersSettings> }, Record<string, never>>,
    advancedProductFilterOptions?: GraphCacheUpdateResolver<{ advancedProductFilterOptions: WithTypename<AdvancedProductFilterOptions> }, QueryAdvancedProductFilterOptionsArgs>,
    allOutputCombinationsByFilters?: GraphCacheUpdateResolver<{ allOutputCombinationsByFilters: WithTypename<OutputCombinationConnection> }, QueryAllOutputCombinationsByFiltersArgs>,
    areProductsCompatible?: GraphCacheUpdateResolver<{ areProductsCompatible: Array<WithTypename<ProductCompatibilityResult>> }, QueryAreProductsCompatibleArgs>,
    artworkSetById?: GraphCacheUpdateResolver<{ artworkSetById: WithTypename<ArtworkSetModel> }, QueryArtworkSetByIdArgs>,
    artworkSets?: GraphCacheUpdateResolver<{ artworkSets: WithTypename<ArtworkSetListModel> }, QueryArtworkSetsArgs>,
    brandById?: GraphCacheUpdateResolver<{ brandById: WithTypename<BrandModel> }, QueryBrandByIdArgs>,
    brandByName?: GraphCacheUpdateResolver<{ brandByName: WithTypename<BrandModel> }, QueryBrandByNameArgs>,
    brands?: GraphCacheUpdateResolver<{ brands: Array<WithTypename<BrandModel>> }, Record<string, never>>,
    budgetById?: GraphCacheUpdateResolver<{ budgetById: WithTypename<BudgetModel> }, QueryBudgetByIdArgs>,
    budgets?: GraphCacheUpdateResolver<{ budgets: WithTypename<BudgetListModel> }, QueryBudgetsArgs>,
    contentKitById?: GraphCacheUpdateResolver<{ contentKitById: WithTypename<ContentKitModel> }, QueryContentKitByIdArgs>,
    contentKits?: GraphCacheUpdateResolver<{ contentKits: WithTypename<ContentKitsModel> }, QueryContentKitsArgs>,
    courierToken?: GraphCacheUpdateResolver<{ courierToken: Scalars['String'] }, Record<string, never>>,
    currentBasket?: GraphCacheUpdateResolver<{ currentBasket: WithTypename<BasketModel> }, Record<string, never>>,
    currentUser?: GraphCacheUpdateResolver<{ currentUser: WithTypename<CurrentUserModel> }, Record<string, never>>,
    draftProductById?: GraphCacheUpdateResolver<{ draftProductById: WithTypename<DraftProductModel> }, QueryDraftProductByIdArgs>,
    draftProducts?: GraphCacheUpdateResolver<{ draftProducts: Array<WithTypename<DraftProductModel>> }, Record<string, never>>,
    editorCombinationDetails?: GraphCacheUpdateResolver<{ editorCombinationDetails: WithTypename<EditorCombinationDetailsModel> }, QueryEditorCombinationDetailsArgs>,
    editorInitialValues?: GraphCacheUpdateResolver<{ editorInitialValues: WithTypename<EditorInitialValuesModel> }, QueryEditorInitialValuesArgs>,
    expandOutputCombinationGrouping?: GraphCacheUpdateResolver<{ expandOutputCombinationGrouping: Array<WithTypename<OutputCombinationModel>> }, QueryExpandOutputCombinationGroupingArgs>,
    filtersBySearchId?: GraphCacheUpdateResolver<{ filtersBySearchId: WithTypename<FiltersModel> }, QueryFiltersBySearchIdArgs>,
    financialReports?: GraphCacheUpdateResolver<{ financialReports: WithTypename<FinancialReportModel> }, QueryFinancialReportsArgs>,
    findCompatibleProducts?: GraphCacheUpdateResolver<{ findCompatibleProducts: WithTypename<CompatibleProductSearchResult> }, QueryFindCompatibleProductsArgs>,
    findOutputCombinationGroups?: GraphCacheUpdateResolver<{ findOutputCombinationGroups: WithTypename<OutputCombinationGroupListModel> }, QueryFindOutputCombinationGroupsArgs>,
    genreById?: GraphCacheUpdateResolver<{ genreById: WithTypename<GenreModel> }, QueryGenreByIdArgs>,
    genres?: GraphCacheUpdateResolver<{ genres: Array<WithTypename<GenreModel>> }, Record<string, never>>,
    internalUsers?: GraphCacheUpdateResolver<{ internalUsers: Array<WithTypename<InternalUserModel>> }, Record<string, never>>,
    isProductsCompatible?: GraphCacheUpdateResolver<{ isProductsCompatible: WithTypename<ProductCompatibilityResult> }, QueryIsProductsCompatibleArgs>,
    managedTenants?: GraphCacheUpdateResolver<{ managedTenants: Array<WithTypename<TenantModel>> }, Record<string, never>>,
    metadataFilterOptions?: GraphCacheUpdateResolver<{ metadataFilterOptions: WithTypename<MetadataKeyFilterOption> }, QueryMetadataFilterOptionsArgs>,
    msalConfiguration?: GraphCacheUpdateResolver<{ msalConfiguration: WithTypename<MsalConfigurationModel> }, QueryMsalConfigurationArgs>,
    myOrders?: GraphCacheUpdateResolver<{ myOrders: WithTypename<MyOrderListModel> }, QueryMyOrdersArgs>,
    order?: GraphCacheUpdateResolver<{ order: WithTypename<OrderModel> }, QueryOrderArgs>,
    orderQuickSearch?: GraphCacheUpdateResolver<{ orderQuickSearch: Array<WithTypename<BudgetOrderSuggestion> | WithTypename<OrderSuggestion> | WithTypename<ProductOrderSuggestion> | WithTypename<UserOrderSuggestion>> }, QueryOrderQuickSearchArgs>,
    outputCombinationById?: GraphCacheUpdateResolver<{ outputCombinationById: WithTypename<OutputCombinationModel> }, QueryOutputCombinationByIdArgs>,
    outputCombinationByOrderId?: GraphCacheUpdateResolver<{ outputCombinationByOrderId: Array<WithTypename<OutputCombinationModel>> }, QueryOutputCombinationByOrderIdArgs>,
    outputCombinationGroup?: GraphCacheUpdateResolver<{ outputCombinationGroup: WithTypename<ContentKitOutputCombinationGroupModel> | WithTypename<ProductOutputCombinationGroupModel> }, QueryOutputCombinationGroupArgs>,
    outputCombinationGroups?: GraphCacheUpdateResolver<{ outputCombinationGroups: WithTypename<OutputCombinationGroupListModel> }, QueryOutputCombinationGroupsArgs>,
    productById?: GraphCacheUpdateResolver<{ productById: WithTypename<ProductModel> }, QueryProductByIdArgs>,
    productCompatibilityOptions?: GraphCacheUpdateResolver<{ productCompatibilityOptions: WithTypename<ProductCompatibilityOptions> }, Record<string, never>>,
    productGroupById?: GraphCacheUpdateResolver<{ productGroupById: WithTypename<ProductGroupModel> }, QueryProductGroupByIdArgs>,
    productGroupFilterOptions?: GraphCacheUpdateResolver<{ productGroupFilterOptions: WithTypename<ProductGroupFilterOption> }, QueryProductGroupFilterOptionsArgs>,
    productGroupProductOptions?: GraphCacheUpdateResolver<{ productGroupProductOptions: WithTypename<ProductListModel> }, QueryProductGroupProductOptionsArgs>,
    productGroups?: GraphCacheUpdateResolver<{ productGroups: Array<WithTypename<ProductGroupModel>> }, Record<string, never>>,
    productPreviews?: GraphCacheUpdateResolver<{ productPreviews: WithTypename<PreviewsListModel> }, QueryProductPreviewsArgs>,
    products?: GraphCacheUpdateResolver<{ products: WithTypename<ProductListModel> }, QueryProductsArgs>,
    projectById?: GraphCacheUpdateResolver<{ projectById: WithTypename<ProjectModel> }, QueryProjectByIdArgs>,
    projects?: GraphCacheUpdateResolver<{ projects: Array<WithTypename<ProjectModel>> }, Record<string, never>>,
    quickSearch?: GraphCacheUpdateResolver<{ quickSearch: Array<WithTypename<ContentKitSuggestion> | WithTypename<ProductSuggestion>> }, QueryQuickSearchArgs>,
    sceneById?: GraphCacheUpdateResolver<{ sceneById: WithTypename<SceneModel> }, QuerySceneByIdArgs>,
    scenes?: GraphCacheUpdateResolver<{ scenes: Array<WithTypename<SceneModel>> }, Record<string, never>>,
    searchFilterOptions?: GraphCacheUpdateResolver<{ searchFilterOptions: WithTypename<SearchFilterOptions> }, QuerySearchFilterOptionsArgs>,
    searchIdByFilters?: GraphCacheUpdateResolver<{ searchIdByFilters: Scalars['String'] }, QuerySearchIdByFiltersArgs>,
    searchSummary?: GraphCacheUpdateResolver<{ searchSummary: WithTypename<SearchResultSummary> }, QuerySearchSummaryArgs>,
    shapeCollectionById?: GraphCacheUpdateResolver<{ shapeCollectionById: WithTypename<ShapeCollectionModel> }, QueryShapeCollectionByIdArgs>,
    shapeCollectionFilterOptions?: GraphCacheUpdateResolver<{ shapeCollectionFilterOptions: WithTypename<ShapeCollectionFilterOption> }, QueryShapeCollectionFilterOptionsArgs>,
    shapeCollectionSceneById?: GraphCacheUpdateResolver<{ shapeCollectionSceneById: WithTypename<ShapeCollectionImageSceneModel> | WithTypename<ShapeCollectionUvRemapSceneModel> | WithTypename<ShapeCollectionVideoSceneModel> }, QueryShapeCollectionSceneByIdArgs>,
    shapeCollectionScenesByShapeCollection?: GraphCacheUpdateResolver<{ shapeCollectionScenesByShapeCollection: Array<WithTypename<ShapeCollectionImageSceneModel> | WithTypename<ShapeCollectionUvRemapSceneModel> | WithTypename<ShapeCollectionVideoSceneModel>> }, QueryShapeCollectionScenesByShapeCollectionArgs>,
    shapeCollections?: GraphCacheUpdateResolver<{ shapeCollections: WithTypename<ShapeCollectionListModel> }, QueryShapeCollectionsArgs>,
    stashedEditorDetailsInput?: GraphCacheUpdateResolver<{ stashedEditorDetailsInput: WithTypename<StashItemModelOfEditorDetailsModel> }, QueryStashedEditorDetailsInputArgs>,
    templateById?: GraphCacheUpdateResolver<{ templateById: WithTypename<TemplateModel> }, QueryTemplateByIdArgs>,
    templatePreviews?: GraphCacheUpdateResolver<{ templatePreviews: WithTypename<PreviewsListModel> }, QueryTemplatePreviewsArgs>,
    templates?: GraphCacheUpdateResolver<{ templates: WithTypename<TemplateListModel> }, QueryTemplatesArgs>,
    tenant?: GraphCacheUpdateResolver<{ tenant: WithTypename<TenantModel> }, QueryTenantArgs>,
    tenantAdditionalImageTypes?: GraphCacheUpdateResolver<{ tenantAdditionalImageTypes: Array<WithTypename<AdditionalImageTypeModel>> }, Record<string, never>>,
    tenantScenes?: GraphCacheUpdateResolver<{ tenantScenes: Array<WithTypename<SceneModel>> }, Record<string, never>>,
    tenants?: GraphCacheUpdateResolver<{ tenants: Array<WithTypename<TenantModel>> }, Record<string, never>>,
    transactionReports?: GraphCacheUpdateResolver<{ transactionReports: WithTypename<TransactionReportModel> }, QueryTransactionReportsArgs>,
    userJourney?: GraphCacheUpdateResolver<{ userJourney: WithTypename<UserJourneyModel> }, QueryUserJourneyArgs>,
    userProfile?: GraphCacheUpdateResolver<{ userProfile: WithTypename<UserProfileModel> }, Record<string, never>>,
    userProvisioningTableById?: GraphCacheUpdateResolver<{ userProvisioningTableById: WithTypename<UserProvisioningTableModel> }, QueryUserProvisioningTableByIdArgs>,
    userProvisioningTables?: GraphCacheUpdateResolver<{ userProvisioningTables: Array<WithTypename<UserProvisioningTableModel>> }, Record<string, never>>,
    users?: GraphCacheUpdateResolver<{ users: Array<WithTypename<UserWithBudgetsModel>> }, Record<string, never>>
  },
  Mutation?: {
    addAdditionalImage?: GraphCacheUpdateResolver<{ addAdditionalImage: WithTypename<AdditionalImageModel> }, MutationAddAdditionalImageArgs>,
    addAdditionalImageType?: GraphCacheUpdateResolver<{ addAdditionalImageType: WithTypename<AdditionalImageTypeModel> }, MutationAddAdditionalImageTypeArgs>,
    addArtworkSet?: GraphCacheUpdateResolver<{ addArtworkSet: WithTypename<ArtworkSetModel> }, MutationAddArtworkSetArgs>,
    addArtworks?: GraphCacheUpdateResolver<{ addArtworks: Scalars['Boolean'] }, MutationAddArtworksArgs>,
    addBrand?: GraphCacheUpdateResolver<{ addBrand: WithTypename<BrandModel> }, MutationAddBrandArgs>,
    addBundleToBasket?: GraphCacheUpdateResolver<{ addBundleToBasket: WithTypename<BasketModel> }, MutationAddBundleToBasketArgs>,
    addContentKit?: GraphCacheUpdateResolver<{ addContentKit: WithTypename<ContentKitModel> }, MutationAddContentKitArgs>,
    addCredits?: GraphCacheUpdateResolver<{ addCredits: Scalars['Boolean'] }, MutationAddCreditsArgs>,
    addDraftProduct?: GraphCacheUpdateResolver<{ addDraftProduct: WithTypename<DraftProductModel> }, MutationAddDraftProductArgs>,
    addGenre?: GraphCacheUpdateResolver<{ addGenre: WithTypename<GenreModel> }, MutationAddGenreArgs>,
    addItemToBasket?: GraphCacheUpdateResolver<{ addItemToBasket: WithTypename<BasketModel> }, MutationAddItemToBasketArgs>,
    addPayPerUseBudget?: GraphCacheUpdateResolver<{ addPayPerUseBudget: WithTypename<BudgetModel> }, MutationAddPayPerUseBudgetArgs>,
    addProduct?: GraphCacheUpdateResolver<{ addProduct: WithTypename<ProductModel> }, MutationAddProductArgs>,
    addProductGroup?: GraphCacheUpdateResolver<{ addProductGroup: WithTypename<ProductGroupModel> }, MutationAddProductGroupArgs>,
    addProject?: GraphCacheUpdateResolver<{ addProject: WithTypename<ProjectModel> }, MutationAddProjectArgs>,
    addQaUser?: GraphCacheUpdateResolver<{ addQaUser: Scalars['Boolean'] }, MutationAddQaUserArgs>,
    addSalesForceId?: GraphCacheUpdateResolver<{ addSalesForceId: Scalars['Boolean'] }, MutationAddSalesForceIdArgs>,
    addScene?: GraphCacheUpdateResolver<{ addScene: WithTypename<SceneModel> }, MutationAddSceneArgs>,
    addShapeCollection?: GraphCacheUpdateResolver<{ addShapeCollection: WithTypename<ShapeCollectionModel> }, MutationAddShapeCollectionArgs>,
    addShapeCollectionImageScene?: GraphCacheUpdateResolver<{ addShapeCollectionImageScene: WithTypename<ShapeCollectionImageSceneModel> }, MutationAddShapeCollectionImageSceneArgs>,
    addShapeCollectionImageSceneByShapeId?: GraphCacheUpdateResolver<{ addShapeCollectionImageSceneByShapeId: WithTypename<ShapeCollectionImageSceneModel> }, MutationAddShapeCollectionImageSceneByShapeIdArgs>,
    addShapeCollectionUVRemapScene?: GraphCacheUpdateResolver<{ addShapeCollectionUVRemapScene: WithTypename<ShapeCollectionUvRemapSceneModel> }, MutationAddShapeCollectionUvRemapSceneArgs>,
    addShapeCollectionUVRemapSceneByShapeId?: GraphCacheUpdateResolver<{ addShapeCollectionUVRemapSceneByShapeId: WithTypename<ShapeCollectionUvRemapSceneModel> }, MutationAddShapeCollectionUvRemapSceneByShapeIdArgs>,
    addShapeCollectionVideoScene?: GraphCacheUpdateResolver<{ addShapeCollectionVideoScene: WithTypename<ShapeCollectionVideoSceneModel> }, MutationAddShapeCollectionVideoSceneArgs>,
    addShapeCollectionVideoSceneByShapeId?: GraphCacheUpdateResolver<{ addShapeCollectionVideoSceneByShapeId: WithTypename<ShapeCollectionVideoSceneModel> }, MutationAddShapeCollectionVideoSceneByShapeIdArgs>,
    addSubscriptionBudget?: GraphCacheUpdateResolver<{ addSubscriptionBudget: WithTypename<BudgetModel> }, MutationAddSubscriptionBudgetArgs>,
    addTemplate?: GraphCacheUpdateResolver<{ addTemplate: WithTypename<TemplateModel> }, MutationAddTemplateArgs>,
    addTenant?: GraphCacheUpdateResolver<{ addTenant: WithTypename<TenantModel> }, MutationAddTenantArgs>,
    addUserProvisioningTable?: GraphCacheUpdateResolver<{ addUserProvisioningTable: WithTypename<AddUserProvisioningTableFailed> | WithTypename<AddUserProvisioningTableSucceeded> }, MutationAddUserProvisioningTableArgs>,
    archiveTemplate?: GraphCacheUpdateResolver<{ archiveTemplate: WithTypename<TemplateModel> }, MutationArchiveTemplateArgs>,
    assignBudgetsForUser?: GraphCacheUpdateResolver<{ assignBudgetsForUser: Scalars['Boolean'] }, MutationAssignBudgetsForUserArgs>,
    assignProductGroups?: GraphCacheUpdateResolver<{ assignProductGroups: WithTypename<UserWithBudgetsModel> }, MutationAssignProductGroupsArgs>,
    assignUserRole?: GraphCacheUpdateResolver<{ assignUserRole: Scalars['Boolean'] }, MutationAssignUserRoleArgs>,
    assignUsersForBudget?: GraphCacheUpdateResolver<{ assignUsersForBudget: Scalars['Boolean'] }, MutationAssignUsersForBudgetArgs>,
    batchDownload?: GraphCacheUpdateResolver<{ batchDownload: WithTypename<DownloadModel> }, MutationBatchDownloadArgs>,
    changeBudgetName?: GraphCacheUpdateResolver<{ changeBudgetName: Scalars['Boolean'] }, MutationChangeBudgetNameArgs>,
    changeBudgetOwner?: GraphCacheUpdateResolver<{ changeBudgetOwner: Scalars['Boolean'] }, MutationChangeBudgetOwnerArgs>,
    changeTenantView?: GraphCacheUpdateResolver<{ changeTenantView: WithTypename<CurrentUserModel> }, MutationChangeTenantViewArgs>,
    copyTemplate?: GraphCacheUpdateResolver<{ copyTemplate: WithTypename<TemplateModel> }, MutationCopyTemplateArgs>,
    deleteBrand?: GraphCacheUpdateResolver<{ deleteBrand: Scalars['Boolean'] }, MutationDeleteBrandArgs>,
    deleteBudget?: GraphCacheUpdateResolver<{ deleteBudget: Scalars['Boolean'] }, MutationDeleteBudgetArgs>,
    deleteContentKit?: GraphCacheUpdateResolver<{ deleteContentKit: Scalars['Boolean'] }, MutationDeleteContentKitArgs>,
    deleteGenre?: GraphCacheUpdateResolver<{ deleteGenre: Scalars['Boolean'] }, MutationDeleteGenreArgs>,
    deleteOrder?: GraphCacheUpdateResolver<{ deleteOrder: WithTypename<OrderModel> }, MutationDeleteOrderArgs>,
    deleteOrderItem?: GraphCacheUpdateResolver<{ deleteOrderItem: WithTypename<OrderItemModel> }, MutationDeleteOrderItemArgs>,
    exportAs?: GraphCacheUpdateResolver<{ exportAs: WithTypename<ExportAsModel> }, MutationExportAsArgs>,
    extendSubscription?: GraphCacheUpdateResolver<{ extendSubscription: Scalars['Boolean'] }, MutationExtendSubscriptionArgs>,
    fixOrderItem?: GraphCacheUpdateResolver<{ fixOrderItem: Scalars['Boolean'] }, MutationFixOrderItemArgs>,
    inviteUser?: GraphCacheUpdateResolver<{ inviteUser: Scalars['Boolean'] }, MutationInviteUserArgs>,
    makeDraftProductLive?: GraphCacheUpdateResolver<{ makeDraftProductLive: Scalars['Boolean'] }, MutationMakeDraftProductLiveArgs>,
    newUserNotification?: GraphCacheUpdateResolver<{ newUserNotification: Scalars['Boolean'] }, MutationNewUserNotificationArgs>,
    notifyOfFailedViewer?: GraphCacheUpdateResolver<{ notifyOfFailedViewer: Scalars['Boolean'] }, MutationNotifyOfFailedViewerArgs>,
    order?: GraphCacheUpdateResolver<{ order: WithTypename<OrderModel> }, MutationOrderArgs>,
    orderPremium?: GraphCacheUpdateResolver<{ orderPremium: WithTypename<OrderModel> }, MutationOrderPremiumArgs>,
    publishTemplate?: GraphCacheUpdateResolver<{ publishTemplate: WithTypename<TemplateModel> }, MutationPublishTemplateArgs>,
    reTriggerOrderItem?: GraphCacheUpdateResolver<{ reTriggerOrderItem: Scalars['Boolean'] }, MutationReTriggerOrderItemArgs>,
    refirePreviewRequest?: GraphCacheUpdateResolver<{ refirePreviewRequest: Scalars['Boolean'] }, MutationRefirePreviewRequestArgs>,
    refreshArtwork?: GraphCacheUpdateResolver<{ refreshArtwork: WithTypename<ArtworkModel> }, MutationRefreshArtworkArgs>,
    refreshShape?: GraphCacheUpdateResolver<{ refreshShape: WithTypename<ShapeModel> }, MutationRefreshShapeArgs>,
    removeAdditionalImage?: GraphCacheUpdateResolver<{ removeAdditionalImage: Scalars['Boolean'] }, MutationRemoveAdditionalImageArgs>,
    removeAdditionalImageType?: GraphCacheUpdateResolver<{ removeAdditionalImageType: Scalars['Boolean'] }, MutationRemoveAdditionalImageTypeArgs>,
    removeArtworkSet?: GraphCacheUpdateResolver<{ removeArtworkSet: Scalars['Boolean'] }, MutationRemoveArtworkSetArgs>,
    removeArtworks?: GraphCacheUpdateResolver<{ removeArtworks: Scalars['Boolean'] }, MutationRemoveArtworksArgs>,
    removeDraftProduct?: GraphCacheUpdateResolver<{ removeDraftProduct: Scalars['Boolean'] }, MutationRemoveDraftProductArgs>,
    removeEditorCombination?: GraphCacheUpdateResolver<{ removeEditorCombination: Scalars['Boolean'] }, MutationRemoveEditorCombinationArgs>,
    removeItemFromBasket?: GraphCacheUpdateResolver<{ removeItemFromBasket: WithTypename<BasketModel> }, MutationRemoveItemFromBasketArgs>,
    removePreview?: GraphCacheUpdateResolver<{ removePreview: Scalars['Boolean'] }, MutationRemovePreviewArgs>,
    removeProduct?: GraphCacheUpdateResolver<{ removeProduct: Scalars['Boolean'] }, MutationRemoveProductArgs>,
    removeProductGroup?: GraphCacheUpdateResolver<{ removeProductGroup: Scalars['Boolean'] }, MutationRemoveProductGroupArgs>,
    removeProject?: GraphCacheUpdateResolver<{ removeProject: Scalars['Boolean'] }, MutationRemoveProjectArgs>,
    removeScene?: GraphCacheUpdateResolver<{ removeScene: Scalars['Boolean'] }, MutationRemoveSceneArgs>,
    removeShapeCollection?: GraphCacheUpdateResolver<{ removeShapeCollection: Scalars['Boolean'] }, MutationRemoveShapeCollectionArgs>,
    removeShapeCollectionScene?: GraphCacheUpdateResolver<{ removeShapeCollectionScene: Scalars['Boolean'] }, MutationRemoveShapeCollectionSceneArgs>,
    removeTemplate?: GraphCacheUpdateResolver<{ removeTemplate: Scalars['Boolean'] }, MutationRemoveTemplateArgs>,
    removeTenant?: GraphCacheUpdateResolver<{ removeTenant: Scalars['Boolean'] }, MutationRemoveTenantArgs>,
    removeUserProvisioningTable?: GraphCacheUpdateResolver<{ removeUserProvisioningTable: Scalars['Boolean'] }, MutationRemoveUserProvisioningTableArgs>,
    requestAccess?: GraphCacheUpdateResolver<{ requestAccess: Scalars['Boolean'] }, Record<string, never>>,
    requestPreviews?: GraphCacheUpdateResolver<{ requestPreviews: Scalars['String'] }, MutationRequestPreviewsArgs>,
    requestPreviewsByProductId?: GraphCacheUpdateResolver<{ requestPreviewsByProductId: Scalars['String'] }, MutationRequestPreviewsByProductIdArgs>,
    resetUser?: GraphCacheUpdateResolver<{ resetUser: Scalars['Boolean'] }, MutationResetUserArgs>,
    resetWelcomeMessageShown?: GraphCacheUpdateResolver<{ resetWelcomeMessageShown: Scalars['Boolean'] }, MutationResetWelcomeMessageShownArgs>,
    resumeBudget?: GraphCacheUpdateResolver<{ resumeBudget: Scalars['Boolean'] }, MutationResumeBudgetArgs>,
    revokeUserPermissions?: GraphCacheUpdateResolver<{ revokeUserPermissions: Scalars['Boolean'] }, MutationRevokeUserPermissionsArgs>,
    saveEditorCombination?: GraphCacheUpdateResolver<{ saveEditorCombination: Scalars['Boolean'] }, MutationSaveEditorCombinationArgs>,
    setDefaultProduct?: GraphCacheUpdateResolver<{ setDefaultProduct: Scalars['Boolean'] }, MutationSetDefaultProductArgs>,
    setTenantProjectManagers?: GraphCacheUpdateResolver<{ setTenantProjectManagers: Scalars['Boolean'] }, MutationSetTenantProjectManagersArgs>,
    setUserAsAdmin?: GraphCacheUpdateResolver<{ setUserAsAdmin: Scalars['Boolean'] }, MutationSetUserAsAdminArgs>,
    setUserAsDefaultUser?: GraphCacheUpdateResolver<{ setUserAsDefaultUser: Scalars['Boolean'] }, MutationSetUserAsDefaultUserArgs>,
    setUserAsProjectManager?: GraphCacheUpdateResolver<{ setUserAsProjectManager: Scalars['Boolean'] }, MutationSetUserAsProjectManagerArgs>,
    stashEditorDetailsInput?: GraphCacheUpdateResolver<{ stashEditorDetailsInput: Scalars['String'] }, MutationStashEditorDetailsInputArgs>,
    suspendBudget?: GraphCacheUpdateResolver<{ suspendBudget: Scalars['Boolean'] }, MutationSuspendBudgetArgs>,
    toggleSalesForceId?: GraphCacheUpdateResolver<{ toggleSalesForceId: Scalars['Boolean'] }, MutationToggleSalesForceIdArgs>,
    updateAdditionalImageType?: GraphCacheUpdateResolver<{ updateAdditionalImageType: WithTypename<AdditionalImageTypeModel> }, MutationUpdateAdditionalImageTypeArgs>,
    updateAdvancedFiltersSettings?: GraphCacheUpdateResolver<{ updateAdvancedFiltersSettings: WithTypename<AdvancedFiltersSettings> }, MutationUpdateAdvancedFiltersSettingsArgs>,
    updateArtworkSet?: GraphCacheUpdateResolver<{ updateArtworkSet: WithTypename<ArtworkSetModel> }, MutationUpdateArtworkSetArgs>,
    updateBrand?: GraphCacheUpdateResolver<{ updateBrand: WithTypename<BrandModel> }, MutationUpdateBrandArgs>,
    updateBudgetPremiumOrdering?: GraphCacheUpdateResolver<{ updateBudgetPremiumOrdering: Scalars['Boolean'] }, MutationUpdateBudgetPremiumOrderingArgs>,
    updateContentKit?: GraphCacheUpdateResolver<{ updateContentKit: WithTypename<ContentKitModel> }, MutationUpdateContentKitArgs>,
    updateDefaultPreviews?: GraphCacheUpdateResolver<{ updateDefaultPreviews: Scalars['Boolean'] }, MutationUpdateDefaultPreviewsArgs>,
    updateDraftProduct?: GraphCacheUpdateResolver<{ updateDraftProduct: WithTypename<DraftProductModel> }, MutationUpdateDraftProductArgs>,
    updateGenre?: GraphCacheUpdateResolver<{ updateGenre: WithTypename<GenreModel> }, MutationUpdateGenreArgs>,
    updateProduct?: GraphCacheUpdateResolver<{ updateProduct: WithTypename<ProductModel> }, MutationUpdateProductArgs>,
    updateProductGroup?: GraphCacheUpdateResolver<{ updateProductGroup: WithTypename<ProductGroupModel> }, MutationUpdateProductGroupArgs>,
    updateProject?: GraphCacheUpdateResolver<{ updateProject: WithTypename<ProjectModel> }, MutationUpdateProjectArgs>,
    updateScene?: GraphCacheUpdateResolver<{ updateScene: WithTypename<SceneModel> }, MutationUpdateSceneArgs>,
    updateShapeCollection?: GraphCacheUpdateResolver<{ updateShapeCollection: WithTypename<ShapeCollectionModel> }, MutationUpdateShapeCollectionArgs>,
    updateShapeCollectionImageScene?: GraphCacheUpdateResolver<{ updateShapeCollectionImageScene: WithTypename<ShapeCollectionImageSceneModel> }, MutationUpdateShapeCollectionImageSceneArgs>,
    updateShapeCollectionUVRemapScene?: GraphCacheUpdateResolver<{ updateShapeCollectionUVRemapScene: WithTypename<ShapeCollectionUvRemapSceneModel> }, MutationUpdateShapeCollectionUvRemapSceneArgs>,
    updateShapeCollectionVideoScene?: GraphCacheUpdateResolver<{ updateShapeCollectionVideoScene: WithTypename<ShapeCollectionVideoSceneModel> }, MutationUpdateShapeCollectionVideoSceneArgs>,
    updateTemplate?: GraphCacheUpdateResolver<{ updateTemplate: WithTypename<TemplateModel> }, MutationUpdateTemplateArgs>,
    updateTenant?: GraphCacheUpdateResolver<{ updateTenant: WithTypename<TenantModel> }, MutationUpdateTenantArgs>,
    updateUserProvisioningTable?: GraphCacheUpdateResolver<{ updateUserProvisioningTable: WithTypename<UserProvisioningTableModel> }, MutationUpdateUserProvisioningTableArgs>,
    uploadShapeImageSequence?: GraphCacheUpdateResolver<{ uploadShapeImageSequence: Scalars['Boolean'] }, MutationUploadShapeImageSequenceArgs>,
    validateDraftProduct?: GraphCacheUpdateResolver<{ validateDraftProduct: WithTypename<ValidationResultModel> }, MutationValidateDraftProductArgs>,
    welcomeMessageShown?: GraphCacheUpdateResolver<{ welcomeMessageShown: Scalars['Boolean'] }, Record<string, never>>
  },
  Subscription?: {},
  AddUserProvisioningTableFailed?: {
    csvDownloadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<AddUserProvisioningTableFailed>>, Record<string, never>>,
    succeeded?: GraphCacheUpdateResolver<Maybe<WithTypename<AddUserProvisioningTableFailed>>, Record<string, never>>,
    validationResult?: GraphCacheUpdateResolver<Maybe<WithTypename<AddUserProvisioningTableFailed>>, Record<string, never>>
  },
  AddUserProvisioningTableSucceeded?: {
    data?: GraphCacheUpdateResolver<Maybe<WithTypename<AddUserProvisioningTableSucceeded>>, Record<string, never>>,
    succeeded?: GraphCacheUpdateResolver<Maybe<WithTypename<AddUserProvisioningTableSucceeded>>, Record<string, never>>
  },
  AdditionalImageModel?: {
    additionalImageType?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    additionalImageTypeId?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    fileName?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    fileType?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    height?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    productId?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    tenantId?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>,
    width?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageModel>>, Record<string, never>>
  },
  AdditionalImageTypeModel?: {
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageTypeModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageTypeModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageTypeModel>>, Record<string, never>>,
    tenants?: GraphCacheUpdateResolver<Maybe<WithTypename<AdditionalImageTypeModel>>, Record<string, never>>
  },
  AdvancedFiltersSettings?: {
    metadataFiltersEnabled?: GraphCacheUpdateResolver<Maybe<WithTypename<AdvancedFiltersSettings>>, Record<string, never>>,
    productGroupFiltersEnabled?: GraphCacheUpdateResolver<Maybe<WithTypename<AdvancedFiltersSettings>>, Record<string, never>>,
    shapeCollectionFiltersEnabled?: GraphCacheUpdateResolver<Maybe<WithTypename<AdvancedFiltersSettings>>, Record<string, never>>
  },
  AdvancedProductFilterOptions?: {
    metadataFilterOptions?: GraphCacheUpdateResolver<Maybe<WithTypename<AdvancedProductFilterOptions>>, AdvancedProductFilterOptionsMetadataFilterOptionsArgs>,
    productGroupFilterOptions?: GraphCacheUpdateResolver<Maybe<WithTypename<AdvancedProductFilterOptions>>, AdvancedProductFilterOptionsProductGroupFilterOptionsArgs>,
    shapeCollectionFilterOptions?: GraphCacheUpdateResolver<Maybe<WithTypename<AdvancedProductFilterOptions>>, AdvancedProductFilterOptionsShapeCollectionFilterOptionsArgs>
  },
  ArtworkMapping?: {
    inputs?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMapping>>, Record<string, never>>,
    mappingUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMapping>>, Record<string, never>>
  },
  ArtworkMappingInputObject?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingInputObject>>, Record<string, never>>,
    inputType?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingInputObject>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingInputObject>>, Record<string, never>>,
    options?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingInputObject>>, Record<string, never>>,
    required?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingInputObject>>, Record<string, never>>
  },
  ArtworkMappingOption?: {
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingOption>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingOption>>, Record<string, never>>,
    value?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkMappingOption>>, Record<string, never>>
  },
  ArtworkModel?: {
    fullQuality?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkModel>>, Record<string, never>>,
    nasId?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkModel>>, Record<string, never>>,
    preview?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkModel>>, Record<string, never>>,
    shapeCollectionId?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkModel>>, Record<string, never>>,
    thumbnail?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkModel>>, Record<string, never>>
  },
  ArtworkSetCompatibilityOptions?: {
    artworkTypes?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetCompatibilityOptions>>, Record<string, never>>
  },
  ArtworkSetFilter?: {
    artworkSetTypes?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetFilter>>, Record<string, never>>
  },
  ArtworkSetListModel?: {
    artworkSets?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetListModel>>, ArtworkSetListModelArtworkSetsArgs>,
    filteredTotal?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetListModel>>, Record<string, never>>
  },
  ArtworkSetModel?: {
    artworks?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetModel>>, Record<string, never>>,
    locale?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetModel>>, Record<string, never>>,
    source?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetModel>>, Record<string, never>>,
    validatedBy?: GraphCacheUpdateResolver<Maybe<WithTypename<ArtworkSetModel>>, Record<string, never>>
  },
  BaseMeasurementFilter?: {
    max?: GraphCacheUpdateResolver<Maybe<WithTypename<BaseMeasurementFilter>>, Record<string, never>>,
    measurement?: GraphCacheUpdateResolver<Maybe<WithTypename<BaseMeasurementFilter>>, Record<string, never>>,
    min?: GraphCacheUpdateResolver<Maybe<WithTypename<BaseMeasurementFilter>>, Record<string, never>>
  },
  BasketGroupModel?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketGroupModel>>, Record<string, never>>,
    groupId?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketGroupModel>>, Record<string, never>>,
    items?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketGroupModel>>, Record<string, never>>
  },
  BasketItemModel?: {
    configuration?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    contentKit?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    creditCost?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    hashKey?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    isPremiumOrderable?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    mainProduct?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    outputVariant?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    productSelections?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    template?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    templateVersion?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>,
    userInputs?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemModel>>, Record<string, never>>
  },
  BasketItemStatusFlags?: {
    isInBasket?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemStatusFlags>>, Record<string, never>>,
    isOrdered?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketItemStatusFlags>>, Record<string, never>>
  },
  BasketModel?: {
    groups?: GraphCacheUpdateResolver<Maybe<WithTypename<BasketModel>>, Record<string, never>>
  },
  BrandModel?: {
    color?: GraphCacheUpdateResolver<Maybe<WithTypename<BrandModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<BrandModel>>, Record<string, never>>,
    logoUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<BrandModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<BrandModel>>, Record<string, never>>
  },
  BudgetListModel?: {
    budgets?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetListModel>>, BudgetListModelBudgetsArgs>,
    totalNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetListModel>>, Record<string, never>>
  },
  BudgetModel?: {
    assignedUsers?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    budgetType?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    creditValue?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    credits?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    expiresAt?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    isExpired?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    isInUse?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    isSuspended?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    owner?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    paymentType?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    premiumOrdering?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    salesForceReferences?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    subscriptionCreditAmount?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>,
    subscriptionStart?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetModel>>, Record<string, never>>
  },
  BudgetOrderSuggestion?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetOrderSuggestion>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetOrderSuggestion>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<BudgetOrderSuggestion>>, Record<string, never>>
  },
  CompatibleProductSearchResult?: {
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<CompatibleProductSearchResult>>, CompatibleProductSearchResultProductsArgs>,
    totalNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<CompatibleProductSearchResult>>, Record<string, never>>
  },
  CompleteShapeCollectionSceneModel?: {
    hdriFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    json?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    jsonFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    lutFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    prerenderedImageFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    prerenderedVideoFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    scene?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    shapeCollectionId?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    shapeViewerData?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    uvFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>,
    videoUvFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<CompleteShapeCollectionSceneModel>>, Record<string, never>>
  },
  ConfigurationModel?: {
    outputVariant?: GraphCacheUpdateResolver<Maybe<WithTypename<ConfigurationModel>>, Record<string, never>>,
    productSelections?: GraphCacheUpdateResolver<Maybe<WithTypename<ConfigurationModel>>, Record<string, never>>,
    userInputs?: GraphCacheUpdateResolver<Maybe<WithTypename<ConfigurationModel>>, Record<string, never>>
  },
  ContentKitModel?: {
    genre?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>,
    lastModifiedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>,
    related?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>,
    templates?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitModel>>, Record<string, never>>
  },
  ContentKitOutputCombinationGroupModel?: {
    contentKit?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitOutputCombinationGroupModel>>, Record<string, never>>,
    genre?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitOutputCombinationGroupModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitOutputCombinationGroupModel>>, Record<string, never>>,
    mainEntityId?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitOutputCombinationGroupModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitOutputCombinationGroupModel>>, Record<string, never>>,
    outputCombinations?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitOutputCombinationGroupModel>>, Record<string, never>>
  },
  ContentKitSearchFilters?: {
    contentKits?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitSearchFilters>>, Record<string, never>>,
    genres?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitSearchFilters>>, Record<string, never>>
  },
  ContentKitSuggestion?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitSuggestion>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitSuggestion>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitSuggestion>>, Record<string, never>>
  },
  ContentKitsModel?: {
    contentKits?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitsModel>>, ContentKitsModelContentKitsArgs>,
    filteredTotal?: GraphCacheUpdateResolver<Maybe<WithTypename<ContentKitsModel>>, Record<string, never>>
  },
  CurrentUserModel?: {
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>,
    firstName?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>,
    lastName?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>,
    roles?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>,
    selectedTenantId?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>,
    tenantId?: GraphCacheUpdateResolver<Maybe<WithTypename<CurrentUserModel>>, Record<string, never>>
  },
  DimensionFilter?: {
    depth?: GraphCacheUpdateResolver<Maybe<WithTypename<DimensionFilter>>, Record<string, never>>,
    height?: GraphCacheUpdateResolver<Maybe<WithTypename<DimensionFilter>>, Record<string, never>>,
    width?: GraphCacheUpdateResolver<Maybe<WithTypename<DimensionFilter>>, Record<string, never>>
  },
  Dimensions?: {
    depth?: GraphCacheUpdateResolver<Maybe<WithTypename<Dimensions>>, Record<string, never>>,
    height?: GraphCacheUpdateResolver<Maybe<WithTypename<Dimensions>>, Record<string, never>>,
    width?: GraphCacheUpdateResolver<Maybe<WithTypename<Dimensions>>, Record<string, never>>
  },
  DownloadModel?: {
    downloadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<DownloadModel>>, Record<string, never>>
  },
  DraftProductModel?: {
    artworkSets?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    brand?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    globalId?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    shapeCollection?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>,
    variant?: GraphCacheUpdateResolver<Maybe<WithTypename<DraftProductModel>>, Record<string, never>>
  },
  EditorCombinationDetailsModel?: {
    hashKey?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorCombinationDetailsModel>>, Record<string, never>>,
    orderHistory?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorCombinationDetailsModel>>, Record<string, never>>
  },
  EditorDetailsModel?: {
    contentKitId?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorDetailsModel>>, Record<string, never>>,
    outputVariant?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorDetailsModel>>, Record<string, never>>,
    productSelections?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorDetailsModel>>, Record<string, never>>,
    templateId?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorDetailsModel>>, Record<string, never>>,
    userInputs?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorDetailsModel>>, Record<string, never>>
  },
  EditorInitialValuesModel?: {
    configuration?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorInitialValuesModel>>, Record<string, never>>,
    contentKit?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorInitialValuesModel>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorInitialValuesModel>>, Record<string, never>>,
    template?: GraphCacheUpdateResolver<Maybe<WithTypename<EditorInitialValuesModel>>, Record<string, never>>
  },
  ExportAsModel?: {
    downloadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ExportAsModel>>, Record<string, never>>
  },
  FileInfo?: {
    contentType?: GraphCacheUpdateResolver<Maybe<WithTypename<FileInfo>>, Record<string, never>>,
    extension?: GraphCacheUpdateResolver<Maybe<WithTypename<FileInfo>>, Record<string, never>>,
    height?: GraphCacheUpdateResolver<Maybe<WithTypename<FileInfo>>, Record<string, never>>,
    originalFileName?: GraphCacheUpdateResolver<Maybe<WithTypename<FileInfo>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<FileInfo>>, Record<string, never>>,
    width?: GraphCacheUpdateResolver<Maybe<WithTypename<FileInfo>>, Record<string, never>>
  },
  FilterOptions?: {
    options?: GraphCacheUpdateResolver<Maybe<WithTypename<FilterOptions>>, Record<string, never>>,
    total?: GraphCacheUpdateResolver<Maybe<WithTypename<FilterOptions>>, Record<string, never>>
  },
  FiltersModel?: {
    brands?: GraphCacheUpdateResolver<Maybe<WithTypename<FiltersModel>>, FiltersModelBrandsArgs>,
    contentKits?: GraphCacheUpdateResolver<Maybe<WithTypename<FiltersModel>>, FiltersModelContentKitsArgs>,
    genres?: GraphCacheUpdateResolver<Maybe<WithTypename<FiltersModel>>, FiltersModelGenresArgs>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<FiltersModel>>, FiltersModelProductsArgs>,
    searchContext?: GraphCacheUpdateResolver<Maybe<WithTypename<FiltersModel>>, Record<string, never>>
  },
  FinancialReportModel?: {
    rows?: GraphCacheUpdateResolver<Maybe<WithTypename<FinancialReportModel>>, FinancialReportModelRowsArgs>,
    totalNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<FinancialReportModel>>, Record<string, never>>
  },
  FindByIdFilter?: {
    idList?: GraphCacheUpdateResolver<Maybe<WithTypename<FindByIdFilter>>, Record<string, never>>,
    requirement?: GraphCacheUpdateResolver<Maybe<WithTypename<FindByIdFilter>>, Record<string, never>>
  },
  GenericFilterOptionItem?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<GenericFilterOptionItem>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<GenericFilterOptionItem>>, Record<string, never>>,
    product?: GraphCacheUpdateResolver<Maybe<WithTypename<GenericFilterOptionItem>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<GenericFilterOptionItem>>, Record<string, never>>
  },
  GenreModel?: {
    color?: GraphCacheUpdateResolver<Maybe<WithTypename<GenreModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<GenreModel>>, Record<string, never>>,
    logoUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<GenreModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<GenreModel>>, Record<string, never>>
  },
  InfoModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<InfoModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<InfoModel>>, Record<string, never>>
  },
  InternalUserModel?: {
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<InternalUserModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<InternalUserModel>>, Record<string, never>>,
    loggedInBefore?: GraphCacheUpdateResolver<Maybe<WithTypename<InternalUserModel>>, Record<string, never>>,
    managedTenants?: GraphCacheUpdateResolver<Maybe<WithTypename<InternalUserModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<InternalUserModel>>, Record<string, never>>,
    roles?: GraphCacheUpdateResolver<Maybe<WithTypename<InternalUserModel>>, Record<string, never>>,
    tenants?: GraphCacheUpdateResolver<Maybe<WithTypename<InternalUserModel>>, Record<string, never>>
  },
  KeyValuePairOfStringAndObject?: {
    key?: GraphCacheUpdateResolver<Maybe<WithTypename<KeyValuePairOfStringAndObject>>, Record<string, never>>
  },
  KeyValuePairOfStringAndProductSelectionModel?: {
    key?: GraphCacheUpdateResolver<Maybe<WithTypename<KeyValuePairOfStringAndProductSelectionModel>>, Record<string, never>>,
    value?: GraphCacheUpdateResolver<Maybe<WithTypename<KeyValuePairOfStringAndProductSelectionModel>>, Record<string, never>>
  },
  KeyValuePairOfStringAndString?: {
    key?: GraphCacheUpdateResolver<Maybe<WithTypename<KeyValuePairOfStringAndString>>, Record<string, never>>,
    value?: GraphCacheUpdateResolver<Maybe<WithTypename<KeyValuePairOfStringAndString>>, Record<string, never>>
  },
  KeyValuePairOfStringAndString__?: {
    key?: GraphCacheUpdateResolver<Maybe<WithTypename<KeyValuePairOfStringAndString__>>, Record<string, never>>,
    value?: GraphCacheUpdateResolver<Maybe<WithTypename<KeyValuePairOfStringAndString__>>, Record<string, never>>
  },
  MetadataKeyFilterConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterConnection>>, Record<string, never>>
  },
  MetadataKeyFilterOption?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterOption>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterOption>>, Record<string, never>>,
    values?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterOption>>, MetadataKeyFilterOptionValuesArgs>
  },
  MetadataKeyFilterOptionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterOptionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataKeyFilterOptionEdge>>, Record<string, never>>
  },
  MetadataValueFilterConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterConnection>>, Record<string, never>>
  },
  MetadataValueFilterOption?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterOption>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterOption>>, Record<string, never>>,
    productCount?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterOption>>, Record<string, never>>
  },
  MetadataValueFilterOptionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterOptionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataValueFilterOptionEdge>>, Record<string, never>>
  },
  MsalConfigurationModel?: {
    clientId?: GraphCacheUpdateResolver<Maybe<WithTypename<MsalConfigurationModel>>, Record<string, never>>,
    domain?: GraphCacheUpdateResolver<Maybe<WithTypename<MsalConfigurationModel>>, Record<string, never>>,
    instance?: GraphCacheUpdateResolver<Maybe<WithTypename<MsalConfigurationModel>>, Record<string, never>>,
    userJourney?: GraphCacheUpdateResolver<Maybe<WithTypename<MsalConfigurationModel>>, Record<string, never>>,
    userJourneyUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<MsalConfigurationModel>>, Record<string, never>>
  },
  MyOrderListModel?: {
    filteredTotal?: GraphCacheUpdateResolver<Maybe<WithTypename<MyOrderListModel>>, Record<string, never>>,
    myOrders?: GraphCacheUpdateResolver<Maybe<WithTypename<MyOrderListModel>>, MyOrderListModelMyOrdersArgs>
  },
  OrderItemModel?: {
    basketHashKey?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    completedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    contentKitId?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    cost?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    downloadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    failedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    failureReason?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    fileInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    generatorRunId?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    height?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    isDeleted?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    mainProductId?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    outputVariant?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    premiumOrdering?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    preview?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    processingStartedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    productSelections?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    retryCount?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    runId?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    template?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    templateVersion?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    userInputs?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>,
    width?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderItemModel>>, Record<string, never>>
  },
  OrderModel?: {
    budget?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    isDeleted?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    isProcessed?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    orderCompleted?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    orderItems?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    orderPartial?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    orderRendering?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    orderedBy?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>,
    totalCost?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderModel>>, Record<string, never>>
  },
  OrderSuggestion?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderSuggestion>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderSuggestion>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<OrderSuggestion>>, Record<string, never>>
  },
  OutputCombinationConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationConnection>>, Record<string, never>>
  },
  OutputCombinationEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationEdge>>, Record<string, never>>
  },
  OutputCombinationGroupListModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupListModel>>, Record<string, never>>,
    items?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupListModel>>, OutputCombinationGroupListModelItemsArgs>,
    searchId?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupListModel>>, Record<string, never>>,
    total?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupListModel>>, Record<string, never>>
  },
  OutputCombinationGroupingInfoModel?: {
    completedCount?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupingInfoModel>>, Record<string, never>>,
    contentKitId?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupingInfoModel>>, Record<string, never>>,
    inProgressCount?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupingInfoModel>>, Record<string, never>>,
    productId?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupingInfoModel>>, Record<string, never>>,
    templateId?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupingInfoModel>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationGroupingInfoModel>>, Record<string, never>>
  },
  OutputCombinationListModel?: {
    items?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationListModel>>, OutputCombinationListModelItemsArgs>,
    total?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationListModel>>, Record<string, never>>
  },
  OutputCombinationModel?: {
    configuration?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    contentKit?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    downloadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    groupingInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    hashKey?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    isDownloadable?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    isSaved?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    mainProductId?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    orderInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    template?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>,
    thumbnail?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationModel>>, Record<string, never>>
  },
  OutputCombinationOrderInfo?: {
    createdBy?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    downloadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    fileInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    orderId?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    orderItemId?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    orderedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    preview?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>,
    templateVersion?: GraphCacheUpdateResolver<Maybe<WithTypename<OutputCombinationOrderInfo>>, Record<string, never>>
  },
  PageInfo?: {
    endCursor?: GraphCacheUpdateResolver<Maybe<WithTypename<PageInfo>>, Record<string, never>>,
    hasNextPage?: GraphCacheUpdateResolver<Maybe<WithTypename<PageInfo>>, Record<string, never>>,
    startCursor?: GraphCacheUpdateResolver<Maybe<WithTypename<PageInfo>>, Record<string, never>>
  },
  PremiumOrderingModel?: {
    enabled?: GraphCacheUpdateResolver<Maybe<WithTypename<PremiumOrderingModel>>, Record<string, never>>,
    multiplier?: GraphCacheUpdateResolver<Maybe<WithTypename<PremiumOrderingModel>>, Record<string, never>>
  },
  PreviewModel?: {
    failureReason?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    height?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    mainProductId?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    outputFullType?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    productSelections?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    runId?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    template?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    url?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>,
    width?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewModel>>, Record<string, never>>
  },
  PreviewStatsModel?: {
    completed?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewStatsModel>>, Record<string, never>>,
    failed?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewStatsModel>>, Record<string, never>>,
    processing?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewStatsModel>>, Record<string, never>>,
    total?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewStatsModel>>, Record<string, never>>
  },
  PreviewsListModel?: {
    filteredTotal?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewsListModel>>, Record<string, never>>,
    previews?: GraphCacheUpdateResolver<Maybe<WithTypename<PreviewsListModel>>, PreviewsListModelPreviewsArgs>
  },
  ProductCompatibilityOptions?: {
    additionalImageTypes?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductCompatibilityOptions>>, Record<string, never>>,
    artworkSetCompatibilityOptions?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductCompatibilityOptions>>, Record<string, never>>,
    brands?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductCompatibilityOptions>>, Record<string, never>>,
    shapeCollectionCompatibilityOptions?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductCompatibilityOptions>>, Record<string, never>>
  },
  ProductCompatibilityResult?: {
    failureReason?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductCompatibilityResult>>, Record<string, never>>,
    isCompatible?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductCompatibilityResult>>, Record<string, never>>,
    productId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductCompatibilityResult>>, Record<string, never>>
  },
  ProductFilter?: {
    additionalImageTypeIds?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilter>>, Record<string, never>>,
    artworkSetFilter?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilter>>, Record<string, never>>,
    brandIds?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilter>>, Record<string, never>>,
    shapeCollectionFilter?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilter>>, Record<string, never>>,
    skuList?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilter>>, Record<string, never>>
  },
  ProductFilterConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterConnection>>, Record<string, never>>
  },
  ProductFilterOption?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOption>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOption>>, Record<string, never>>,
    product?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOption>>, Record<string, never>>,
    productCount?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOption>>, Record<string, never>>
  },
  ProductFilterOptionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOptionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOptionEdge>>, Record<string, never>>
  },
  ProductFilterOptionItem?: {
    globalId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOptionItem>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOptionItem>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOptionItem>>, Record<string, never>>,
    product?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOptionItem>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductFilterOptionItem>>, Record<string, never>>
  },
  ProductGlobalId?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGlobalId>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGlobalId>>, Record<string, never>>
  },
  ProductGroupFilterConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterConnection>>, Record<string, never>>
  },
  ProductGroupFilterOption?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterOption>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterOption>>, Record<string, never>>,
    values?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterOption>>, ProductGroupFilterOptionValuesArgs>
  },
  ProductGroupFilterOptionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterOptionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupFilterOptionEdge>>, Record<string, never>>
  },
  ProductGroupModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupModel>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupModel>>, Record<string, never>>,
    users?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductGroupModel>>, Record<string, never>>
  },
  ProductListModel?: {
    filteredTotal?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductListModel>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductListModel>>, ProductListModelProductsArgs>
  },
  ProductModel?: {
    additionalImages?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    artworkSets?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    brand?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    globalId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    lastModifiedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    previewStats?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    shapeCollection?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>,
    variant?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductModel>>, Record<string, never>>
  },
  ProductOrderSuggestion?: {
    globalId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOrderSuggestion>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOrderSuggestion>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOrderSuggestion>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOrderSuggestion>>, Record<string, never>>
  },
  ProductOutputCombinationGroupModel?: {
    brand?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    globalId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    mainEntityId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    outputCombinations?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    product?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>,
    variant?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductOutputCombinationGroupModel>>, Record<string, never>>
  },
  ProductSearchFilters?: {
    brands?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSearchFilters>>, Record<string, never>>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSearchFilters>>, Record<string, never>>
  },
  ProductSelectionModel?: {
    artworkId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSelectionModel>>, Record<string, never>>,
    entityId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSelectionModel>>, Record<string, never>>,
    entityType?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSelectionModel>>, Record<string, never>>,
    productId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSelectionModel>>, Record<string, never>>,
    shapeCollectionId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSelectionModel>>, Record<string, never>>
  },
  ProductSuggestion?: {
    globalId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSuggestion>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSuggestion>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSuggestion>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductSuggestion>>, Record<string, never>>
  },
  ProjectModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectModel>>, Record<string, never>>
  },
  RangeFilter?: {
    max?: GraphCacheUpdateResolver<Maybe<WithTypename<RangeFilter>>, Record<string, never>>,
    min?: GraphCacheUpdateResolver<Maybe<WithTypename<RangeFilter>>, Record<string, never>>
  },
  ReportRowModel?: {
    autoTopUp?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    budget?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    creditValue?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    creditsAdded?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    creditsExceeded?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    creditsRollover?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    creditsUsed?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    from?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    period?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    reportType?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    salesForceIds?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    tenant?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    to?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    totalCreditsValue?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>,
    year?: GraphCacheUpdateResolver<Maybe<WithTypename<ReportRowModel>>, Record<string, never>>
  },
  SalesForceReferenceModel?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<SalesForceReferenceModel>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<SalesForceReferenceModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<SalesForceReferenceModel>>, Record<string, never>>,
    isActive?: GraphCacheUpdateResolver<Maybe<WithTypename<SalesForceReferenceModel>>, Record<string, never>>,
    salesForceId?: GraphCacheUpdateResolver<Maybe<WithTypename<SalesForceReferenceModel>>, Record<string, never>>
  },
  SceneInfoModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneInfoModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneInfoModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneInfoModel>>, Record<string, never>>
  },
  SceneModel?: {
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneModel>>, Record<string, never>>,
    tenants?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<SceneModel>>, Record<string, never>>
  },
  SearchContext?: {
    contentKitFilters?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    groupSortBy?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    groupSortDirection?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    layout?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    productFilters?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    sortBy?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    sortDirection?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    statusFilter?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>,
    textPhrase?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchContext>>, Record<string, never>>
  },
  SearchFilterOptions?: {
    brands?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchFilterOptions>>, SearchFilterOptionsBrandsArgs>,
    contentKits?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchFilterOptions>>, SearchFilterOptionsContentKitsArgs>,
    genres?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchFilterOptions>>, SearchFilterOptionsGenresArgs>,
    products?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchFilterOptions>>, SearchFilterOptionsProductsArgs>
  },
  SearchResultSummary?: {
    contentKitCount?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchResultSummary>>, Record<string, never>>,
    previewCount?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchResultSummary>>, Record<string, never>>,
    productCount?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchResultSummary>>, Record<string, never>>
  },
  SearchStatusFilterFlags?: {
    isAll?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchStatusFilterFlags>>, Record<string, never>>,
    isDownloadable?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchStatusFilterFlags>>, Record<string, never>>,
    isInProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchStatusFilterFlags>>, Record<string, never>>,
    isPreview?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchStatusFilterFlags>>, Record<string, never>>,
    isSaved?: GraphCacheUpdateResolver<Maybe<WithTypename<SearchStatusFilterFlags>>, Record<string, never>>
  },
  ShapeCollectionCompatibilityOptions?: {
    baseMeasurements?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionCompatibilityOptions>>, Record<string, never>>,
    scenes?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionCompatibilityOptions>>, Record<string, never>>,
    shapeCollectionTypes?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionCompatibilityOptions>>, Record<string, never>>,
    shapeTypes?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionCompatibilityOptions>>, Record<string, never>>
  },
  ShapeCollectionFilter?: {
    dimensionFilter?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilter>>, Record<string, never>>,
    measurementFilter?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilter>>, Record<string, never>>,
    shapeCollectionIds?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilter>>, Record<string, never>>,
    shapeCollectionTypes?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilter>>, Record<string, never>>,
    shapeCollectionTypesOperator?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilter>>, Record<string, never>>,
    shapeTypesOperator?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilter>>, Record<string, never>>,
    shapes?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilter>>, Record<string, never>>
  },
  ShapeCollectionFilterConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterConnection>>, Record<string, never>>
  },
  ShapeCollectionFilterOption?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterOption>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterOption>>, Record<string, never>>,
    productCount?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterOption>>, Record<string, never>>,
    values?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterOption>>, ShapeCollectionFilterOptionValuesArgs>
  },
  ShapeCollectionFilterOptionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterOptionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionFilterOptionEdge>>, Record<string, never>>
  },
  ShapeCollectionImageSceneModel?: {
    hdriFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    json?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    jsonFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    lutFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    prerenderedImageFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    scene?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    shapeCollectionId?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    shapeViewerData?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>,
    uvFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionImageSceneModel>>, Record<string, never>>
  },
  ShapeCollectionListModel?: {
    filteredTotal?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionListModel>>, Record<string, never>>,
    shapeCollections?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionListModel>>, ShapeCollectionListModelShapeCollectionsArgs>
  },
  ShapeCollectionModel?: {
    artworkMapping?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    dimensions?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    linkedShapeCollection?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    shapeCollectionScenes?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    shapes?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>,
    unitSize?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionModel>>, Record<string, never>>
  },
  ShapeCollectionUVRemapSceneModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionUvRemapSceneModel>>, Record<string, never>>,
    jsonFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionUvRemapSceneModel>>, Record<string, never>>,
    lutFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionUvRemapSceneModel>>, Record<string, never>>,
    scene?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionUvRemapSceneModel>>, Record<string, never>>,
    shapeCollectionId?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionUvRemapSceneModel>>, Record<string, never>>,
    videoUvFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionUvRemapSceneModel>>, Record<string, never>>
  },
  ShapeCollectionVideoSceneModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    json?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    jsonFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    lutFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    prerenderedVideoFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    scene?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    shapeCollectionId?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    shapeViewerData?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>,
    videoUvFileUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeCollectionVideoSceneModel>>, Record<string, never>>
  },
  ShapeFilterConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterConnection>>, Record<string, never>>
  },
  ShapeFilterOption?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterOption>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterOption>>, Record<string, never>>,
    productCount?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterOption>>, Record<string, never>>
  },
  ShapeFilterOptionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterOptionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeFilterOptionEdge>>, Record<string, never>>
  },
  ShapeModel?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>,
    lastModifiedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>,
    nasId?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>,
    thumbnail?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>,
    validatedBy?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>,
    viewerData?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeModel>>, Record<string, never>>
  },
  ShapeViewerDataModel?: {
    imageSequence?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeViewerDataModel>>, Record<string, never>>,
    json?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeViewerDataModel>>, Record<string, never>>,
    lut?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeViewerDataModel>>, Record<string, never>>,
    mp4?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeViewerDataModel>>, Record<string, never>>,
    uvMappings?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeViewerDataModel>>, Record<string, never>>,
    webM?: GraphCacheUpdateResolver<Maybe<WithTypename<ShapeViewerDataModel>>, Record<string, never>>
  },
  SimplifiedArtworkSetModel?: {
    artworks?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedArtworkSetModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedArtworkSetModel>>, Record<string, never>>,
    locale?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedArtworkSetModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedArtworkSetModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedArtworkSetModel>>, Record<string, never>>
  },
  SimplifiedProductModel?: {
    additionalImages?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    artworkSets?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    brand?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    globalId?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    productGroups?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    shapeCollection?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>,
    variant?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedProductModel>>, Record<string, never>>
  },
  SimplifiedShapeCollectionModel?: {
    dimensions?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>,
    linkedShapeCollection?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>,
    shapeCollectionScenes?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>,
    shapes?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>,
    unitSize?: GraphCacheUpdateResolver<Maybe<WithTypename<SimplifiedShapeCollectionModel>>, Record<string, never>>
  },
  StashItemModelOfEditorDetailsModel?: {
    key?: GraphCacheUpdateResolver<Maybe<WithTypename<StashItemModelOfEditorDetailsModel>>, Record<string, never>>,
    stashData?: GraphCacheUpdateResolver<Maybe<WithTypename<StashItemModelOfEditorDetailsModel>>, Record<string, never>>,
    tenantId?: GraphCacheUpdateResolver<Maybe<WithTypename<StashItemModelOfEditorDetailsModel>>, Record<string, never>>
  },
  TemplateAITextSuggestion?: {
    editablePromptPart?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateAiTextSuggestion>>, Record<string, never>>,
    isTextAiSuggestionEnabled?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateAiTextSuggestion>>, Record<string, never>>
  },
  TemplateClickDragInteraction?: {
    loop?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateClickDragInteraction>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateClickDragInteraction>>, Record<string, never>>
  },
  TemplateFileResource?: {
    resourceRef?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateFileResource>>, Record<string, never>>
  },
  TemplateInputModel?: {
    category?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    clickDragInteraction?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    colorPalette?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    compatibility?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    customValueOption?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    default?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    maxFontSize?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    maxLength?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    minFontSize?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    nodeId?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    options?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    order?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    range?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>,
    values?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputModel>>, Record<string, never>>
  },
  TemplateInputOptions?: {
    color?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputOptions>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputOptions>>, Record<string, never>>,
    image?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputOptions>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputOptions>>, Record<string, never>>,
    preview?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputOptions>>, Record<string, never>>,
    value?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputOptions>>, Record<string, never>>
  },
  TemplateInputRange?: {
    max?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputRange>>, Record<string, never>>,
    min?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputRange>>, Record<string, never>>,
    step?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateInputRange>>, Record<string, never>>
  },
  TemplateListModel?: {
    filteredTotal?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateListModel>>, Record<string, never>>,
    templates?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateListModel>>, TemplateListModelTemplatesArgs>
  },
  TemplateMetadata?: {
    mainProductInputName?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateMetadata>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateMetadata>>, Record<string, never>>,
    outputs?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateMetadata>>, Record<string, never>>
  },
  TemplateModel?: {
    aiTextSuggestion?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    isPerformanceCoachEnabled?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    linkedContentKits?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    modifiedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    nodeGraph?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    previewStats?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    previews?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    rootTemplate?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    templateStatus?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    textTranslation?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    thumbnailUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>,
    version?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateModel>>, Record<string, never>>
  },
  TemplateOutput?: {
    code?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    cost?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    fullType?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    height?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    inputs?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    nodeId?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    previewType?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    segments?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    shouldAutoApplyUserChanges?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    skipFrames?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    types?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    videoLength?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>,
    width?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateOutput>>, Record<string, never>>
  },
  TemplateSegment?: {
    inputs?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateSegment>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateSegment>>, Record<string, never>>,
    preview?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateSegment>>, Record<string, never>>,
    videoLength?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateSegment>>, Record<string, never>>
  },
  TemplateSegmentPreview?: {
    resourceRef?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateSegmentPreview>>, Record<string, never>>
  },
  TemplateTextTranslation?: {
    isTextTranslationEnabled?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateTextTranslation>>, Record<string, never>>,
    languageList?: GraphCacheUpdateResolver<Maybe<WithTypename<TemplateTextTranslation>>, Record<string, never>>
  },
  TenantModel?: {
    domain?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>,
    domains?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>,
    logoUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>,
    noMatchesImageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>,
    projectManagers?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>,
    userJourney?: GraphCacheUpdateResolver<Maybe<WithTypename<TenantModel>>, Record<string, never>>
  },
  TransactionModel?: {
    budget?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>,
    credits?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>,
    orderItem?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>,
    tenant?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>,
    transactionType?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionModel>>, Record<string, never>>
  },
  TransactionReportModel?: {
    totalNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionReportModel>>, Record<string, never>>,
    transactions?: GraphCacheUpdateResolver<Maybe<WithTypename<TransactionReportModel>>, TransactionReportModelTransactionsArgs>
  },
  UnitSize?: {
    content?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitSize>>, Record<string, never>>,
    measurement?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitSize>>, Record<string, never>>,
    quantity?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitSize>>, Record<string, never>>,
    unitType?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitSize>>, Record<string, never>>
  },
  UserJourneyModel?: {
    userJourney?: GraphCacheUpdateResolver<Maybe<WithTypename<UserJourneyModel>>, Record<string, never>>,
    userJourneyUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<UserJourneyModel>>, Record<string, never>>
  },
  UserModel?: {
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<UserModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserModel>>, Record<string, never>>,
    loggedInBefore?: GraphCacheUpdateResolver<Maybe<WithTypename<UserModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<UserModel>>, Record<string, never>>,
    roles?: GraphCacheUpdateResolver<Maybe<WithTypename<UserModel>>, Record<string, never>>
  },
  UserOrderSuggestion?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserOrderSuggestion>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<UserOrderSuggestion>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<UserOrderSuggestion>>, Record<string, never>>
  },
  UserProfileModel?: {
    hasProductAccess?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProfileModel>>, Record<string, never>>,
    recentlyUsedContentKits?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProfileModel>>, Record<string, never>>,
    recentlyUsedProducts?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProfileModel>>, Record<string, never>>,
    welcomeMessageShown?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProfileModel>>, Record<string, never>>
  },
  UserProvisioningTableItemModel?: {
    budgets?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableItemModel>>, Record<string, never>>,
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableItemModel>>, Record<string, never>>,
    processed?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableItemModel>>, Record<string, never>>,
    processedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableItemModel>>, Record<string, never>>,
    productGroups?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableItemModel>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableItemModel>>, Record<string, never>>
  },
  UserProvisioningTableModel?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableModel>>, Record<string, never>>,
    createdBy?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableModel>>, Record<string, never>>,
    items?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableModel>>, Record<string, never>>,
    lastModifiedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<UserProvisioningTableModel>>, Record<string, never>>
  },
  UserWithBudgetsModel?: {
    assignedBudgets?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>,
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>,
    loggedInBefore?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>,
    ownedBudgets?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>,
    productGroups?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>,
    roles?: GraphCacheUpdateResolver<Maybe<WithTypename<UserWithBudgetsModel>>, Record<string, never>>
  },
  ValidationErrorModel?: {
    errorCode?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationErrorModel>>, Record<string, never>>,
    errorMessage?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationErrorModel>>, Record<string, never>>,
    propertyName?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationErrorModel>>, Record<string, never>>
  },
  ValidationFailure?: {
    errorCode?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationFailure>>, Record<string, never>>,
    errorMessage?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationFailure>>, Record<string, never>>,
    formattedMessagePlaceholderValues?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationFailure>>, Record<string, never>>,
    propertyName?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationFailure>>, Record<string, never>>,
    severity?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationFailure>>, Record<string, never>>
  },
  ValidationResult?: {
    errors?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationResult>>, Record<string, never>>,
    isValid?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationResult>>, Record<string, never>>,
    ruleSetsExecuted?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationResult>>, Record<string, never>>,
    toDictionary?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationResult>>, Record<string, never>>
  },
  ValidationResultModel?: {
    errors?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationResultModel>>, Record<string, never>>,
    isValid?: GraphCacheUpdateResolver<Maybe<WithTypename<ValidationResultModel>>, Record<string, never>>
  },
  Validator?: {
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<Validator>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Validator>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<Validator>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<Validator>>, Record<string, never>>
  },
};

export type GraphCacheConfig = Parameters<typeof cacheExchange>[0] & {
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
};